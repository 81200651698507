import axios from "axios";
import { API_URL } from "../config/appConstatnts";

// Main Api Class for signup functionality
class Api {
  // Api for check an email.
  static getCheckEmail = (email) => {
    const HEADER = {
      headers: {
        access_token: localStorage.getItem("token"),
        "Content-Type": "application/json",
        Company_Id: localStorage.getItem("CompanyId"),
      },
    };
    return axios.get(API_URL + "/Verident/CheckEmailExists?Email=" + email, HEADER);
  };

  // Api for get state of US
  static getState = (CountryID) => {
    return axios.get(API_URL + "/State/Get/" + CountryID);
  };

  // Api for Signup
  static signUp = (payload) => {
    const HEADER = {
      headers: {
        access_token: localStorage.getItem("token"),
        "Content-Type": "application/json",
        Company_Id: localStorage.getItem("CompanyId"),
      },
    };
    return axios.post(API_URL + "/Verident/SignUp", payload, HEADER);
  };

  // Get Specialities
  static getSpecialities = () => {
    const HEADER = {
      headers: {
        access_token: localStorage.getItem("token"),
        "Content-Type": "application/json",
        Company_Id: localStorage.getItem("CompanyId"),
      },
    };
    return axios.get(API_URL + "/Verident/GetSpecialities?IsSignUP=True", HEADER);
  };
}

export default Api;
