import React from "react";
import Button from "../../CommonComponents/Buttons/Button";

const BlockButton = ({ onClick, text }) => {
  return (
    <div className="account-btn-block">
      <div className="btn-block">
        <Button
          type="button"
          className="btn btn-outline-primary blue-btn-bg "
          onClick={onClick}
          text={text}
        />
      </div>
    </div>
  );
};

export default BlockButton;
