import { useEffect, useState } from "react";
import Api from "../../API/CommonApiCalls";

const useStateList = (state) => {
  const [stateList, setStateList] = useState([{ value: 0, label: "Select state" }]);
  const [stateListError, setstateListError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const response = await Api.getState(state);
        setStateList([
          ...stateList,
          ...response.data.Result.map(({ Id, Name }) => ({ value: Id, label: Name })),
        ]);
      } catch (err) {
        setstateListError(err);
        setStateList([...stateList]);
      } finally {
        setLoading(false);
      }
    })();
  }, [state]);

  return { stateList, stateListError, loading };
};
export default useStateList;
