import React, { useEffect, useLayoutEffect, useState } from "react";
import "../assets/css/style.css";
import "../assets/css/responsive.css";
import AppNavbar from "../CommonComponents/Navbars/AppNavbar";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {
  Card,
  CardBody,
  Container,
  FormGroup,
  Input,
  Label,
  Button,
  CustomInput,
} from "reactstrap";
import Footer from "../CommonComponents/Footers/Footer";
import { useHistory } from "react-router-dom";
import { validCharRegex } from "../config/appRegex";
import Api from "../API/screening";
import CommonApi from "../API/CommonApiCalls";
import AppPageLoader from "../CommonComponents/PageLoader/AppPageLoader";
import { toast } from "react-toastify";
import useStateList from "../CommonComponents/_helper-functions/StateList";
import useLocationUserList from "../CommonComponents/_helper-functions/LocationUserList";
import { getFormatedDate, goToMainPage } from "../config/CommonFunctions";

const Addpatients = () => {
  let history = useHistory();
  const previousPath = history.location.state?.previousPath;
  const { locationUserList, loading } = useLocationUserList();

  // const [locationUserList, setLocationUserList] = useState(userLocationList);

  const [detail, setDetail] = useState(false);
  const submitData = {
    firstName: "",
    lastName: "",
    birthDate: "",
    gender: "",
    phone: "",
    email: "",
    address: "",
    location:
      locationUserList.locationList.length === 2
        ? locationUserList.locationList[1]
        : locationUserList.locationList[0],
    city: "",
    state: "",
    zipCode: "",
  };
  const [responseData, setResponseData] = useState(submitData);
  const [responseDataError, setResponseDataError] = useState({
    firstName: "",
    lastName: "",
    gender: "",
    birthDate: "",
    location: "",
  });

  const { stateList } = useStateList("US");
  const newStateList = stateList && stateList.map((val) => ({ value: val.id, label: val.value }));

  const [sectionLoader, setSectionLoader] = useState(false);
  const [stateListData, setStateListData] = useState(newStateList);
  const [commonError, setCommonError] = useState("");

  const handleChange = (event, name) => {
    switch (name) {
      case "firstName":
        setResponseData({ ...responseData, firstName: event.target.value });
        setResponseDataError({ ...responseDataError, firstName: "" });
        break;
      case "lastName":
        setResponseData({ ...responseData, lastName: event.target.value });
        setResponseDataError({ ...responseDataError, lastName: "" });
        break;
      case "birthDate":
        setResponseData({ ...responseData, birthDate: event });
        setResponseDataError({ ...responseDataError, birthDate: "" });
        break;
      case "phone":
        setResponseData({
          ...responseData,
          phone: event.target.value.length <= 10 ? event.target.value : responseData.phone,
        });
        setResponseDataError({ ...responseDataError, phone: "" });
        break;
      case "location":
        setResponseData({ ...responseData, location: event });
        setResponseDataError({ ...responseDataError, location: "" });
        break;
      case "gender":
        setResponseData({ ...responseData, gender: event });
        setResponseDataError({ ...responseDataError, gender: "" });
        break;
      case "address":
        setResponseData({ ...responseData, address: event.target.value });
        setResponseDataError({ ...responseDataError, address: "" });
        break;
      case "email":
        setResponseData({ ...responseData, email: event.target.value });
        setResponseDataError({ ...responseDataError, email: "" });
        break;
      case "city":
        setResponseData({ ...responseData, city: event.target.value });
        setResponseDataError({ ...responseDataError, city: "" });
        break;
      case "state":
        setResponseData({ ...responseData, state: event });
        setResponseDataError({ ...responseDataError, state: "" });
        break;
      case "zipCode":
        setResponseData({ ...responseData, zipCode: event.target.value });
        setResponseDataError({ ...responseDataError, zipCode: "" });
        break;
      default:
        break;
    }
  };
  const redirectToPreviousPage = (patientId) => {
    setResponseData(submitData);
    if (patientId === 0) {
      previousPath && previousPath === "/screen" ? goToMainPage() : history.push(previousPath);
      return;
    }
    if (patientId && patientId > 0 && previousPath && previousPath === "/screen") {
      const patientData = {
        address: responseData.address,
        city: responseData.city,
        dob: responseData?.birthDate ? getFormatedDate(responseData.birthDate) : "",
        email: responseData.email,
        firstName: responseData.firstName,
        gender: responseData.gender,
        id: patientId,
        lastName: responseData.lastName,
        lastScreenDate: "-",
        locationId: responseData.location.value,
        locationName: responseData.location.label,
        patientId: patientId,
        phone: responseData.phone,
        screened: false,
        screenedBy: "-",
        state: responseData?.state?.label ?? "",
        toggleSelected: false,
        vaccinated: false,
        zipCode: responseData.zipCode,
      };
      goToMainPage(patientData);
      return;
    }
    if (previousPath && previousPath !== "/screen") {
      history.push(previousPath);
    }
  };

  const handleSubmit = () => {
    let isError = false;
    if (responseData.firstName.trim().length === 0) {
      setResponseDataError((responseDataError) => ({
        ...responseDataError,
        firstName: "First name is required",
      }));
      isError = true;
    } else if (
      responseData.firstName.trim().length > 0 &&
      !validCharRegex.test(responseData.firstName)
    ) {
      setResponseDataError((responseDataError) => ({
        ...responseDataError,
        firstName: "Please Enter Valid first name",
      }));
      isError = true;
    } else {
      setResponseDataError((responseDataError) => ({
        ...responseDataError,
        firstName: "",
      }));
    }
    if (responseData.lastName.trim().length === 0) {
      setResponseDataError((responseDataError) => ({
        ...responseDataError,
        lastName: "Last name is required",
      }));
      isError = true;
    } else if (
      responseData.lastName.trim().length > 0 &&
      !validCharRegex.test(responseData.lastName)
    ) {
      setResponseDataError((responseDataError) => ({
        ...responseDataError,
        lastName: "Please Enter Valid last name",
      }));
      isError = true;
    } else {
      setResponseDataError((responseDataError) => ({
        ...responseDataError,
        lastName: "",
      }));
    }
    if (responseData.location.value === 0) {
      setResponseDataError((responseDataError) => ({
        ...responseDataError,
        location: "Location is required",
      }));
      isError = true;
    } else {
      setResponseDataError((responseDataError) => ({
        ...responseDataError,
        location: "",
      }));
    }
    if (responseData.gender.length === 0) {
      setResponseDataError((responseDataError) => ({
        ...responseDataError,
        gender: "Gender is required",
      }));
    } else {
      setResponseDataError((responseDataError) => ({
        ...responseDataError,
        gender: "",
      }));
    }
    if (responseData.birthDate.length === 0) {
      setResponseDataError((responseDataError) => ({
        ...responseDataError,
        birthDate: "Birthday is required",
      }));
    } else {
      setResponseDataError((responseDataError) => ({
        ...responseDataError,
        birthDate: "",
      }));
    }

    // Api call
    if (isError === false) {
      const payLoad = {
        Location: responseData.location.value,
        FirstName: responseData.firstName,
        LastName: responseData.lastName,
        Gender: responseData.gender,
        DateOfBirth: responseData.birthDate,
        Phone: detail ? responseData.phone : "",
        Email: detail ? responseData.email : "",
        ExactAddress: detail ? responseData.address : "",
        City: detail ? responseData.city : "",
        State: detail ? responseData.state.value : "",
        ZipCode: detail ? responseData.zipCode : "",
        CompanyId:
          localStorage.getItem("CompanyId") && JSON.parse(localStorage.getItem("CompanyId")),
      };
      setSectionLoader(true);

      Api.addNewPatient(payLoad)
        .then((resp) => {
          setSectionLoader(false);
          redirectToPreviousPage(resp.data);
          redirectToPreviousPage();
          toast.success("Patient Added Successfully.");
        })
        .catch((err) => {
          toast.error(err?.response?.data?.Message ?? "Something Went wrong");
          setSectionLoader(false);
        });
    }
  };

  const getStateList = () => {
    setSectionLoader(true);
    CommonApi.getState("US")
      .then((resp) => {
        let stateData = resp.data.Result.map((val) => ({ value: val.Code, label: val.Name }));
        setStateListData(stateData);
        responseData.state = stateData[0];
        setResponseData(responseData);
      })
      .catch((error) => {
        setCommonError("State list not found");
      })
      .finally(() => setSectionLoader(false));
  };

  useLayoutEffect(() => {
    getStateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (locationUserList.locationList.length === 2) {
      setResponseData({ ...responseData, location: locationUserList.locationList[1] });
    } else {
      setResponseData({ ...responseData, location: locationUserList.locationList[0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationUserList]);

  return (
    <div>
      {(sectionLoader || loading) && <AppPageLoader className={"position-fixed"} />}
      <AppNavbar />
      <div className="main-container">
        <Container fluid>
          <div className="add-patients-block">
            <h2>Add Patient</h2>

            <Card className="border-0 mb-3">
              <CardBody>
                <div className="patients-details">
                  <FormGroup>
                    <div className="patients-form">
                      <Label for="phonenum">
                        Location <span>*</span>
                      </Label>
                      <Select
                        className="style-select react-select dayfilterSelect mr-2"
                        classNamePrefix="select"
                        value={responseData.location}
                        name={"searchLocationBy"}
                        onChange={(event) => handleChange(event, "location")}
                        options={locationUserList.locationList}
                        isClearable={false}
                      />
                      <div className="err-message">{responseDataError.location}</div>
                    </div>
                    <div className="patients-form">
                      <Label for="patientname">
                        First Name <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="First Name"
                        className="patients-input"
                        value={responseData.firstName}
                        onChange={(event) => handleChange(event, "firstName")}
                      />
                      <div className="err-message">{responseDataError.firstName}</div>
                    </div>
                    <div className="patients-form">
                      <Label for="lastname">
                        Last Name <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Last Name"
                        className="patients-input"
                        value={responseData.lastName}
                        onChange={(event) => handleChange(event, "lastName")}
                      />
                      <div className="err-message">{responseDataError.lastName}</div>
                    </div>

                    <div className="patients-form">
                      <Label for="gender">
                        Gender <span>*</span>
                      </Label>
                      <FormGroup>
                        <CustomInput
                          type="radio"
                          id="ques-1"
                          className="detials-check"
                          name="gender"
                          label="Male"
                          value={0}
                          onChange={() => handleChange(0, "gender")}
                          defaultChecked={responseData.gender === 0}
                        />
                        <CustomInput
                          type="radio"
                          id="ques-2"
                          className="detials-check"
                          name="gender"
                          label="Female"
                          value={1}
                          onChange={() => handleChange(1, "gender")}
                          defaultChecked={responseData.gender === 1}
                        />
                      </FormGroup>
                      <div className="err-message">{responseDataError.gender}</div>
                    </div>
                    <div className="patients-form">
                      <Label for="pbirthday">
                        Birthday <span>*</span>
                      </Label>
                      <DatePicker
                        className="datepicker patients-input"
                        name="birthDate"
                        placeholderText="mm/dd/yyyy"
                        selected={responseData.birthDate}
                        value={responseData.birthDate}
                        onChange={(event) => handleChange(event, "birthDate")}
                        dateFormat="MM/dd/yyyy"
                        maxDate={new Date()}
                      />
                      <div className="err-message">{responseDataError.birthDate}</div>
                    </div>

                    {detail && (
                      <div className={"d-block "}>
                        <div className="patients-form">
                          <Label for="phonenum">Phone</Label>
                          <Input
                            type="number"
                            name="phone"
                            id="phonenum"
                            placeholder="Phone Number"
                            className="patients-input"
                            maxLength={10}
                            value={responseData.phone}
                            onChange={(event) => handleChange(event, "phone")}
                          />
                          <div className="err-message">{responseDataError.phone}</div>
                        </div>
                        <div className="patients-form">
                          <Label for="email">Email</Label>
                          <Input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="E-mail"
                            className="patients-input"
                            value={responseData.email}
                            onChange={(event) => handleChange(event, "email")}
                          />
                        </div>
                        <div className="patients-form">
                          <Label for="paddress">Address</Label>
                          <Input
                            type="text"
                            name="address"
                            id="paddress"
                            className="patients-input"
                            placeholder="Address"
                            value={responseData.address}
                            onChange={(event) => handleChange(event, "address")}
                          />
                        </div>
                        <div className="patients-form">
                          <Label for="pcity">City</Label>
                          <Input
                            type="text"
                            name="city"
                            id="pcity"
                            placeholder="City"
                            className="patients-input"
                            value={responseData.city}
                            onChange={(event) => handleChange(event, "city")}
                          />
                        </div>
                        <div className="patients-form">
                          <Label for="pstate">State</Label>
                          <Select
                            className="style-select react-select dayfilterSelect mr-2"
                            classNamePrefix="select"
                            name="state"
                            defaultValue={responseData.state}
                            options={stateListData}
                            isClearable={false}
                            value={responseData.state}
                            onChange={(event) => handleChange(event, "state")}
                          />
                          {commonError && (
                            <div className="err-message text-center">{commonError}</div>
                          )}
                        </div>
                        <div className="patients-form mb-0">
                          <Label for="pzipcode">Zip</Label>
                          <Input
                            type="text"
                            name="zipCode"
                            id="pzipcode"
                            className="patients-input"
                            placeholder="Zip Code"
                            value={responseData.zipCode}
                            onChange={(event) => handleChange(event, "zipCode")}
                          />
                        </div>
                      </div>
                    )}

                    <div className="patients-form">
                      <Label for="pzipcode"></Label>
                      <Button
                        className={detail ? "d-none " : "d-block fw400 title2 add-more-details"}
                        onClick={() => {
                          setDetail(!detail);
                        }}
                      >
                        + Add more details
                      </Button>
                    </div>
                  </FormGroup>
                </div>
                <div className="actions-btns d-flex">
                  <Button color="primary" className="fw400 title2 save-btn" onClick={handleSubmit}>
                    Add
                  </Button>
                  <Button
                    color="secondary"
                    outline
                    className="cancel-btn"
                    onClick={() => redirectToPreviousPage(0)}
                  >
                    Cancel
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Addpatients;
