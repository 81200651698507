import React from "react";
import { Container } from "react-bootstrap";

const Footer = () => {
  const recordlincURL = "https://www.recordlinc.com";
  const localStorageToken = localStorage.getItem("token");
  return (
    <div id="footer" className="footer">
      <Container>
        <div className="main-footer-block">
          <p>
            Call Support: <span>(800)596-0133</span>
          </p>
          <div className="d-flex justify-content-center lists">
            <div className="lists-pages">
              <a href={`${recordlincURL}/AboutUs`} target="_blank" rel="noreferrer noopener">
                About
              </a>
            </div>
            {localStorageToken === null && (
              <div className="lists-pages">
                <a href="/signup">Sign Up</a>
              </div>
            )}
            <div className="lists-pages">
              <a
                href={`${recordlincURL}/Faq/HelpAndTraining`}
                target="_blank"
                rel="noreferrer noopener"
              >
                Demo
              </a>
            </div>
            {localStorageToken === null && (
              <div className="lists-pages">
                <a href="/login">Login</a>
              </div>
            )}
            <div className="lists-pages">
              <a href={`${recordlincURL}/ContactUs`} target="_blank" rel="noreferrer noopener">
                Contact Us
              </a>
            </div>
            <div className="lists-pages">
              <a
                href={`${recordlincURL}/Faq/PrivacyPolicy`}
                target="_blank"
                rel="noreferrer noopener"
              >
                Privacy Policy
              </a>
            </div>
            <div className="lists-pages">
              <a
                href={`${recordlincURL}/Faq/TermsAndConditions`}
                target="_blank"
                rel="noreferrer noopener"
              >
                Terms & Conditions
              </a>
            </div>
          </div>
          <div className="copy-right">
            <p>
              Ⓒ {new Date().getFullYear()} - built by{" "}
              <a href="/">
                <span>DrDDS Innovations</span>
              </a>{" "}
              & Powered by{" "}
              <a href="/">
                <span>RecordLinc</span>
              </a>
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
