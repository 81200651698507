import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import BootyPagination from "../Pagination/BootyPagination";
import NoRecordFound from "../NoRecordFound";
import Api from "../../API/stateDashboard";
import AppPageLoader from "../PageLoader/AppPageLoader";

const DentalPracticeList = ({
  dentalPracticePayLoad,
  paginationDefaultPage,
  paginationPerPage,
  paginationTotalRows,
  setDentalPracticeListPayLoad,
  ...props
}) => {
  const columns = [
    {
      id: 1,
      name: "Dental Practices",
      selector: (row) => row.DentalPractice,
      sortable: true,
    },
    {
      id: 2,
      name: "Location",
      selector: (row) => row.Location,
      sortable: true,
    },
    {
      id: 3,
      name: "Provider",
      selector: (row) => row.Provider,
      sortable: true,
    },
    {
      id: 4,
      name: "State",
      selector: (row) => row.StateName,
      sortable: true,
    },
    {
      id: 5,
      name: "Screened #",
      selector: (row) => row.Screened,
      sortable: true,
    },
    {
      id: 6,
      name: "Screened %",
      selector: (row) => parseInt(row.ScreenedPercentage),
      cell: (row) => row.ScreenedPercentage,
      sortable: true,
    },
    {
      id: 7,
      name: "Vaccinated #",
      selector: (row) => row.Vaacined,
      sortable: true,
    },
    {
      id: 8,
      name: "Vaccinated %",
      selector: (row) => parseInt(row.VaccinedPercentage),
      cell: (row) => row.VaccinedPercentage,
      sortable: true,
    },
  ];

  const [dentalPracticePagePayLoad, setDentalPracticePagePayLoad] = useState(null);
  const [dentalPracticeList, setDentalPracticeList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  //   const [columns, setColumns] = useState(GridColumns);

  const [apiError, setApiError] = useState("");

  const handleOnSort = (index, direction) => {
    const newPayLoad = {
      ...dentalPracticePagePayLoad,
      SortBy: index.id,
      SortDirection: direction.toUpperCase(),
    };
    setDentalPracticePagePayLoad(newPayLoad);
    getDentalPracticeList(newPayLoad);
  };

  const handlePageChange = (data) => {
    const payLoadData = {
      ...dentalPracticePagePayLoad,
      PageIndex: data,
    };
    setDentalPracticePagePayLoad(payLoadData);
    getDentalPracticeList(payLoadData);
  };

  const getDentalPracticeList = (payLoad) => {
    const apiPayLoad = {
      AccountId: payLoad.AccountId,
      StateId: payLoad.StateId === 0 ? null : payLoad.StateId,
      ChartType: payLoad.ChartType,
      StartDate: payLoad.StartDate,
      EndDate: payLoad.EndDate,
      PageIndex: payLoad.PageIndex,
      PageSize: payLoad.PageSize,
      SortBy: payLoad.SortBy,
      SortDirection: payLoad.SortDirection,
    };
    setIsLoading(true);
    Api.getDentalPracticeList(apiPayLoad)
      .then((resp) => {
        setDentalPracticeList(resp.data.Result.Practices ?? []);
        setTotalRecords(resp.data.Result.TotalRecord);
        setApiError("");
      })
      .catch((err) => {
        setApiError(err?.response?.data?.Message);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    dentalPracticePayLoad && getDentalPracticeList(dentalPracticePayLoad);
    setDentalPracticePagePayLoad(dentalPracticePayLoad);
  }, [dentalPracticePayLoad]);

  return (
    <>
      {apiError && apiError.length > 0 && <h5 className="text-center text-danger">{apiError}</h5>}
      {isLoading && <AppPageLoader className={"position-fixed"} />}
      {!isLoading && dentalPracticeList.length > 0 && (
        <div className="float-right">
          <h5 className="mr-5 mt-2 mb-1 p-0">Total Records: {totalRecords > 0 && totalRecords}</h5>
        </div>
      )}

      <DataTable
        noDataComponent={
          isLoading ? (
            <h3>Loading...</h3>
          ) : (
            <NoRecordFound
              className={"w-100"}
              text={"There are no screening records for selected period."}
            />
          )
        }
        columns={dentalPracticePayLoad.StateId === 0 ? columns.splice(3) : columns}
        data={dentalPracticeList}
        onSort={handleOnSort}
        pagination
        paginationServer
        paginationDefaultPage={paginationDefaultPage ?? 1}
        paginationPerPage={paginationPerPage ?? 5}
        paginationTotalRows={totalRecords}
        paginationComponent={BootyPagination}
        onChangePage={handlePageChange}
        {...props}
      />
    </>
  );
};

export default DentalPracticeList;
