import React from "react";

const Input = ({
  name,
  type,
  value,
  className,
  id,
  placeholder,
  iconBefore,
  iconAfter,
  error,
  ...props
}) => {
  return (
    <div className="form-group">
      <span className="input-icon1">{iconBefore}</span>
      <input
        name={name}
        type={type}
        className={className}
        id={id}
        value={value}
        placeholder={placeholder}
        {...props}
      />
      <span className="input-icon">{iconAfter}</span>
      {error && error.length > 0 && <div className="err-message">{error}</div>}
    </div>
  );
};

export default Input;
