import React from "react";
import { ReactComponent as RightArrow } from "../../assets/images/right-arrow-round.svg";

const LRFSideBanner = ({ btnText, onClick, className }) => {
  return (
    <div className={`col-5 bg-green d-flex ${className}`}>
      <div className="justify-content-center align-self-center text-center">
        <h2>ALLIANCE FOR HPV FREE COLORADO</h2>
        <p>
          Funding for this project is supported by the Cancer, Cardiovascular and Chronic Pulmonary
          Disease Grants Program.
        </p>
        <p>Don't have an account?</p>
        <div className="btn-block signup-white-btn">
          <button type="button" className="btn btn-outline-primary blue-btn-bg " onClick={onClick}>
            {btnText} <RightArrow />
          </button>
        </div>
      </div>
    </div>
  );
};

export default LRFSideBanner;
