import React, { useEffect, useState } from "react";
import screeningData from "../../app-pages/data/screeningData";
import DataTable from "react-data-table-component";
import CommonApi from "../../API/CommonApiCalls";
import { ReactComponent as Check } from "../../assets/images/close-badge.svg";
import { ReactComponent as CheckGreen } from "../../assets/images/tick-badge.svg";
import AppPageLoader from "../PageLoader/AppPageLoader";
import BootyPagination from "../Pagination/BootyPagination";
import { Link } from "react-router-dom";
import NoRecordFound from "../NoRecordFound";
import { redirectToAddpatient } from "../../config/CommonFunctions";

const PatientList = ({
  setPageInfo,
  showRecords,
  patientListType,
  handleOnClickRow,
  patientListPayloadFromParent,
  setPatientListPayloadFromParent,
  showLabel,
  setTotalRecordsParent,
  setShowExportBtn,
  ...props
}) => {
  // ========states ===============
  const [patientList, setPatientList] = useState(screeningData);
  const [appPageLoader, setAppPageLoader] = useState(false);
  const [patientListPayload, setPatientListPayload] = useState({ ...patientListPayloadFromParent });
  const [commonError, setCommonError] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [showNoshowLink, setShowNoshowLink] = useState(false);

  const showNoShowLink = (payLoad) => {
    if (payLoad.IsTodaysAppt === false) {
      setShowNoshowLink(true);
    } else setShowNoshowLink(false);
  };
  // ================ handle events ========
  const handleOnclickRow = (data) => {
    if (!!handleOnClickRow) {
      const updatedPatientList = patientList.map((item) => {
        if (data.id !== item.id) {
          return { ...item, toggleSelected: false };
        }
        return {
          ...item,
          toggleSelected: true,
        };
      });
      setPatientList(updatedPatientList);
      handleOnClickRow(data);
    }
  };

  const handleOnSort = (column, records) => {
    const newPatientListPayload = {
      ...patientListPayload,
      SortBy: column.id,
      SortDirection: records.toUpperCase(),
    };
    setPatientListPayload(newPatientListPayload);
  };

  const getPatientList = (payload) => {
    setAppPageLoader(true);
    const payLoad = {
      PageIndex: payload.PageIndex,
      PageSize: payload.PageSize,
      SearchBy: payload.SearchBy,
      SortBy: payload.SortBy,
      SortDirection: payload.SortDirection,
      LocationId: payload.LocationId,
      AppointmentDate: {
        FromDate: payload?.AppointmentDate?.from ?? null,
        ToDate: payload?.AppointmentDate?.to ?? null,
      },
      ScreenedDate: {
        FromDate: payload?.ScreenedDate?.from ?? null,
        ToDate: payload?.ScreenedDate?.to ?? null,
      },
      IsTodaysAppt: payload.IsTodaysAppt,
      ChartType: payload.ChartType ?? null,
    };
    CommonApi.getPatientList(payLoad)
      .then((resp) => {
        if (resp.data.IsSuccess) {
          if (!!setShowExportBtn) {
            resp.data.Result.Patients === null ? setShowExportBtn(false) : setShowExportBtn(true);
          }
          const newPatientListData = resp.data.Result.Patients.map((val) => ({
            id: val.PatientId,
            firstName: val?.FirstName.length > 0 ? val.FirstName : "-",
            lastName: val?.LastName.length > 0 ? val.LastName : "-",
            dob: val?.DOB.length > 0 ? val.DOB : "-",
            gender: val?.Gender.length > 0 ? val.Gender : "-",
            screened: val.IsScreened === 0 ? <Check /> : <CheckGreen />,
            vaccinated: val.IsVaccinated === 0 ? <Check /> : <CheckGreen />,
            lastScreenDate: val?.LastScreenDate.length > 0 ? val.LastScreenDate : "-",
            locationId: val.LocationId,
            locationName: val?.LocationName.length > 0 ? val.LocationName : "-",
            patientId: val.PatientId,
            screenedBy: val.ScreenedBy.length > 0 ? val.ScreenedBy : "-",
            toggleSelected: false,
            city: val.City,
            state: val.State,
            address: val.Address,
            zipCode: val.ZipCode,
            email: val.Email,
            phone: val.Phone,
            appointmentDate: val.AppointmentDate,
            screeningId: val.ScreeningId
          }));

          if (!!setPageInfo) setPageInfo(payLoad);
          setPatientList(newPatientListData);
          setTotalRecords(resp.data.Result.TotalRecord);
          if (!!setTotalRecordsParent) setTotalRecordsParent(resp.data.Result.TotalRecord);
          setCommonError("");
        } else {
          setPatientList([]);
          setTotalRecords(0);
        }
        setAppPageLoader(false);
      })
      .catch((err) => {
        setCommonError(err?.response?.data?.Message);
        showNoShowLink(payLoad);
        setPatientList([]);
        setAppPageLoader(false);
        setTotalRecords(0);
      });
    // .finally(() => setAppPageLoader(false));
  };

  useEffect(() => {
    getPatientList(patientListPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientListPayload]);

  useEffect(() => {
    if (!!patientListPayloadFromParent?.Reload && patientListPayloadFromParent.Reload === true) {
      ResetPatientList();
    } else {
      const newPatientListPayload = { ...patientListPayload, ...patientListPayloadFromParent };
      setPatientListPayload(newPatientListPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientListPayloadFromParent]);

  // =========================== pagination ========================
  const handlePageChange = (PageIndex) => {
    const newPatientListPayload = { ...patientListPayload, PageIndex };
    setPatientListPayload(newPatientListPayload);
  };

  const ResetPatientList = () => {
    const newPatientList = patientList.map((patient) => ({ ...patient, toggleSelected: false }));
    setPatientList(newPatientList);
  };

  const handleChangeIsTodaysAppt = () => {
    setPatientListPayload({ ...patientListPayload, IsTodaysAppt: false });
    setPatientListPayloadFromParent({
      ...patientListPayloadFromParent,
      IsTodaysAppt: false,
    });
  };

  return (
    <>
      <div
        className={`d-flex mb-1 ${showLabel ? "justify-content-between" : "justify-content-end"}`}
      >
        {!appPageLoader && showRecords && totalRecords > 0 && patientList.length > 0 && (
          <div className="ml-auto mt-3 pr-3">
            <h5 className="m-0 p-0">{`Total Records: ${totalRecords}`}</h5>
          </div>
        )}
      </div>
      {appPageLoader && <AppPageLoader className={"position-fixed"} />}
      {commonError && (
        <div className="text-center my-2">
          <h5>{commonError}</h5>
        </div>
      )}
      {patientList && patientList.length === 0 ? (
        <NoRecordFound
          text={
            showLabel
              ? "There are no appointments for today"
              : "There are no appointments for selected period"
          }
        >
          {showNoshowLink ||
            (patientListPayload && patientListPayload.IsTodaysAppt && (
              <h5 className="mt-2">
                <Link onClick={() => handleChangeIsTodaysAppt()}>
                  Click here to display all records
                </Link>{" "}
                or{" "}
                <Link onClick={() => redirectToAddpatient()}>click here to add new patient.</Link>
              </h5>
            ))}
        </NoRecordFound>
      ) : (
        <DataTable
          noDataComponent={appPageLoader ? null : patientList}
          data={appPageLoader ? screeningData : patientList}
          onRowClicked={handleOnclickRow}
          onSort={handleOnSort}
          pagination={!appPageLoader}
          paginationServer
          paginationDefaultPage={patientListPayload.PageIndex}
          paginationPerPage={patientListPayload.PageSize}
          paginationTotalRows={totalRecords}
          onChangePage={handlePageChange}
          paginationComponent={BootyPagination}
          {...props}
        />
      )}
    </>
  );
};
export default PatientList;
