import React from "react";

const SelectDropdown = ({
  icon,
  value,
  handleChange,
  options,
  error,
  parentClassName,
  className,
  name,
}) => {
  return (
    <>
      <div className={parentClassName}>
        <span className="input-icon1">{icon}</span>
        <select className={className} name={name} value={value} onChange={handleChange}>
          {options &&
            options.map((item) => (
              <option value={item.value} key={item.id}>
                {item.label}
              </option>
            ))}
        </select>
      </div>
      {error && <div className="err-message mb-3">{error}</div>}
    </>
  );
};

export default SelectDropdown;
