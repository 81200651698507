import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as Icon1 } from "../assets/images/padlock.svg";
import { ReactComponent as RightArrow } from "../assets/images/right-arrow-round.svg";
import queryString from "query-string";
import { validPasswordRegex } from "../config/appRegex";
import { goToSignin, validateForm } from "../config/CommonFunctions";
import Api from "../API/login";
import LRFLayout from "./SectionCommonComponent/LRFLayout";
import Input from "../CommonComponents/Input/Input";
import BlockButton from "./SectionCommonComponent/BlockButton";
import AppPageLoader from "../CommonComponents/PageLoader/AppPageLoader";
// export default function ResetPasswordPage(){
const ResetPasswordPage = (props) => {
  const [eyeTog2, seteyeTog2] = useState("false");

  const [eyeTog3, seteyeTog3] = useState("false");

  let history = useHistory();

  const redirectToSignin = () => {
    history.push("/login");
  };

  const handleToggleeyeTog2 = () => {
    passwordTextBoxType === "password"
      ? setPasswordTextBoxType("text")
      : setPasswordTextBoxType("password");
    seteyeTog2(!eyeTog2);
  };

  const handleToggleeyeTog3 = () => {
    conPasswordTextBoxType === "password"
      ? setConPasswordTextBoxType("text")
      : setConPasswordTextBoxType("password");
    seteyeTog3(!eyeTog3);
  };

  // Set loader model
  const [loader, setLoader] = useState(false);

  // Set response message model
  const [rMessage, setRMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");

  // Set password field as password.
  const [passwordTextBoxType, setPasswordTextBoxType] = useState("password");

  // Set confirm password field as password.
  const [conPasswordTextBoxType, setConPasswordTextBoxType] = useState("password");

  // Set reset password model
  const [resetPasswordModel, setResetPasswordModel] = useState({
    Password: "",
    ConPassword: "",
    Email: "",
  });

  // Set reset password error model
  const [resetPasswordErrorModel, setResetPasswordErrorModel] = useState({
    Password: "",
    ConPassword: "",
  });

  // Get encrypted email from query string
  useEffect(() => {
    const qString = queryString.parse(window.location.search);
    if (qString != null && qString.Eamil != undefined) {
      setResetPasswordModel((resetPasswordModel) => ({
        ...resetPasswordModel,
        Email: qString.Eamil,
      }));
    }
  }, []);

  // Handel change event of reset password form
  const handelChanges = (event) => {
    event.preventDefault();
    let { name, value } = event.target;

    setResetPasswordModel((resetPasswordModel) => ({
      ...resetPasswordModel,
      [name]: value,
    }));

    let errorMsg = "";
    switch (name) {
      case "Password":
        if (value.length === 0) {
          errorMsg = "New Password is required.";
        } else {
          errorMsg = validPasswordRegex.test(value) ? "" : "Enter valid Password.";
        }
        break;
      case "ConPassword":
        if (value.length === 0) {
          errorMsg = "Confirm New Password is required.";
        } else if (resetPasswordModel.Password != value) {
          errorMsg = "Password don't match.";
        }
        break;
      default:
        break;
    }

    setResetPasswordErrorModel((resetPasswordErrorModel) => ({
      ...resetPasswordErrorModel,
      [name]: errorMsg,
    }));
  };

  // Handel submit event
  const handelSubmit = (event) => {
    event.preventDefault();
    let flagError = false;
    if (resetPasswordModel.Password.length === 0) {
      setResetPasswordErrorModel((resetPasswordErrorModel) => ({
        ...resetPasswordErrorModel,
        Password: "New Password required.",
      }));
      flagError = true;
    }
    if (resetPasswordModel.ConPassword.length === 0) {
      setResetPasswordErrorModel((resetPasswordErrorModel) => ({
        ...resetPasswordErrorModel,
        ConPassword: "Confirm Password required.",
      }));
      flagError = true;
    }
    if (validateForm(resetPasswordErrorModel) && !flagError) {
      setLoader(true);
      Api.resetPassword(resetPasswordModel)
        .then((res) => {
          setLoader(false);
          setRMessage(res.data.Message);
          setTimeout(() => redirectToSignin(), 5000);
        })
        .catch((error) => {
          setLoader(false);
          setErrorMessage(error.response.data.Message);
        });
    }
  };
  return (
    <LRFLayout
      titleText={"Reset Password"}
      sideBannerText={"sign in"}
      sideBannerRoute={goToSignin}
      sideBannerPositionCode={0}
      headerLine={"Please enter your email and we will send you email to reset your password."}
    >
      {loader && <AppPageLoader />}
      <div className="text-success">{rMessage}</div>
      <div className="text-danger">{ErrorMessage}</div>
      <Input
        iconBefore={<Icon1 />}
        type={passwordTextBoxType}
        onChange={handelChanges}
        className="form-control light-border pass-input"
        id="Password"
        placeholder="New Password*"
        name="Password"
        value={resetPasswordModel.Password}
        error={resetPasswordErrorModel.Password}
        iconAfter={
          <i
            className={`passTogEye icon-eye ${!eyeTog2 ? "passVisible" : ""}`}
            onClick={handleToggleeyeTog2}
          ></i>
        }
      />
      <Input
        iconBefore={<Icon1 />}
        type={conPasswordTextBoxType}
        className="form-control light-border pass-input"
        id="ConPassword"
        placeholder="Confirm New Password*"
        name="ConPassword"
        value={resetPasswordModel.ConPassword}
        onChange={handelChanges}
        error={resetPasswordErrorModel.ConPassword}
        iconAfter={
          <i
            className={`passTogEye icon-eye ${!eyeTog3 ? "passVisible" : ""}`}
            onClick={handleToggleeyeTog3}
          ></i>
        }
      />
      <BlockButton text={"Request Reset Password link"} onClick={handelSubmit} />
    </LRFLayout>
  );
};
export default ResetPasswordPage;
