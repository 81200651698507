import React from "react";
import { ChevronLeft, ChevronRight } from "react-feather";

const BootyPagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage, // available but not used here
  currentPage,
}) => {
  const getNumberOfPages = (rowCount, rowsPerPage) => {
    return Math.ceil(rowCount / rowsPerPage);
  };
  const toPages = (pages) => {
    const results = [];
    for (let i = 1; i <= pages; i++) {
      results.push(i);
    }
    return results;
  };
  const handleBackButtonClick = () => {
    onChangePage(currentPage - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(currentPage + 1);
  };

  const handlePageNumber = (e) => {
    onChangePage(Number(e.target.value));
  };

  const pages = getNumberOfPages(rowCount, rowsPerPage);
  const pageItems = toPages(pages);
  const nextDisabled = currentPage === pageItems.length;
  const previosDisabled = currentPage === 1;

  return (
    <div className="pagination justify-content-center">
      <div className="page-item">
        <button
          className="page-link"
          onClick={handleBackButtonClick}
          disabled={previosDisabled}
          aria-disabled={previosDisabled}
          aria-label="previous page"
        >
          <ChevronLeft />
        </button>
      </div>
      <div className="pagination-main">
        {pageItems.map((page) => {
          const className = page === currentPage ? "page-item active" : "page-item";

          return (
            <div key={page} className={className}>
              <button className="page-link" onClick={handlePageNumber} value={page}>
                {page}
              </button>
            </div>
          );
        })}
      </div>
      <div className="page-item">
        <button
          className="page-link"
          onClick={handleNextButtonClick}
          disabled={nextDisabled}
          aria-disabled={nextDisabled}
          aria-label="next page"
        >
          <ChevronRight />
        </button>
      </div>
    </div>
  );
};

export default BootyPagination;
