import React, { useEffect, useState } from "react";
import { ReactComponent as Icon1 } from "../../assets/images/practice-name.svg";
import { ReactComponent as Icon3 } from "../../assets/images/dental.svg";
import { ReactComponent as Icon4 } from "../../assets/images/practice.svg";
import { Multiselect } from "multiselect-react-dropdown";

import Api from "../../API/signup";
import CommonApi from "../../API/CommonApiCalls";
import AppPageLoader from "../../CommonComponents/PageLoader/AppPageLoader";
import { toast } from "react-toastify";
import LRFLayout from "../SectionCommonComponent/LRFLayout";
import Input from "../../CommonComponents/Input/Input";
import { goToSignin } from "../../config/CommonFunctions";
import SelectDropdown from "../SectionCommonComponent/SelectDropdown";
import BlockButton from "../SectionCommonComponent/BlockButton";
import BackButton from "../SectionCommonComponent/BackButton";

const SignUpPage3 = (props) => {
  const { previousPage, postData } = props;
  const defaultPracticeInfo = {
    PracticeName: "",
    Speciality: "",
    OtherPraticeManagement: "",
  };

  const [commonError, setCommonError] = useState("");
  const [practiceInfo, setPracticeInfo] = useState(postData);
  const [practiceInfoError, setPracticeInfoError] = useState(defaultPracticeInfo);
  const [pmsOptions, setPmsOptions] = useState([]);
  const [specialityOptions, setspecialityOptions] = useState([]);
  const [sectionLoader, setSectionLoader] = useState(false);

  const handleChange = (event, inputName) => {
    if (inputName === "Speciality") {
      setPracticeInfo({ ...practiceInfo, Speciality: event.map((value) => value.id) });
    } else {
      const { name, value } = event.target;
      setPracticeInfo({ ...practiceInfo, [name]: value });
    }
    let errorValue = "";
    switch (inputName) {
      case "PracticeName":
        if (event.target.value.trim().length === 0) errorValue = "Practice Name is required.";
        break;
      default:
        break;
    }
    setPracticeInfoError((parcticeErrorModel) => ({
      ...parcticeErrorModel,
      [inputName]: errorValue,
    }));
  };

  const handleSubmit = () => {
    let flagError = false;
    if (practiceInfo.PracticeName.length === 0) {
      setPracticeInfoError((practiceInfoError) => ({
        ...practiceInfoError,
        PracticeName: "Practice Name is required.",
      }));
      flagError = true;
    }
    if (practiceInfo.Speciality.length === 0) {
      setPracticeInfoError((practiceInfoError) => ({
        ...practiceInfoError,
        Speciality: "Dental Speciality is required.",
      }));
      flagError = true;
    }
    if (practiceInfo.OtherPraticeManagement.length === 0) {
      setPracticeInfoError((practiceInfoError) => ({
        ...practiceInfoError,
        OtherPraticeManagement: "Practice Management System is required.",
      }));
      flagError = true;
    }

    if (!flagError) {
      const payLoad = {
        ...postData,
        Speciality: practiceInfo.Speciality,
        PracticeName: practiceInfo.PracticeName,
        OtherPraticeManagement: practiceInfo.OtherPraticeManagement,
      };
      setSectionLoader(true);
      Api.signUp(payLoad)
        .then((res) => {
          setSectionLoader(false);
          if (res.data.IsSuccess) {
            toast.success(
              "Please check your email for a confirmation email to login to your account."
            );
            props.next();
          }
        })
        .catch((error) => {
          setSectionLoader(false);
          setCommonError(error?.response?.data?.Message);
        })
        .finally(() => setSectionLoader(false));
    }
  };

  useEffect(() => {
    CommonApi.getIntegrationList()
      .then((response) => {
        const integrationOptionList = response.data.Result.filter((val) => val.IsActive).map(
          ({ Id, Name }) => ({
            value: Id,
            label: Name,
          })
        );
        setPmsOptions(integrationOptionList);
        setPracticeInfo({
          ...practiceInfo,
          OtherPraticeManagement: integrationOptionList[0].value,
        });
      })
      .catch((error) => {
        setPmsOptions([]);
        setCommonError("There is some error in Practice Management System option list");
      });

    CommonApi.getSpecialities()
      .then((response) => {
        const specialityList = response.data.Result.map(({ Id, Description }) => ({
          id: Id,
          value: Description,
        }));
        setspecialityOptions(specialityList);
      })
      .catch((error) => {
        setspecialityOptions([]);
        setCommonError("There is some error in speciality option list");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {sectionLoader && <AppPageLoader className={"position-fixed"} />}
      <LRFLayout
        titleText={"Sign up"}
        sideBannerText={"sign in"}
        sideBannerRoute={goToSignin}
        sideBannerPositionCode={1}
        headerLine={"Enter your Practice information"}
      >
        {commonError?.length > 0 && <div className="err-message">{commonError}</div>}
        <form>
          <div className="content">
            <Input
              name="PracticeName"
              type="text"
              id="PracticeName"
              className="form-control light-border"
              placeholder="Practice Name*"
              onChange={(e) => handleChange(e, "PracticeName")}
              value={practiceInfo.PracticeName}
              iconBefore={<Icon1 />}
              error={practiceInfoError.PracticeName}
            />
            <div className="form-group has-value select-form-group">
              <Multiselect
                name={"Speciality"}
                id={"Speciality"}
                className="multi-select"
                options={specialityOptions} // Options to display in the dropdown
                showCheckbox={true}
                placeholder="Select Dental Specialty"
                displayValue="value"
                onSelect={(selectedList) => handleChange(selectedList, "Speciality")}
                closeOnSelect={false} // Property name to display in the dropdown options
              />
              <span className="input-icon1">
                <Icon3 />
              </span>
              <span className="select-down-arrow">
                <i className="fa fa-chevron-down" aria-hidden="true"></i>
              </span>
            </div>
            <div className="err-message">{practiceInfoError.Speciality}</div>
            <SelectDropdown
              icon={<Icon4 />}
              value={practiceInfo.OtherPraticeManagement}
              name={"OtherPraticeManagement"}
              handleChange={(e) => handleChange(e, "OtherPraticeManagement")}
              options={pmsOptions}
              error={practiceInfoError.OtherPraticeManagement}
              parentClassName={"form-group has-value select-form-group"}
              className={"form-control light-border"}
            />
          </div>
          <BlockButton onClick={handleSubmit} text={"sign up"} />
          <BackButton onClick={previousPage} />
        </form>
      </LRFLayout>
    </>
  );
};
export default SignUpPage3;
