import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as Icon1 } from "../assets/images/padlock.svg";
import Icon3 from "../assets/images/logo.png";
import Home from "../Home";
import { Modal, ModalBody } from "reactstrap";
import { ReactComponent as RightArrow } from "../assets/images/right-arrow-round.svg";

// export default function ChangePassPage(){
const ChangePassPage = (props) => {
  let history = useHistory();
  const redirectToSignin = () => {
    history.push("/login");
  };
  const [eyeTog1, seteyeTog1] = useState("false");
  const handleToggleeyeTog1 = () => {
    seteyeTog1(!eyeTog1);
  };
  const [eyeTog2, seteyeTog2] = useState("false");
  const handleToggleeyeTog2 = () => {
    seteyeTog2(!eyeTog2);
  };
  const [eyeTog3, seteyeTog3] = useState("false");
  const handleToggleeyeTog3 = () => {
    seteyeTog3(!eyeTog3);
  };
  const {} = props;
  const [modal, setModal] = useState(true);

  const toggle = () => setModal(modal);

  return (
    <section className="common-section vh-0">
      <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered sign-up-modal">
        <ModalBody className="main-block signin-block">
          <div className="login sub-block">
            <div className="row justify-content-center">
              <div className="col-lg-7 pr-lg-0">
                <div className="d-block">
                  <div className="inner-block text-center">
                    <div className="block-1">
                      <h1>Change Password</h1>
                      <p className="mb-2">
                        {" "}
                        Your new password must be different from old passwords.
                      </p>
                    </div>

                    <form>
                      <div className="content">
                        <div className="form-group">
                          <span className="input-icon1">
                            <Icon1 />
                          </span>
                          <input
                            type="password"
                            className="form-control light-border pass-input"
                            id="exampleInputPassword1"
                            placeholder="Old Password*"
                          />

                          <span className="input-icon">
                            <i
                              className={`passTogEye icon-eye ${!eyeTog1 ? "passVisible" : ""}`}
                              onClick={handleToggleeyeTog1}
                            ></i>
                          </span>
                        </div>
                        <div className="form-group">
                          <span className="input-icon1">
                            <Icon1 />
                          </span>
                          <input
                            type="password"
                            className="form-control light-border pass-input"
                            id="exampleInputPassword1"
                            placeholder="New Password*"
                          />

                          <span className="input-icon">
                            <i
                              className={`passTogEye icon-eye ${!eyeTog2 ? "passVisible" : ""}`}
                              onClick={handleToggleeyeTog2}
                            ></i>
                          </span>
                        </div>
                        <div className="form-group">
                          <span className="input-icon1">
                            <Icon1 />
                          </span>
                          <input
                            type="password"
                            className="form-control light-border pass-input"
                            id="exampleInputPassword1"
                            placeholder="Confirm New Password*"
                          />

                          <span className="input-icon">
                            <i
                              className={`passTogEye icon-eye ${!eyeTog3 ? "passVisible" : ""}`}
                              onClick={handleToggleeyeTog3}
                            ></i>
                          </span>
                        </div>
                      </div>
                      <div className="account-btn-block">
                        <div className="btn-block">
                          <button type="button" className="btn btn-outline-primary   blue-btn-bg ">
                            Save
                          </button>
                        </div>
                        <div className="forget-link  text-right link-purple signup-link">
                          <p>
                            Already have Account ?
                            <a href="#" className="h5 f-600 pl-2" onClick={redirectToSignin}>
                              Sign in
                            </a>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-5 bg-green d-flex">
                <div className="justify-content-center align-self-center text-center">
                  <img src={Icon3} className="img-fluid logo-white" />
                  <h1>Welcome</h1>
                  <p>To keep connected with us please login with your personal info</p>
                  <div className="btn-block">
                    <div className="btn-block signup-white-btn">
                      <button
                        type="button"
                        className="btn btn-outline-primary blue-btn-bg "
                        onClick={redirectToSignin}
                      >
                        sign in <RightArrow />
                      </button>
                    </div>
                    <p>Remember your password?</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Home />
    </section>
  );
};
export default ChangePassPage;
