import axios from "axios";
import { API_URL } from "../config/appConstatnts";

class Api {
  // API for screening
  static GetReportSummary = (payLoad) => {
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Company_Id: localStorage.getItem("CompanyId"),
        access_token: localStorage.getItem("token"),
      },
    };
    return axios.post(API_URL + "/HPV/GetReportSummary", payLoad, HEADER);
  };
  static GetReportSummaryChart = (payLoad) => {
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Company_Id: localStorage.getItem("CompanyId"),
        access_token: localStorage.getItem("token"),
      },
    };
    return axios.post(API_URL + "/HPV/GetReportSummaryChart", payLoad, HEADER);
  };
  static ExportPatientData = (payLoad) => {
    const HEADER = {
      headers: {
        "Content-Type": "application/json",
        Company_Id: localStorage.getItem("CompanyId"),
        access_token: localStorage.getItem("token"),
      },
    };
    return axios.post(API_URL + "/HPV/ExportPatientData", payLoad, HEADER);
  };
}
export default Api;
