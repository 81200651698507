import { useEffect, useState } from "react";
import Api from "../../API/CommonApiCalls";

const useHPVQAList = (hpvqaId) => {
  const [HPVQAList, setHPVQAList] = useState(null);
  const [HPVQAListError, setHPVQAListError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const response = await Api.getQA(hpvqaId);
        setHPVQAList(
          response.data.Result.map(({ Id, OrderBy, Question, Type }) => ({
            id: Id,
            q: Question,
            type: Type,
            OrderBy,
            a: "",
            error: "",
          }))
        );
      } catch (err) {
        setHPVQAListError(err);
        setHPVQAList([]);
      } finally {
        setLoading(false);
      }
    })();
  }, [hpvqaId]);

  return { HPVQAList, HPVQAListError, loading };
};
export default useHPVQAList;
