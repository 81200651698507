import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import Api from "../../API/reports";
import { ReactComponent as Aware } from "../../assets/images/aware.svg";
import { ReactComponent as Check } from "../../assets/images/check.svg";
import { ReactComponent as CheckGreen } from "../../assets/images/check-green.svg";
import { ReactComponent as Question } from "../../assets/images/question-count.svg";
import AppPageLoader from "../PageLoader/AppPageLoader";

export const DashboardCards = ({ bgClassName, title, count, percentage, cardLogo }) => {
  return (
    <Col lg="12">
      <Card className={`border-0 mb-4 ${bgClassName ?? "purple-bg"}`}>
        <CardBody>
          <CardTitle>{title}</CardTitle>
          <div className="count-detail">
            <div className="numbers">
              <span>{count}</span>
            </div>
            <div className="percentage">
              <span>{percentage}</span>
              {cardLogo}
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};
export const CardSection = ({ payLoad, pageType }) => {
  const [cardsData, setCardsDate] = useState([]);
  const [errorSection, setErrorSection] = useState("");
  const [appPageLoader, setAppPageLoader] = useState(false);
  const cardData = [
    {
      bgClassName: "purple-bg",
      title: "Patients Aware of HPV Vaccine Count & Percentage",
      count: "6,589",
      percentage: "34%",
      cardLogo: <Aware />,
    },
    {
      bgClassName: "info-bg",
      title: "Completed the HPV Vaccine Series",
      count: "89,000",
      percentage: "34%",
      cardLogo: <Check />,
    },
    {
      bgClassName: "success-bg",
      title: "Oral Cancer Screening Performed",
      count: "6,589,000",
      percentage: "34%",
      cardLogo: <CheckGreen />,
    },
    {
      bgClassName: "purple-bg",
      title: "HPV Vaccination Education Provided",
      count: "6,589,000",
      percentage: "34%",
      cardLogo: <Question />,
    },
  ];

  const getReportSummaryApi = (payLoad) => {
    setAppPageLoader(true);
    Api.GetReportSummary(payLoad)
      .then((resp) => {
        setCardsDate(
          resp.data.Result.map((value, key) => ({
            bgClassName: cardData[key].bgClassName,
            title: value.Label,
            count: value.Count,
            percentage: value.Percentage,
            cardLogo: cardData[key].cardLogo,
          }))
        );
        setErrorSection("");
      })
      .catch((err) => {
        setErrorSection(err?.response?.data?.Message);
      })
      .finally(() => setAppPageLoader(false));
  };
  const getStateDashboardSummaryApi = (payLoad) => {
    setAppPageLoader(true);
    const apiPayLoad = {
      AccountId: payLoad.AccountListId.value,
      ChartType: payLoad.ChartType,
      EndDate: payLoad.EndDate,
      StartDate: payLoad.StartDate,
      StateId: payLoad.StateListId.value === 0 ? null : payLoad.StateListId.value,
    };
    Api.GetReportSummary(apiPayLoad)
      .then((resp) => {
        setCardsDate(
          resp.data.Result.map((value, key) => ({
            bgClassName: cardData[key].bgClassName,
            title: value.Label,
            count: value.Count,
            percentage: value.Percentage,
            cardLogo: cardData[key].cardLogo,
          }))
        );
        setErrorSection("");
      })
      .catch((err) => {
        setErrorSection(err?.response?.data?.Message);
      })
      .finally(() => setAppPageLoader(false));
  };
  useEffect(() => {
    if (pageType === "reports") getReportSummaryApi(payLoad);
    if (pageType === "stateDashboard") getStateDashboardSummaryApi(payLoad);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payLoad]);

  return (
    <Col xl="5">
      {appPageLoader && <AppPageLoader className={"position-fixed"} />}
      <Row>
        <>
          <div className="my-5 text-center">
            {errorSection && <h6 className="text-danger ">{errorSection}</h6>}
            {!appPageLoader && cardsData.length === 0 && (
              <h6 className="text-center">No Data Found!!</h6>
            )}
          </div>
          {cardsData.map(({ bgClassName, title, count, percentage, cardLogo }, key) => (
            <DashboardCards
              key={`Card-${key}`}
              bgClassName={bgClassName}
              title={title}
              count={count}
              percentage={percentage}
              cardLogo={cardLogo}
            />
          ))}
        </>
      </Row>
    </Col>
  );
};
