import React, { useEffect, useState } from "react";
import AppNavbar from "../CommonComponents/Navbars/AppNavbar";
import Select from "react-select";
//import Search from '../assets/images/search.svg'
import { Card, CardBody, Container, FormGroup, Input, Button } from "reactstrap";
import DatePicker from "react-datepicker";
import Search from "../assets/images/search.svg";
import Footer from "../CommonComponents/Footers/Footer";
import { handleOnScreenBtnClick, redirectToAddpatient } from "../config/CommonFunctions";
import { useHistory } from "react-router-dom";
import CommonApi from "../API/CommonApiCalls";
import AppPageLoader from "../CommonComponents/PageLoader/AppPageLoader";
import PatientList from "../CommonComponents/PatientList";

const Patients = () => {
  let history = useHistory();

  const columns = [
    {
      name: "Action",
      selector: (row) => row.action,
      cell: (row) => (
        <Button
          color="primary"
          style={{ borderRadius: "12px" }}
          outline
          onClick={(e) => handleOnScreenBtnClickPatientList(row, e)}
        >
          Screen
        </Button>
      ),
      sortable: false,
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
      sortable: true,
    },
    {
      name: "DOB",
      selector: (row) => row.dob,
      sortable: true,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row.locationName,
      sortable: true,
    },
    {
      name: "Screened",
      selector: (row) => row.screened,
      // sortable: true,
    },
    {
      name: "Screened By",
      selector: (row) => row.screenedBy,
      sortable: true,
    },
    {
      name: "Last Screening",
      selector: (row) => row.lastScreenDate,
      sortable: true,
    },
    {
      name: "Vaccinated",
      selector: (row) => row.vaccinated,
      // sortable: true,
    },
  ];

  const initialState = {
    SearchBy: "",
    LocationId: { value: 0, label: "Select Location" },
    AppointmentDate: {
      from: null,
      to: null,
    },
    ScreenedDate: {
      from: null,
      to: null,
    },
    IsTodaysAppt: false,
  };
  //=================================== states =========================

  const [appPageLoader, setAppPageLoader] = useState(false);
  const [userLocationList, setUserLocationList] = useState({
    locationList: [{ value: 0, label: "Select Location" }],
    usersList: [{ value: 0, label: "Select Doctor" }],
  });
  const [commonError, setCommonError] = useState({ userLocationListApiError: "" });
  const [patientPageState, setPatientPageState] = useState(initialState);
  const [patientListPayload, setPatientListPayload] = useState({
    ...initialState,
    PageIndex: 1,
    PageSize: 10,
    SearchBy: "",
    SortBy: 1,
    SortDirection: "ASC",
    AppointmentDate: {
      from: null,
      to: null,
    },
    ScreenedDate: {
      from: null,
      to: null,
    },
    LocationId: initialState.LocationId.value,
  });
  const resetCustomeDate = {
    AppointmentDate: {
      from: null,
      to: null,
    },
    ScreenedDate: {
      from: null,
      to: null,
    },
    IsTodaysAppt: false,
  };
  const resetCustomeDateError = {
    AppointmentDate: {
      from: "",
      to: "",
    },
    ScreenedDate: {
      from: "",
      to: "",
    },
    IsTodaysAppt: false,
  };
  const [customeDate, setCustomeDate] = useState(resetCustomeDate);
  const [customeDateError, setCustomeDateError] = useState(resetCustomeDateError);
  const [pageInfo, setPageInfo] = useState(initialState);
  const [searchInput, setSearchInput] = useState("");

  // ================================== handle events ===================
  const changePageInfo = (pageInfo) => {
    setPageInfo(pageInfo);
  };
  const handleOnScreenBtnClickPatientList = (row, event) => {
    handleOnScreenBtnClick(row.patientId, history.location.pathname, pageInfo);
  };
  const handleChange = (event, name) => {
    const newPayLoad = patientPageState;
    switch (name) {
      case "LocationId":
        newPayLoad.LocationId = event;
        break;

      default:
        break;
    }

    setPatientPageState({ ...newPayLoad, SearchBy: searchInput });
    setPatientListPayload({
      ...newPayLoad,
      LocationId: newPayLoad.LocationId.value,
      SearchBy: searchInput,
    });
  };
  const handleCustomeDateChange = (event, name) => {
    const newCustomDate = { ...customeDate };
    switch (name) {
      case "AppointmentFromDate":
        newCustomDate.AppointmentDate.from = event;
        break;
      case "AppointmentToDate":
        newCustomDate.AppointmentDate.to = event;
        break;
      case "ScreenedFromDate":
        newCustomDate.ScreenedDate.from = event;
        break;
      case "ScreenedToDate":
        newCustomDate.ScreenedDate.to = event;
        break;
      default:
        break;
    }
    setCustomeDate(newCustomDate);
  };
  const handleCustomeDateSubmit = (name) => {
    if (name === "Appointment") {
      if (customeDate.AppointmentDate.from !== null && customeDate.AppointmentDate.to === null) {
        setCustomeDateError({
          ...customeDateError,
          AppointmentDate: {
            from: "",
            to: "Please enter appointment to date",
          },
        });
        return;
      }
      if (customeDate.AppointmentDate.to !== null && customeDate.AppointmentDate.from === null) {
        setCustomeDateError({
          ...customeDateError,
          AppointmentDate: {
            from: "Please enter appointment from date",
            to: "",
          },
        });
        return;
      }
      setPatientListPayload({ ...patientListPayload, ...customeDate });
      setCustomeDateError(resetCustomeDateError);
    }
    if (name === "Screening") {
      if (customeDate.ScreenedDate.from !== null && customeDate.ScreenedDate.to === null) {
        setCustomeDateError({
          ...customeDateError,
          ScreenedDate: {
            from: "",
            to: "Please enter screened to date",
          },
        });
        return;
      }
      if (customeDate.ScreenedDate.to !== null && customeDate.ScreenedDate.from === null) {
        setCustomeDateError({
          ...customeDateError,
          ScreenedDate: {
            from: "Please enter screened from date",
            to: "",
          },
        });
        return;
      }
      setPatientListPayload({ ...patientListPayload, ...customeDate });
      setCustomeDateError(resetCustomeDateError);
    }
  };
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };
  const handleSubmitSearch = () => {
    setPatientListPayload({ ...patientListPayload, SearchBy: searchInput });
  };
  const handleCustomeDateReset = () => {
    setPatientListPayload({
      ...patientListPayload,
      AppointmentDate: {
        from: null,
        to: null,
      },
      ScreenedDate: {
        from: null,
        to: null,
      },
      SearchBy: "",
      LocationId: 0,
      IsTodaysAppt: false,
    });
    setPatientPageState({ ...initialState, IsTodaysAppt: false });
    setCustomeDate(resetCustomeDate);
    setCustomeDateError(resetCustomeDateError);
    setSearchInput("");
  };

  // ================================= get api calls =====================
  const getDoctorLocationList = () => {
    setAppPageLoader(true);
    CommonApi.getLocationDoctorList()
      .then((resp) => {
        if (resp.data.IsSuccess) {
          const locationList = [
            ...userLocationList.locationList,
            ...resp.data.Result.Location.map(({ Id, Name }) => ({ value: Id, label: Name })),
          ];
          const usersList = [
            ...userLocationList.usersList,
            ...resp.data.Result.User.map(({ Id, Name }) => ({ value: Id, label: Name })),
          ];
          setUserLocationList({ locationList, usersList });
        } else {
          setCommonError({ ...commonError, userLocationListApiError: resp.data.Message });
        }
      })
      .catch((err) => {
        setCommonError({ ...commonError, userLocationListApiError: err.response.data.Message });
      })
      .finally(() => setAppPageLoader(false));
  };

  useEffect(() => {
    getDoctorLocationList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AppNavbar />
      {appPageLoader && <AppPageLoader className={"position-fixed"} />}
      <div className="main-container">
        <Container fluid>
          {/* <h2>Patients</h2> */}
          <Card className="transparant border-0">
            <CardBody className="p-0">
              <div
                className={`screen-by d-flex ${
                  userLocationList.locationList.length > 2
                    ? "justify-content-between"
                    : "justify-content-end"
                }`}
              >
                {userLocationList.locationList.length > 2 && (
                  <div className="location-content">
                    <label>Location</label>
                    <Select
                      className="style-select react-select dayfilterSelect mr-2"
                      classNamePrefix="select"
                      name={"LocationId"}
                      value={patientPageState.LocationId ?? userLocationList.locationList[0]}
                      defaultValue={userLocationList.locationList[0]}
                      options={userLocationList.locationList}
                      onChange={(e) => handleChange(e, "LocationId")}
                      isClearable={false}
                    />
                  </div>
                )}
                <div className="main-search">
                  <FormGroup>
                    <img src={Search} alt="serach" onClick={() => handleSubmitSearch()} />
                    <Input
                      type="text"
                      name="SearchBy"
                      id="SearchBy"
                      placeholder="Search Patients"
                      value={searchInput}
                      onChange={handleSearchInputChange}
                    />
                  </FormGroup>
                  <Button
                    className="ml-0 ml-lg-3"
                    color="primary"
                    outline
                    onClick={redirectToAddpatient}
                  >
                    + Add Patient
                  </Button>
                </div>
              </div>
              <div className="screen-by d-flex justify-content-between">
                <div className="appoinment">
                  <label>Appointment Date</label>
                  <div>
                    <DatePicker
                      className="datepicker"
                      selected={customeDate.AppointmentDate.from}
                      name={"AppointmentFromDate"}
                      onChange={(date) => handleCustomeDateChange(date, "AppointmentFromDate")}
                      placeholderText="mm/dd/yyy"
                    />
                    To
                    <DatePicker
                      className="datepicker"
                      selected={customeDate.AppointmentDate.to}
                      name={"AppointmentToDate"}
                      onChange={(date) => handleCustomeDateChange(date, "AppointmentToDate")}
                      placeholderText="mm/dd/yyy"
                    />
                  </div>
                  <div className="text-danger">
                    {customeDateError.AppointmentDate.from || customeDateError.AppointmentDate.to}
                  </div>
                  <Button
                    className=" ml-0 ml-md-3 px-5"
                    color="primary"
                    outline
                    onClick={() => handleCustomeDateSubmit("Appointment")}
                    disabled={
                      customeDate.AppointmentDate.from === null &&
                      customeDate.AppointmentDate.to === null
                    }
                    title={
                      customeDate.AppointmentDate.from === null &&
                      customeDate.AppointmentDate.to === null
                        ? "Search is disabled. Please select date filters to enable."
                        : ""
                    }
                  >
                    Search
                  </Button>
                  <Button
                    className=" ml-3 px-5"
                    onClick={() => handleCustomeDateReset()}
                    color="primary"
                    outline
                  >
                    Reset
                  </Button>
                </div>
                {/* <div className="appoinment">
                  <label>Screened Date</label>
                  <div>
                    <DatePicker
                      className="datepicker"
                      selected={customeDate.ScreenedDate.from}
                      name={"ScreenedFromDate"}
                      onChange={(date) => handleCustomeDateChange(date, "ScreenedFromDate")}
                      dateFormat="MM/dd/yyyy"
                      placeholderText="mm/dd/yyy"
                    />
                    To
                    <DatePicker
                      className="datepicker"
                      selected={customeDate.ScreenedDate.to}
                      name={"ScreenedToDate"}
                      onChange={(date) => handleCustomeDateChange(date, "ScreenedToDate")}
                      dateFormat="MM/dd/yyyy"
                      placeholderText="mm/dd/yyy"
                    />
                  </div>
                  <div className="text-danger">
                    {customeDateError.ScreenedDate.from || customeDateError.ScreenedDate.to}
                  </div>
                  <Button
                    className=" ml-0 ml-md-3 px-5"
                    color="primary"
                    outline
                    onClick={() => handleCustomeDateSubmit("Screening")}
                    disabled={
                      customeDate.ScreenedDate.from === null && customeDate.ScreenedDate.to === null
                    }
                    title={
                      customeDate.ScreenedDate.from === null && customeDate.ScreenedDate.to === null
                        ? "Search is disabled. Please select date filters to enable."
                        : ""
                    }
                  >
                    Search
                  </Button>
                  <Button
                    className=" ml-3 px-5"
                    color="primary"
                    outline
                    onClick={() => handleCustomeDateReset()}
                  >
                    Reset
                  </Button>
                </div> */}
              </div>
            </CardBody>
          </Card>

          <PatientList
            columns={columns}
            showRecords={true}
            patientListPayloadFromParent={patientListPayload}
            setPageInfo={changePageInfo}
          />
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Patients;
