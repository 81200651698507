import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import UserImg from "../assets/images/user-img1.png";
import AppPageLoader from "../CommonComponents/PageLoader/AppPageLoader";

const PatientInfoSection = ({ selectedPatient }) => {
  return (
    <Card className="border-0 mb-3 patients-info-card">
      <CardBody>
        <h3>Patient Information</h3>

        <div className="d-flex info-box">
          {selectedPatient === null && <AppPageLoader />}
          <div className="user-img">
            <img src={UserImg} alt="patient-profile" className="img-fluid logo-white" />
          </div>
          <div className="info-block">
            <Row>
              <Col lg="3">
                <div className="details ">
                  <label>First Name :</label>
                  <span>
                    {selectedPatient && selectedPatient.FirstName.length > 0
                      ? selectedPatient.FirstName
                      : "-"}
                  </span>
                </div>
              </Col>
              <Col lg="3">
                <div className="details">
                  <label>Last Name :</label>
                  <span>
                    {selectedPatient && selectedPatient.LastName.length > 0
                      ? selectedPatient.LastName
                      : "-"}
                  </span>
                </div>
              </Col>
              <Col lg="2">
                <div className="details">
                  <label>Gender :</label>
                  <span>
                    {selectedPatient && selectedPatient.Gender.length > 0
                      ? selectedPatient.Gender
                      : "-"}
                  </span>
                </div>
              </Col>
              <Col lg="4">
                <div className="details">
                  <label>Date Of Birth :</label>
                  <span>
                    {selectedPatient && selectedPatient.DOB.length > 0 ? selectedPatient.DOB : "-"}
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <div className="details ">
                  <label>Phone :</label>
                  <span>
                    {selectedPatient && selectedPatient.Phone.length > 0 ? (
                      <a href={`tel: ${selectedPatient.Phone}`}>{selectedPatient.Phone}</a>
                    ) : (
                      "-"
                    )}
                  </span>
                </div>
              </Col>
              <Col lg="3">
                <div className="details ">
                  <label>Email :</label>
                  <span>
                    {selectedPatient && selectedPatient.Email.length > 0 ? (
                      <a href={`mailto: ${selectedPatient.Email}`}>{selectedPatient.Email}</a>
                    ) : (
                      "-"
                    )}
                  </span>
                </div>
              </Col>
              <Col lg="4">
                <div className="details">
                  <label>Patient ID :</label>
                  <span>
                    {selectedPatient && selectedPatient.PatientId > 0
                      ? selectedPatient.PatientId
                      : "-"}
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="3">
                <div className="details ">
                  <label>Address :</label>
                  <span>
                    {selectedPatient && selectedPatient.Address.length > 0
                      ? selectedPatient.Address
                      : "-"}
                  </span>
                </div>
              </Col>
              <Col lg="3">
                <div className="details ">
                  <label>State :</label>
                  <span>
                    {selectedPatient && selectedPatient.State.length > 0
                      ? selectedPatient.State
                      : "-"}
                  </span>
                </div>
              </Col>
              <Col lg="2">
                <div className="details ">
                  <label>City :</label>
                  <span>
                    {selectedPatient && selectedPatient.City.length > 0
                      ? selectedPatient.City
                      : "-"}
                  </span>
                </div>
              </Col>
              <Col lg="4">
                <div className="details">
                  <label>Zip :</label>
                  <span>
                    {selectedPatient && selectedPatient.ZipCode.length > 0
                      ? selectedPatient.ZipCode
                      : "-"}
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default PatientInfoSection;
