import { useEffect, useState } from "react";
import CommonApi from "../../API/CommonApiCalls";

const useLocationUserList = () => {
  const [locationUserList, setLocationUserList] = useState({
    locationList: [{ value: 0, label: "Select Location" }],
    usersList: [{ value: 0, label: "Select Users" }],
  });
  const [locationUserListError, setLocationUserListError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        const response = await CommonApi.getLocationDoctorList();

        setLocationUserList({
          usersList: [
            { value: 0, label: "Select Users" },
            ...response.data.Result.User.map(({ Id, Name, LocationId }) => ({
              value: Id,
              label: Name,
              locationId: LocationId,
            })),
          ],
          locationList: [
            { value: 0, label: "Select Location" },
            ...response.data.Result.Location.map(({ Id, Name }) => ({ value: Id, label: Name })),
          ],
        });
      } catch (err) {
        setLocationUserListError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { locationUserList, locationUserListError, loading };
};

export default useLocationUserList;
