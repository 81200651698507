import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import "../assets/css/style.css";
import "../assets/css/responsive.css";
import AppNavbar from "../CommonComponents/Navbars/AppNavbar";
import { Card, CardBody, Container } from "reactstrap";
import Footer from "../CommonComponents/Footers/Footer";
import CommonApi from "../API/CommonApiCalls";
import AppPageLoader from "../CommonComponents/PageLoader/AppPageLoader";
import ScreeningHistory from "../CommonComponents/ScreeningHistory/ScreeningHistoryTable";
import { HPVQASection } from "../CommonComponents/HPVQA/HPVQA";
import AddNewScreening from "../CommonComponents/AddNewScreening";
import { scroller } from "react-scroll";
import PatientInfoSection from "./PatientInfoSection";
import Api from "../API/patientInfo";

const PatientsInfo = () => {
  let history = useHistory();

  const columns = [
    {
      id: 5,
      name: "Location",
      selector: (row) => row.LocationName,
      sortable: true,
    },

    { id: 7, name: "Screened By", selector: (row) => row.ScreenedBy, sortable: true },
    { id: 8, name: "Screening Date", selector: (row) => row.ScreenedDate, sortable: true },

    {
      name: "Action",
      selector: (row) => row.action,
      cell: (row) => (
        <Button
          color="primary"
          style={{ borderRadius: "12px" }}
          outline
          onClick={(e) => handleOnShowQAClick(row, e)}
        >
          Show Q&A
        </Button>
      ),
      sortable: false,
    },
  ];

  // ================ states ===================
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [appPageLoader, setAppPageLoader] = useState(false);
  const [commonError, setCommonError] = useState({
    patientListApiError: "",
    screeningHistoryApiError: "",
  });
  const [submitPatientInfoData, setSubmitPatientInfoData] = useState([]);
  const [showAddNewCard, setShowAddNewCard] = useState(false);
  const [showNoshowHPVQA, setShowNoshowHPVQA] = useState(false);

  const defaultData = {
    LocationId: "",
    PageIndex: 1,
    PageSize: 10,
    SearchBy: "",
    SortBy: 1,
    SortDirection: "ASC",
  };
  const patientListPayLoadData = history?.location?.state?.patientListPayLoad ?? defaultData;
  const patientId = history?.location?.state?.patientId ?? 0;
  const [screeningHistoryPayLoad, setScreeningHistoryPayLoad] = useState({
    PatientId: patientId,
    PageIndex: 1,
    SortBy: 8,
    SortDirection: "DESC",
  });
  const [totalRecords, setTotalRecords] = useState(5);

  const [screeningHistoryData, setScreeningHistoryData] = useState([]);

  //  =================== handlechange functions =========
  const handleAddNewScreenSubmit = () => {
    setShowAddNewCard(false);
    return getScreeningHistoryApi(screeningHistoryPayLoad);
  };
  const handleAddNewScreenCancel = () => {
    setShowAddNewCard(false);
    scroller.scrollTo("gotoAddNewScreeningSection");
  };
  const handleScreeningHistoryCancel = () => {
    setShowNoshowHPVQA(false);
    scroller.scrollTo("gotoAddNewScreeningSection");
  };
  const handleOnShowQAClick = (row, e) => {
    setShowNoshowHPVQA(true);
    const newQAResponse = {
      ...row,
      QuestionAnswers: row.QuestionAnswer.map(
        ({ QuestionId, OrderBy, Question, QuestionType, Answer }) => ({
          id: QuestionId,
          q: Question,
          type: QuestionType,
          OrderBy,
          a: Answer,
          error: "",
        })
      ),
    };
    setSubmitPatientInfoData(newQAResponse);
    scroller.scrollTo("gotoHPVQASection", {
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  const handleAddNewScreening = () => {
    setShowAddNewCard(true);
    scroller.scrollTo("gotoAddNewScreeningSection", {
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart",
    });
    setShowNoshowHPVQA(false);
  };
  const handlePageChange = (data) => {
    const payLoadData = {
      ...screeningHistoryPayLoad,
      PageIndex: data,
    };
    setScreeningHistoryPayLoad(payLoadData);
    getScreeningHistoryApi(payLoadData);
  };
  const handleOnSort = (row, direction) => {
    const newScreeningHistoryPayLoad = {
      ...screeningHistoryPayLoad,
      PatientId: patientId,
      PageIndex: 1,
      SortBy: row.id,
      SortDirection: direction.toUpperCase(),
    };
    setScreeningHistoryData(newScreeningHistoryPayLoad);
    getScreeningHistoryApi(newScreeningHistoryPayLoad);
  };
  //  =================== getApi calls ===================
  const getPatientList = (payLoad) => {
    setAppPageLoader(true);
    CommonApi.getPatientList(payLoad)
      .then((resp) => {
        const selectedpatient = resp.data.Result.Patients.find((val) => {
          return val.PatientId === patientId;
        });
        setSelectedPatient(selectedpatient);
        setCommonError({ ...commonError, patientListApiError: "" });
        setAppPageLoader(false);
      })
      .catch((err) => {
        setCommonError({ ...commonError, patientListApiError: "Something went wrong" });
        setAppPageLoader(false);
      })
      .finally(() => setAppPageLoader(false));
  };

  const getScreeningHistoryApi = (apiPayLoad) => {
    setAppPageLoader(true);
    Api.getScreeningHistory(apiPayLoad)
      .then((resp) => {
        setScreeningHistoryData(resp.data.Result.ScreeningDetails);
        setTotalRecords(resp.data.Result.TotalRecord);
        setCommonError({ ...commonError, screeningHistoryApiError: "" });
        setAppPageLoader(false);
      })
      .catch((err) => {
        setCommonError({
          ...commonError,
          screeningHistoryApiError: err?.response?.data?.Message ?? "Something went wrong",
        });
        setAppPageLoader(false);
      })
      .finally(() => setAppPageLoader(false));
  };

  useEffect(() => {
    getPatientList(patientListPayLoadData);
    getScreeningHistoryApi(screeningHistoryPayLoad);
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AppNavbar />
      {appPageLoader && <AppPageLoader className="position-fixed" />}
      <div className="main-container">
        <Container fluid>
          <div className="screening-main-block ques-ans-block">
            <h2>HPV Questions & Answers</h2>
            <PatientInfoSection selectedPatient={selectedPatient} />

            <div className="gotoAddNewScreeningSection"></div>
            {showAddNewCard && selectedPatient && (
              <AddNewScreening
                PatientId={patientId}
                LocationBy={selectedPatient.LocationId}
                setShowCard={setShowAddNewCard}
                handleOnCancel={handleAddNewScreenCancel}
                handleOnSubmit={handleAddNewScreenSubmit}
              />
            )}

            <div className="text-danger">{commonError && commonError.screeningHistoryApiError}</div>
            <Card className="border-0 mb-3 patients-info-card">
              <CardBody>
                <div className="d-flex justify-content-between align-items-center my-0 select-patients">
                  <div className="screen-history my-0 py-0">
                    <h2 className="mt-0 pt-0">Screening History</h2>
                  </div>
                  <Button
                    className="fw400 title2 add-scr"
                    onClick={handleAddNewScreening}
                    style={{ textTransform: "none" }}
                  >
                    <b>+ Add New Screening</b>
                  </Button>
                </div>

                <ScreeningHistory
                  columns={columns}
                  data={screeningHistoryData}
                  paginationTotalRows={totalRecords}
                  onChangePage={handlePageChange}
                  handleOnSort={handleOnSort}
                  isLoading={appPageLoader}
                  totalRecords={totalRecords}
                />

                <div className="gotoHPVQASection"></div>
                {showNoshowHPVQA && (
                  <div className="hpv-questions-block">
                    <h3>HPV Questions</h3>
                    <div className="my-2 d-flex justify-content-between">
                      <h5>Location Name: {submitPatientInfoData.LocationName}</h5>
                      <h5>Screened by: {submitPatientInfoData.ScreenedBy}</h5>
                      <h5>Screening Date: {submitPatientInfoData.ScreenedDate}</h5>
                    </div>
                    <HPVQASection QAList={submitPatientInfoData.QuestionAnswers} disabled={true} />
                    <div className="actions-btns d-flex mt-1">
                      <Button
                        color="secondary"
                        className="cancel-btn"
                        onClick={handleScreeningHistoryCancel}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default PatientsInfo;
