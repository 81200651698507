// ** Third Party Components
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import Api from "../../API/reports";
import AppPageLoader from "../PageLoader/AppPageLoader";

const ApexAreaCharts = ({ direction, payLoad, pageType }) => {
  // ** Chart Options

  const [chartData, setChartData] = useState([]);
  const [commonError, setCommonError] = useState("");
  const [appPageLoader, setAppPageLoader] = useState(false);
  const [areaColor, setAreaColor] = useState({
    series4: "#7687d4",
    series3: "#0FAB55",
    series2: "#0088EE",
    series1: "#FFB547",
  });

  const options = {
    chart: {
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      curve: "smooth",
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    colors: [areaColor.series4, areaColor.series3, areaColor.series2, areaColor.series1],

    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0,
        opacityTo: 0.4,
      },
    },
    tooltip: {
      shared: false,
    },
    yaxis: {
      opposite: direction === "rtl",
    },
    xaxis: {
      labels: {
        datetimeFormatter: {
          month: "MMM 'yy",
          day: "dd MMM",
          year: "yyyy",
          hour: "HH:mm",
        },
      },
    },
  };

  const getReportSummaryChart = (payLoad) => {
    const newPayLoad = {
      AccountId: payLoad.AccountId,
      ChartType: payLoad.ChartType,
      EndDate: payLoad.EndDate,
      StartDate: payLoad.StartDate,
      LocationId: payLoad.LocationId,
    };
    setAppPageLoader(true);
    Api.GetReportSummaryChart(newPayLoad)
      .then((resp) => {
        const newAreaColor = resp.data.Result.map((val, key) => ({ [`series${key}`]: val.color }));
        setAreaColor({ ...newAreaColor });
        setChartData(resp.data.Result);
        setCommonError("");
      })
      .catch((err) => {
        setCommonError(err?.response?.data?.Message);
      })
      .finally(() => setAppPageLoader(false));
  };

  const getStateDashboardSummaryChart = (payLoad) => {
    const newPayLoad = {
      AccountId: payLoad.AccountListId.value,
      StateId: payLoad.StateListId.value === 0 ? null : payLoad.StateListId.value,
      ChartType: payLoad.ChartType,
      EndDate: payLoad.EndDate,
      StartDate: payLoad.StartDate,
    };
    setAppPageLoader(true);
    Api.GetReportSummaryChart(newPayLoad)
      .then((resp) => {
        const newAreaColor = resp.data.Result.map((val, key) => ({ [`series${key}`]: val.color }));
        setAreaColor({ ...newAreaColor });
        setChartData(resp.data.Result);
        setCommonError("");
      })
      .catch((err) => {
        setCommonError(err?.response?.data?.Message);
      })
      .finally(() => setAppPageLoader(false));
  };

  useEffect(() => {
    if (pageType === "reports") getReportSummaryChart(payLoad);
    if (pageType === "stateDashboard") getStateDashboardSummaryChart(payLoad);
  }, [payLoad, pageType]);

  return (
    <>
      {appPageLoader && <AppPageLoader />}
      <div className="text-center text-danger">{commonError}</div>
      <Chart
        className="dashboard-chart"
        options={options}
        series={chartData}
        type="area"
        height={750}
      />
    </>
  );
};
export default ApexAreaCharts;
