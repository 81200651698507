import React from "react";
import { Link } from "react-router-dom";
import LRFSideBanner from "../../CommonComponents/Cards/LRFSideBanner";

const LRFLayout = ({
  titleText,
  headerLine,
  children,
  sideBannerText,
  sideBannerRoute,
  sideBannerPositionCode,
}) => {
  return (
    <section className="common-section vh-0 ">
      <div className="sign-up-block">
        <div className="main-block signin-block">
          <div className="login sub-block">
            <div className="row justify-content-center">
              {sideBannerPositionCode === 1 && (
                <LRFSideBanner
                  className={"bg-green-1"}
                  onClick={sideBannerRoute}
                  btnText={sideBannerText}
                />
              )}
              <div className="col-lg-7 pr-lg-0">
                <div className="d-block">
                  <div className="inner-block text-center">
                    <div className="block-1">
                      <h1>
                        {titleText} to{" "}
                        <Link to={"/"} title={"Go to home page"}>
                          <span>
                            <b>HPV</b>
                          </span>
                        </Link>
                      </h1>
                      <p>{`${
                        headerLine ? headerLine : "Welcome back. Enter your credentials to login. "
                      }`}</p>
                    </div>
                    {children}
                  </div>
                </div>
              </div>
              {sideBannerPositionCode === 0 && (
                <LRFSideBanner onClick={sideBannerRoute} btnText={sideBannerText} />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LRFLayout;
