import axios from "axios";
import { API_URL } from "../config/appConstatnts";

class Api {
  // API for screening history data
  static getScreeningHistory = (payLoad) => {
    const HEADER = {
      headers: {
        access_token: localStorage.getItem("token"),
        "Content-Type": "application/json",
        Company_Id: localStorage.getItem("CompanyId"),
      },
    };
    return axios.post(API_URL + "/HPV/GetScreeningHistory", payLoad, HEADER);
  };
}
export default Api;
