import React, { useEffect, useState } from "react";
import { scroller } from "react-scroll";

import "../assets/css/style.css";
import "../assets/css/responsive.css";
import { toast } from "react-toastify";
import AppNavbar from "../CommonComponents/Navbars/AppNavbar";
// import DatePicker from "react-datepicker";
import Search from "../assets/images/search.svg";
import { Card, CardBody, Container, FormGroup, Input, Button } from "reactstrap";
import Footer from "../CommonComponents/Footers/Footer";
import LabelSelect from "./SectionCommonComponents/LabelSelect";
import { HPVQASection } from "../CommonComponents/HPVQA/HPVQA";
import { redirectToAddpatient } from "../config/CommonFunctions";
import Api from "../API/screening";
import AppPageLoader from "../CommonComponents/PageLoader/AppPageLoader";
import PatientList from "../CommonComponents/PatientList";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import useLocationUserList from "../CommonComponents/_helper-functions/LocationUserList";
import useHPVQAList from "../CommonComponents/_helper-functions/HPVQAList";
import swal from 'sweetalert';
import EditScreening from "./SectionCommonComponents/EditScreeningModal";

const Screening = React.memo(() => {
  const history = useHistory();
  const selectedPatientInfo = history.location.state && history.location.state.patientData;
  // =======for columns of data table====================
  const columns = [
    {
      name: "Action",
      selector: (row) => row.action,
      cell: (row) => (
        <Button
          color="primary"
          style={{ borderRadius: "12px" }}
          outline
          onClick={(e) => handleOnClickRow(row, e)}
        >
          Screen
        </Button>
      ),
      sortable: false,
    },
    {
      id: 10,
      name: "Patient ID",
      selector: (row) => row.patientId,
      sortable: false,
    },
    {
      id: 1,
      name: "First Name",
      selector: (row) => row.firstName,
      sortable: true,
    },
    {
      id: 2,
      name: "Last Name",
      selector: (row) => row.lastName,
      sortable: true,
    },
    {
      id: 3,
      name: "DOB",
      selector: (row) => row.dob,
      sortable: true,
    },
    {
      id: 4,
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
    },

    {
      id: 6,
      name: "Screened",
      selector: (row) => row.screened,
      sortable: true,
    },
    {
      id: 8,
      name: "Last Screening Date",
      selector: (row) => row.lastScreenDate,
      cell: (row) => (
        row.screeningId ? <> <label className="cursor-pointer">{row.lastScreenDate}</label> <i className="fa fa-pencil pl-2 pb-3 fa-lg cursor-pointer edit-screening" onClick={(e) => handleEditScreening(row, e)}></i> </> : 
        <><label className="pl-4">-</label></>        
      ),
      sortable: true,
    },
    {
      name: "Delete",
      selector: (row) => row.delete,
      cell: (row) => (
        row.screeningId ? <i className="fa fa-trash fa-lg pb-3 pl-4 cursor-pointer danger delete-screening" onClick={(e) => handleDeleteRow(row, e)}></i>  :<></>        
      ),
      sortable: false,
    },
  ];

  // =====================states ====================================

  const { locationUserList, loading } = useLocationUserList();
  const { HPVQAList } = useHPVQAList();

  const resetScreeningData = {
    screenBy: { value: 0, label: "Select Provider", locationId: 0 },
    screenByError: "",
    searchLocationBy: { value: 0, label: "Select Location" },
    QAResponse: null,
    patientId: !!selectedPatientInfo ? selectedPatientInfo?.patientId : 0,
    locationBy: 0,
  };
  const [submitScreeningData, setSubmitScreeningData] = useState(resetScreeningData);
  const [showSelectedPatient, setShowSelectedPatient] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [appPageLoader, setAppPageLoader] = useState(false);
  const [patientListPayload, setPatientListPayload] = useState(null);

  const [searchInput, setSearchInput] = useState("");
  const [EditScreeningModalShow, SetEditScreeningModalShow] = useState(false);
  const [EditScreeningData, SetEditScreeningData] = useState(null);
  const [selectedScreened, SetSelectedScreened] = useState(0);

  //  ===========Reset data================================
  const resetQAList = submitScreeningData?.QAResponse?.map((val) => ({ ...val, a: "", error: "" }));

  // =====================user interactions, events===================
  const handleClick = (val, id) => {
    const newQAResp = [...submitScreeningData.QAResponse];
    newQAResp[id].a = val;
    newQAResp[id].error = "";
    setSubmitScreeningData({ ...submitScreeningData, QAResponse: newQAResp });
  };

  const handleChange = (event, name) => {
    setSubmitScreeningData({
      ...submitScreeningData,
      [name]: event,
      [`${name}Error`]: "",
      IsTodaysAppt: false,
    });
    if (name === "searchLocationBy") {
      const newPayLoad = { ...patientListPayload, LocationId: event.value, IsTodaysAppt: false };
      setPatientListPayload(newPayLoad);
    }
  };

  const handleCancel = () => {
    if (!!selectedPatientInfo) history.location.state = null;
    setPatientListPayload({ ...patientListPayload, Reload: true });
    setShowSelectedPatient(false);
    setSubmitScreeningData({
      ...submitScreeningData,
      patientId: 0,
      locationBy: 0,
      screenBy: { value: 0, label: "Select Provider", locationId: 0 },
      screenByError: "",
      QAResponse: resetQAList,
    });
    scroller.scrollTo("main-container");
  };

  const handleOnClickRow = (data) => {
    setShowSelectedPatient(true);
    setSelectedPatient(data);
    setSubmitScreeningData({
      ...submitScreeningData,
      patientId: data.patientId,
      locationBy: data.locationId,
    });
    scroller.scrollTo("clear-fix", {
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const handleDeleteRow =(data) =>{
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this patient screening",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        Api.DeleteScreening(data.screeningId)
        .then((resp) => {
          setSubmitScreeningData({ ...resetScreeningData, QAResponse: resetQAList });
          setPatientListPayload({ ...patientListPayload, Reset: true });
          setShowSelectedPatient(false);
          toast.success(resp.data.Message);
          scroller.scrollTo("main-container");
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => setAppPageLoader(false));
      }
    });
  }

  const handleEditScreening = (data) => {
    SetSelectedScreened(data.screeningId);
    SetEditScreeningModalShow(true);
    data.formatLastScreenDate = GetFormattedDate(data.lastScreenDate);
    SetEditScreeningData(data);
  }

  const GetFormattedDate = (date) =>{
    return new Date(date).getFullYear() + "-" + ((new Date(date).getMonth() + 1) > 9 ? (new Date(date).getMonth() + 1) : "0" + (new Date(date).getMonth() + 1)) + "-" + (new Date(date).getDate() > 9 ? new Date(date).getDate() : "0" + new Date(date).getDate());
  }

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };
  const handleSubmitSearch = () => {
    const payLoad = { ...patientListPayload, SearchBy: searchInput, IsTodaysAppt: false };
    setPatientListPayload(payLoad);
  };

  // ========on submit of screening data=================
  const handleSubmit = () => {
    let isError = false;
    const newSubmitScreeningData = submitScreeningData;
    if (newSubmitScreeningData.screenBy.value === 0) {
      isError = true;
      newSubmitScreeningData.screenByError = "Please select Doctor";
    } else {
      newSubmitScreeningData.screenByError = "";
    }
    if (
      newSubmitScreeningData.QAResponse.map(({ id, a }) => ({
        QuestionId: id,
        Answer: a,
      })).every((val) => val.Answer.length > 0) === false
    ) {
      let data = newSubmitScreeningData.QAResponse;
      // eslint-disable-next-line array-callback-return
      data.filter((val) => {
        if (val.type === 1) {
          if (val.a === "") val.error = "Please select an option";
          else val.error = "";
        }
        if (val.type === 0) {
          if (val.a.length === 0) val.error = "Please enter a value";
          else val.error = "";
        }
      });
      isError = true;
      newSubmitScreeningData.QAResponse = data;
    } else {
      let data = newSubmitScreeningData.QAResponse;
      data.filter((val) => {
        val.error = "";
        return val;
      });
      newSubmitScreeningData.QAResponse = data;
    }
    setSubmitScreeningData({ ...newSubmitScreeningData });

    if (isError === false) {
      setAppPageLoader(true);
      const payLoad = {
        PatientId: submitScreeningData.patientId,
        DoctorId: submitScreeningData.screenBy.value,
        LocationId: submitScreeningData.locationBy,
        AccountId:
          localStorage.getItem("userData") &&
          JSON.parse(localStorage.getItem("userData")).AccountId,
        Screening: submitScreeningData.QAResponse.map(({ id, a }) => ({
          QuestionId: id,
          Answer: a,
        })),
      };
      Api.AddScreening(payLoad)
        .then((resp) => {
          setSubmitScreeningData({ ...resetScreeningData, QAResponse: resetQAList });
          setPatientListPayload({ ...patientListPayload, Reset: true });
          setShowSelectedPatient(false);
          toast.success(resp.data.Message);
          scroller.scrollTo("main-container");
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => setAppPageLoader(false));
    }
  };

  const handleChangeScreening = (e) => {
    swal({
      title: "Are you sure?",
      text: "want to update the screening date !!!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        Api.UpdateScreening(selectedScreened, e)
        .then((resp) => {
          setSubmitScreeningData({ ...resetScreeningData, QAResponse: resetQAList });
          setPatientListPayload({ ...patientListPayload, Reset: true });
          setShowSelectedPatient(false);
          SetEditScreeningModalShow(false);
          toast.success(resp.data.Message);
          scroller.scrollTo("main-container");
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        })
        .finally(() => setAppPageLoader(false));
      }
    });

  }

  // ==============Get APi calls============================

  useEffect(() => {
    if (!!selectedPatientInfo) {
      setPatientListPayload({
        SearchBy: "",
        LocationId: 0,
        Reset: false,
        IsTodaysAppt: false,
        PageIndex: 1,
        PageSize: 10,
        SortBy: 10,
        SortDirection: "DESC",
        ChartType: null,
      });
      handleOnClickRow(selectedPatientInfo);
    } else {
      setPatientListPayload({
        SearchBy: "",
        LocationId: 0,
        Reset: false,
        IsTodaysAppt: true,
        PageIndex: 1,
        PageSize: 10,
        SortBy: 10,
        SortDirection: "DESC",
        ChartType: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!!HPVQAList) {
      setSubmitScreeningData({ ...submitScreeningData, QAResponse: HPVQAList });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HPVQAList]);

  return (
    <div>
      {(appPageLoader || loading) && <AppPageLoader className={"position-fixed"} />}
      <AppNavbar />
      <div className="main-container">
        <Container fluid>
          <div className="screening-main-block">
            <Card className="border-0 mb-3">
              <CardBody>
                <div className="mb-4">
                  <div className={`screen-by d-flex justify-content-between`}>
                    <>
                      <div className="d-flex m-0 justify-content-between flex-wrap select-patients screen-by">
                        <label>
                          Select Patient to Screen <span>*</span>
                        </label>
                      </div>
                    </>
                    {locationUserList.locationList.length > 2 && (
                      <div className="location-content">
                        <label>Location</label>
                        <Select
                          className="style-select react-select dayfilterSelect mr-2"
                          classNamePrefix="select"
                          defaultValue={locationUserList.locationList[0]}
                          value={submitScreeningData.searchLocationBy}
                          name={"searchLocationBy"}
                          onChange={(event) => handleChange(event, "searchLocationBy")}
                          options={locationUserList.locationList}
                          isClearable={false}
                        />
                      </div>
                    )}
                    <div className="main-search">
                      <FormGroup>
                        <img src={Search} alt="serach" onClick={() => handleSubmitSearch()} />
                        <Input
                          type="text"
                          name="search"
                          id="SearchBy"
                          value={searchInput}
                          onChange={handleSearchInputChange}
                          placeholder="Search Patients"
                        />
                      </FormGroup>
                    </div>
                    <Button outline color="primary" onClick={redirectToAddpatient}>
                      + Add Patient
                    </Button>
                  </div>
                </div>

                {/* ========================Patient Table section==================== */}
                {patientListPayload && (
                  <div className="screening">
                    <PatientList
                      columns={columns}
                      showRecords={false}
                      patientListPayloadFromParent={patientListPayload}
                      setPatientListPayloadFromParent={setPatientListPayload}
                      showLabel={true}
                    />
                  </div>
                )}
                <>
                  <EditScreening show={EditScreeningModalShow} onHide={() => SetEditScreeningModalShow(false)}
                    EditScreeningData = {EditScreeningData} handleChangeScreening = {(e) => handleChangeScreening(e)}
                  />
                </>

                {/* =======================HPV Question Answer Section================= */}
                <div className="clear-fix my-0"></div>
                {showSelectedPatient && (
                  <div className="hpv-questions-block mt-5">
                    <h3
                      className="mb-0"
                      style={{ textTransform: "none" }}
                    >{`HPV Questions for : ${selectedPatient.firstName} ${selectedPatient.lastName}`}</h3>
                    <LabelSelect
                      parentClassName={"mb-5 position-relative"}
                      label={["Screen by ", <span key={1}>*</span>]}
                      className={"style-select react-select dayfilterSelect mr-2"}
                      value={submitScreeningData.screenBy ?? locationUserList.usersList[0]}
                      name={"screenBy"}
                      onChange={(event) => handleChange(event, "screenBy")}
                      options={locationUserList.usersList}
                      error={submitScreeningData.screenByError}
                    />

                    {submitScreeningData.QAResponse && (
                      <HPVQASection
                        handleClick={handleClick}
                        QAList={submitScreeningData.QAResponse}
                      />
                    )}
                    <div className="actions-btns d-flex mt-1">
                      <Button
                        color="primary"
                        className="fw400 title2 save-btn"
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                      <Button color="secondary" className="cancel-btn" onClick={handleCancel}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
});

export default Screening;
