import React from "react";
import { goToSignin } from "../../config/CommonFunctions";

const ThankYouPage = () => {
  return (
    <>
      <section className="common-section vh-0 ">
        <div className="sign-up-block">
          <div className="main-block signin-block">
            <div className="login sub-block" style={{ width: "50vw", height: "50vh" }}>
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="d-block">
                    <div className="inner-block text-center">
                      <div className="block-1">
                        <h1 className="mb-3">THANK YOU</h1>
                        <p
                          className="font-weight-bold"
                          style={{ color: "#000000", opacity: "0.8" }}
                        >
                          Please check your email for a confirmation email to login to your account.
                        </p>
                        <button className="btn blue-btn-bg " onClick={() => goToSignin()}>
                          Go to Sign in
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ThankYouPage;
