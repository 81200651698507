import React, { useState } from "react";
import { ReactComponent as MailIcon } from "../assets/images/envelope.svg";
import BlockButton from "./SectionCommonComponent/BlockButton";
import LRFLayout from "./SectionCommonComponent/LRFLayout";
import { goToSignin } from "../config/CommonFunctions";
import Input from "../CommonComponents/Input/Input";
import Api from "../API/login";
import { validEmailRegex } from "../config/appRegex";
import { toast } from "react-toastify";
import AppPageLoader from "../CommonComponents/PageLoader/AppPageLoader";

const ForgotPasswordPage = () => {
  const [userModel, setUserModel] = useState({ email: "" });
  const [userModelErrors, setUserModelErrors] = useState({ email: "" });
  const [appPageLoader, setAppPageLoader] = useState(false);
  const handleChange = (event) => {
    setUserModel({ [event.target.name]: event.target.value });
  };
  const handleSubmit = () => {
    let isError = false;
    if (userModel.email.length === 0) {
      setUserModelErrors({ email: "Email is required" });
      isError = true;
      return;
    }
    if (userModel.email.trim().length > 0 && !validEmailRegex.test(userModel.email)) {
      setUserModelErrors({ email: "Enter valid Email." });
      isError = true;
      return;
    }

    if (isError === false) {
      setAppPageLoader(true);
      Api.forgotPassword(userModel.email)
        .then((resp) => {
          toast.success(resp.data.Message);
          setUserModelErrors({ email: "" });
          goToSignin();
        })
        .catch((err) => {
          setUserModelErrors({ email: err.response.data.Message });
          toast.error(err.response.data.Message);
        })
        .finally(() => setAppPageLoader(false));
    }
  };
  return (
    <LRFLayout
      titleText={"Sign in"}
      sideBannerText={"sign in"}
      sideBannerRoute={goToSignin}
      sideBannerPositionCode={0}
      headerLine={"Please enter your email and we will send you email to reset your password."}
    >
      <form>
        {appPageLoader && <AppPageLoader className={"position-fixed"} />}
        <div className="content">
          <Input
            name="email"
            type={"email"}
            className={"form-control light-border"}
            id={"email"}
            maxLength="100"
            value={userModel.Username}
            onChange={handleChange}
            placeholder={"Email Address *"}
            error={userModelErrors.email}
            iconBefore={<MailIcon />}
          />
        </div>
        <BlockButton text={"Request Reset Password link"} onClick={handleSubmit} />
      </form>
    </LRFLayout>
  );
};
export default ForgotPasswordPage;
