import React, { useEffect, useState } from "react";
import AppNavbar from "./CommonComponents/Navbars/AppNavbar";
import Select2 from "react-select2-wrapper";
import FeatherIcon from "feather-icons-react";
// import ApexCharts from "react-apexcharts";
import Chart from "react-apexcharts";
import Card1 from "../src/assets/images/bar-chart.png";
import Card2 from "../src/assets/images/totalcollection.svg";
import Card3 from "../src/assets/images/user.png";
import Card4 from "../src/assets/images/new-patients.svg";

import "./assets/css/style.css";
import "./assets/css/responsive.css";

import AppPageLoader from "./CommonComponents/PageLoader/AppPageLoader";
import { useHistory } from "react-router-dom";

function Dashboard_historical(props) {
  let history = useHistory();

  function handleClick() {
    history.push("/dashboard-month");
  }
  // Loader
  // End of Loader
  const PatientsDetailMixChart = {
    series: [
      {
        name: "Doctor",
        type: "bar",
        data: [140000, 130000, 110000, 100000, 150000, 125000, 160000, 140000],
      },
      {
        name: "Hygiene",
        type: "bar",
        data: [120000, 140000, 100000, 90000, 180000, 110000, 120000, 90000],
      },
    ],
    options: {
      colors: ["#f48b25", "#d83f20"],
      // colors: [chartColor1, chartColor2],
      chart: {
        height: 350,
        offsetX: 10,
        // type: "bar",
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [1, 1],
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },

      fill: {
        opacity: [1, 1],
        type: ["solid", "solid"],
        // gradient: {
        //   inverseColors: false,
        //   shade: "light",
        //   type: "vertical",
        //   opacityFrom: 0.75,
        //   opacityTo: 0.55,
        //   stops: [0, 100, 100, 100],
        // },
      },
      // labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      labels: [
        "Jan-2021",
        "Feb-2021",
        "Mar-2021",
        "Apr-2021",
        "May-2021",
        "Jun-2021",
        "Jul-2021",
        "Aug-2021",
      ],
      markers: {
        size: 0,
      },
      xaxis: {
        labels: {
          offsetX: 1,
          rotate: -45,
        },
      },
      yaxis: {
        title: {
          text: "Production ($1000)",
          style: {
            colors: ["#040d14"],
            fontSize: "11px !important",
            fontWeight: 400,
            cssClass: "apexcharts-yaxis-label",
          },
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            if (typeof y !== "undefined") {
              return y.toFixed(0) + " Patients";
            }
            return y;
          },
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        offsetX: 0,
      },
    },
  };
  const TotalVisitChart = {
    series: [50, 40, 10],
    options: {
      chart: {
        type: "donut",
        height: 280,
        width: 280,
      },
      colors: ["#f48b25", "#d83f20", "#f9bc94"],
      labels: ["John", "Smith", "Elish"],
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              name: {
                show: false,
                fontSize: "16px",
                fontWeight: "800",
              },
              value: {
                fontSize: "20px",
                fontWeight: "800",
                formatter: function (val) {
                  return (Math.round(val * 100) / 100).toLocaleString();
                },
              },
              total: {
                show: true,
                label: "Total",
                formatter: function (w) {
                  return (
                    Math.round(
                      w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0) * 100
                    ) / 100
                  ).toLocaleString();
                },
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 20,
      },
      tooltip: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 1600,
          options: {
            chart: {
              width: 215,
              height: 215,
            },
            stroke: {
              width: 15,
            },
          },
        },
        {
          breakpoint: 567,
          options: {
            chart: {
              width: 210,
              height: 210,
            },
            stroke: {
              width: 15,
            },
          },
        },
      ],
    },
  };
  const TreatmentChart = {
    series: [1568, 1249, 1158],
    options: {
      chart: {
        type: "donut",
        height: 280,
        width: 280,
      },
      colors: ["#f48b25", "#d83f20", "#f9bc94"],
      labels: ["Presented", "Accepted", "Unscheduled"],
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "16px",
                fontWeight: "800",
              },
              value: {
                fontSize: "20px",
                fontWeight: "800",
                formatter: function (val) {
                  return "$ " + (Math.round(val * 100) / 100).toLocaleString();
                },
              },
              total: {
                show: true,
                label: "Total",
                formatter: function (w) {
                  return (
                    "$" +
                    (
                      Math.round(
                        w.globals.seriesTotals.reduce((a, b) => {
                          return a + b;
                        }, 0) * 100
                      ) / 100
                    ).toLocaleString()
                  );
                },
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 20,
      },
      tooltip: {
        enabled: true,
        value: {
          formatter: function (value) {
            return "$" + value;
          },
        },
      },
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 1600,
          options: {
            chart: {
              width: 215,
              height: 215,
            },
            stroke: {
              width: 15,
            },
          },
        },
        {
          breakpoint: 567,
          options: {
            chart: {
              width: 210,
              height: 210,
            },
            stroke: {
              width: 15,
            },
          },
        },
      ],
    },
  };
  const ReappointmentChart = {
    series: [30, 28, 10],
    options: {
      chart: {
        type: "donut",
        height: 280,
        width: 280,
      },
      colors: ["#f48b25", "#d83f20", "#f9bc94"],
      labels: ["Patients", "Reappointed", "Unscheduled"],
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "16px",
                fontWeight: "800",
              },
              value: {
                fontSize: "20px",
                fontWeight: "800",
                formatter: function (val) {
                  return (Math.round(val * 100) / 100).toLocaleString();
                },
              },
              total: {
                show: true,
                label: "Total",
                formatter: function (w) {
                  return (
                    Math.round(
                      w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0) * 100
                    ) / 100
                  ).toLocaleString();
                },
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 20,
      },
      tooltip: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 1600,
          options: {
            chart: {
              width: 215,
              height: 215,
            },
            stroke: {
              width: 15,
            },
          },
        },
        {
          breakpoint: 567,
          options: {
            chart: {
              width: 210,
              height: 210,
            },
            stroke: {
              width: 15,
            },
          },
        },
      ],
    },
  };
  const UnscheduledPatientChart = {
    series: [10, 35, 9, 12, 24],
    options: {
      chart: {
        type: "donut",
        height: 280,
        width: 280,
      },
      colors: ["#f48b25", "#d83f20", "#f9bc94", "#cc9900", "#ffa490"],
      labels: ["0-6 Months", "7-12 Months", "13-18 Months", "19-24 Months", "25-35 Months"],
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              name: {
                show: false,
                fontSize: "16px",
                fontWeight: "800",
              },
              value: {
                fontSize: "20px",
                fontWeight: "800",
                formatter: function (val) {
                  return (Math.round(val * 100) / 100).toLocaleString();
                },
              },
              total: {
                show: true,
                label: "Total",
                formatter: function (w) {
                  return (
                    Math.round(
                      w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0) * 100
                    ) / 100
                  ).toLocaleString();
                },
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 20,
      },
      tooltip: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 1600,
          options: {
            chart: {
              width: 215,
              height: 215,
            },
            stroke: {
              width: 15,
            },
          },
        },
        {
          breakpoint: 567,
          options: {
            chart: {
              width: 210,
              height: 210,
            },
            stroke: {
              width: 15,
            },
          },
        },
      ],
    },
  };
  const PatientsNotScheduledChart = {
    series: [5, 2, 2, 2],
    options: {
      chart: {
        type: "donut",
        height: 280,
        width: 280,
      },
      colors: ["#f48b25", "#d83f20", "#f9bc94", "#cc9900"],
      labels: ["Doctor Unscheduled", "Hygiene Unscheduled", "No-Show", "Cancellations"],
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              name: {
                show: false,
                fontSize: "16px",
                fontWeight: "800",
              },
              value: {
                fontSize: "20px",
                fontWeight: "800",
                formatter: function (val) {
                  return (Math.round(val * 100) / 100).toLocaleString();
                },
              },
              total: {
                show: true,
                label: "Total",
                formatter: function (w) {
                  return (
                    Math.round(
                      w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0) * 100
                    ) / 100
                  ).toLocaleString();
                },
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 20,
      },
      tooltip: {
        enabled: true,
        // custom: function ({ series, seriesIndex, dataPointIndex, w }) { let total = 0; for (let x of series) { total += x; } let selected = series[seriesIndex] return w.config.labels[seriesIndex] + ": " + selected + "(" + (selected / total * 100).toFixed(2) + "%)"; }
      },
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 1600,
          options: {
            chart: {
              width: 215,
              height: 215,
            },
            stroke: {
              width: 15,
            },
          },
        },
        {
          breakpoint: 567,
          options: {
            chart: {
              width: 210,
              height: 210,
            },
            stroke: {
              width: 15,
            },
          },
        },
      ],
    },
  };
  const ReferralSourceChart = {
    series: [40, 10, 30, 20, 10, 10],
    options: {
      chart: {
        type: "donut",
        height: 280,
        width: 280,
      },
      colors: ["#f48b25", "#d83f20", "#f9bc94", "#cc9900", "#ffa490", "#ffbd01"],
      labels: ["Referrals", "Google", "Insurance", "Social Media", "Drive by", "Advertisement"],
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              name: {
                show: false,
                fontSize: "16px",
                fontWeight: "800",
              },
              value: {
                fontSize: "20px",
                fontWeight: "800",
                formatter: function (val) {
                  return (Math.round(val * 100) / 100).toLocaleString();
                },
                // formatter: function (value) {
                //   return "$" + value;
                // }
              },
              total: {
                show: true,
                label: "Total",
                formatter: function (w) {
                  return (
                    Math.round(
                      w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0) * 100
                    ) / 100
                  ).toLocaleString();
                },
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 20,
      },
      tooltip: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 1600,
          options: {
            chart: {
              width: 215,
              height: 215,
            },
            stroke: {
              width: 15,
            },
          },
        },
        {
          breakpoint: 567,
          options: {
            chart: {
              width: 210,
              height: 210,
            },
            stroke: {
              width: 15,
            },
          },
        },
      ],
    },
  };
  const ProductionReferralSourceChart = {
    series: [40, 10, 30, 20, 10, 10],
    options: {
      chart: {
        type: "donut",
        height: 280,
        width: 280,
      },
      colors: ["#f48b25", "#d83f20", "#f9bc94", "#cc9900", "#ffa490", "#ffbd01"],
      labels: ["Referrals", "Google", "Insurance", "Social Media", "Drive by", "Advertisement"],
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            labels: {
              show: true,
              name: {
                show: false,
                fontSize: "16px",
                fontWeight: "800",
              },
              value: {
                fontSize: "20px",
                fontWeight: "800",
                formatter: function (val) {
                  return "$" + (Math.round(val * 100) / 100).toLocaleString();
                },
                // formatter: function (value) {
                //   return "$" + value;
                // }
              },
              total: {
                show: true,
                label: "Total",
                formatter: function (w) {
                  return (
                    "$" +
                    (
                      Math.round(
                        w.globals.seriesTotals.reduce((a, b) => {
                          return a + b;
                        }, 0) * 100
                      ) / 100
                    ).toLocaleString()
                  );
                },
                // formatter: function (w) {
                //   return '$' + w.globals.seriesTotals.reduce((a, b) => {
                //     return a + b
                //   }, 0)
                // }
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 20,
      },
      tooltip: {
        enabled: true,
      },
      legend: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 1600,
          options: {
            chart: {
              width: 215,
              height: 215,
            },
            stroke: {
              width: 15,
            },
          },
        },
        {
          breakpoint: 567,
          options: {
            chart: {
              width: 210,
              height: 210,
            },
            stroke: {
              width: 15,
            },
          },
        },
      ],
    },
  };
  const [showLoading, setShowLoading] = useState(true);

  useEffect(
    () => {
      let timer1 = setTimeout(() => setShowLoading(false), 1000);

      // this will clear Timeout when component unmount like in willComponentUnmount
      return () => {
        clearTimeout(timer1);
        // setShowLoading(true)
      };
    },
    [] //useEffect will run only one time
    //if you pass a value to array, like this [data] than clearTimeout will run every time this value changes (useEffect re-run)
  );

  return (
    <div>
      <AppNavbar />
      <div id="sub-header ">
        <div className="d-flex  py-4  justify-content-center  align-items-center  flex-wrap only-mobile-view">
          <button type="button" className="btn btn-sm blue-btn-bg green-btn ">
            Overview
          </button>
          <button type="button" className="btn btn-sm blue-btn-bg green-btn ">
            Today
          </button>
          <button type="button" className="btn btn-sm blue-btn-bg green-btn ">
            This Week
          </button>
          <button type="button" className="btn btn-sm blue-btn-bg green-btn " onClick={handleClick}>
            <a>This Month</a>
          </button>
          <button type="button" className="btn btn-sm blue-btn-bg green-btn ">
            This Year
          </button>
          <div className="style-select dayfilterSelect">
            <Select2
              defaultValue={6}
              data={[
                { text: "Custom Date", id: 6 },
                { text: "Yesterday", id: 2 },
                { text: "Last Week", id: 3 },
                { text: "Last Month", id: 4 },
                { text: "Last Year", id: 5 },
              ]}
              options={{
                placeholder: "Select",
              }}
            />
          </div>
        </div>
        <div className="d-flex  py-4  justify-content-center  align-items-center  border-top flex-wrap date-filter">
          <div className="filter-ctrl">
            <label>From Date</label>
            <input type="date" className="form-control date-select" name="email" max="9999-12-31" />
            <div className="err-message">Please select less date then To Date</div>
          </div>
          <div className="filter-ctrl">
            <label>To Date</label>
            <input type="date" className="form-control date-select" name="email" max="9999-12-31" />
            <div className="err-message">Please select greater date then From Date</div>
          </div>
        </div>
      </div>
      {/* <!-- cards --> */}
      <div id="blue-card" className="calender-box pt-0">
        <div className="container-fluid">
          <div className="row calender-row">
            <div className="col-xl-5 col-md-12 col-12 padding-r-4 calender-col">
              <div className="row">
                <div className=" col-lg-6  col-md-6 col-sm-6 col-12 card-padding-1 pb-2">
                  <div className="card blue-card">
                    <div className="header">
                      <h6>
                        Total Production
                        <FeatherIcon icon="info" size="18" className="ml-1" />
                      </h6>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p>$19,175</p>
                      </div>
                    </div>
                    <div className="card-img">
                      <img src={Card1} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 card-padding-4 pb-2">
                  <div className="card blue-card">
                    <div className="header">
                      <h6>
                        Total Collections
                        <FeatherIcon icon="info" size="18" className="ml-1" />
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p>$20,879</p>
                      </div>
                    </div>
                    <div className="card-img">
                      <img src={Card2} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 card-padding-1">
                  <div className="card blue-card">
                    <div className="header">
                      <h6>
                        Total Unscheduled Active <br />
                        Patients
                        <FeatherIcon icon="info" size="18" className="ml-1" />
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p>45</p>
                      </div>
                    </div>
                    <div className="card-img">
                      <img src={Card4} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 card-padding-4">
                  <div className="card blue-card">
                    <div className="header">
                      <h6>
                        New Patients
                        <FeatherIcon icon="info" size="18" className="ml-1" />
                      </h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p>13</p>
                      </div>
                    </div>
                    <div className="card-img">
                      <img src={Card3} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-md-12 col-12 padding-l-4 calender-col prod-col main-mixchart">
              <div className="total-patients-list">
                <div className="numberOf-patients-list">
                  <div className="numberOf-patients-txt">
                    Total Patients
                    <FeatherIcon icon="info" size="18" className="ml-1" />
                  </div>
                  <div className="numberOf-patients">5,000</div>
                </div>
                <div className="numberOf-patients-list">
                  <div className="numberOf-patients-txt">
                    Active Patients
                    <FeatherIcon icon="info" size="18" className="ml-1" />
                  </div>
                  <div className="numberOf-patients">2,500</div>
                </div>
                <div className="numberOf-patients-list">
                  <div className="numberOf-patients-txt">
                    Inactive Patients
                    <FeatherIcon icon="info" size="18" className="ml-1" />
                  </div>
                  <div className="numberOf-patients">2,500</div>
                </div>
              </div>
              <div className="white-card pb-0 stack-box  mix-area-chart">
                <h6 className="blue-title-h6 position-absolute">Production</h6>

                <Chart
                  id="#chart-stack"
                  options={PatientsDetailMixChart.options}
                  series={PatientsDetailMixChart.series}
                  // type="bar"
                  height={310}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- cards --> */}
      <div id="chart-data" className="historical-doctor card-row doctor-row pb-0 circle-chart">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h6 className="blue-title-h6 blue-title-h6-blue mb-lg-1 mb-0 mt-2">Doctor</h6>
            </div>
            <div className="col-12 pb-3">
              <div className="summary-cards-container patients-info-row">
                <div className="doctor-production patients-info-box donut-chart-col">
                  <div className="patients-info">
                    <h6>
                      Doctor Production
                      <FeatherIcon icon="info" size="18" className="ml-1" />
                    </h6>
                    <div className="patient-info-detail">
                      <div className="goal-col">
                        <span className="number">$12,765</span>
                        <div className="daily-goal right-border">
                          <h6>Daily Goal</h6>
                          <span className="number">$12,765</span>
                        </div>
                      </div>
                      <div className="status-col">
                        <div className="prev-status">
                          <span className="icon green-txt">
                            {/* <i class="fa fa-caret-up"></i> */}
                            <span className="arrow-up"></span>
                          </span>
                          <span className="status-value green-txt">$1,000 (27%)</span>
                          <span className="status-txt">From Previous WTD</span>
                        </div>
                        <div className="next-status">
                          <span className="icon green-txt">
                            {/* <i class="fa fa-caret-up"></i> */}
                            <span className="arrow-up"></span>
                          </span>
                          <span className="status-value green-txt">$1,000 (27%)</span>
                          <span className="status-txt green-txt">Above Goal</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="patients-map">
                    <h6>Total Visit</h6>
                    <div className="patients-info-map donut-center-value">
                      <div className="app-chart-container">
                        <p className="chart-data">
                          <span>439</span>
                        </p>
                        <div className="inner-container">
                          <Chart
                            id="new-5"
                            options={TotalVisitChart.options}
                            series={TotalVisitChart.series}
                            type="donut"
                            height={280}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="doctor-production patients-info-box treatment-info-box">
                  <div className="patients-info-head">
                    <h6>
                      Treatment Acceptance
                      <FeatherIcon icon="info" size="18" className="ml-1" />
                    </h6>
                    <h6>Acceptance</h6>
                    <button
                      type="button"
                      className="btn btn-sm blue-btn-bg "
                      onClick={() => history.push("/unscheduled-treatment")}
                    >
                      Engage Unscheduled
                    </button>
                  </div>
                  <div className="patients-info right-border">
                    {/* <h6>Treatment Acceptance</h6> */}
                    <div className="patient-info-detail">
                      <div className="goal-col">
                        <span className="number">35%</span>
                        <div className="daily-goal  right-border">
                          <h6>Goal</h6>
                          <span className="number green-txt">65%</span>
                        </div>
                      </div>
                      <div className="status-col">
                        <div className="prev-status">
                          <span className="icon">
                            <span className="arrow-up"></span>
                          </span>
                          <span className="status-value green-txt">$1,000 (27%)</span>
                          <span className="status-txt">From Previous WTD</span>
                        </div>
                        <div className="next-status">
                          <span className="icon">
                            <span className="arrow-up"></span>
                          </span>
                          <span className="status-value green-txt">$1,000 (27%)</span>
                          <span className="status-txt green-txt">Above Goal</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="patients-map">
                    <h6 className="chart-head">Acceptance</h6>
                    <div className="patients-info-map-sec">
                      <div className="patients-info-map">
                        <div className="flex-fill width-100 pos-relative inner-col">
                          <ul className="donut-label list-unstyled">
                            <li className="orange-label blue-label">
                              Presented
                              <span>$1,568</span>
                            </li>
                            <li className="lightblue-label">
                              Accepted
                              <span>$1,249</span>
                            </li>
                            <li className="limeblue-label">
                              Unscheduled
                              <span>$1,158</span>
                            </li>
                          </ul>
                          <div className="app-chart-container">
                            <p className="chart-data">
                              Accepted<span>$1,249</span>
                            </p>
                            <div className="inner-container">
                              {/* <div id="donut-chart3" className="donut mt-0"></div> */}
                              <Chart
                                id="#donut-chart3"
                                className="donut mt-0"
                                options={TreatmentChart.options}
                                series={TreatmentChart.series}
                                type="donut"
                                height={280}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="doctor-production patients-info-box no-map">
                  <div className="patients-info">
                    <h6>
                      Production Per Visit
                      <FeatherIcon icon="info" size="18" className="ml-1" />
                    </h6>
                    <div className="patient-info-detail">
                      <div className="goal-col">
                        <span className="number">$458</span>
                        <div className="daily-goal  right-border">
                          <h6>Goal</h6>
                          <span className="number">$500</span>
                        </div>
                      </div>
                      <div className="status-col">
                        <div className="prev-status">
                          <span className="icon">
                            <span className="arrow-up"></span>
                          </span>
                          <span className="status-value green-txt">$1,000 (27%)</span>
                          <span className="status-txt">From Previous WTD</span>
                        </div>
                        <div className="next-status">
                          <span className="icon">
                            <span className="arrow-down"></span>
                          </span>
                          <span className="status-value red-txt">$1,252 (39%)</span>
                          <span className="status-txt red-txt">Below Goal</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Hygiene */}
      <div id="chart-data" className="historical-doctor hygiene-row card-row pb-0 circle-chart">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h6 className="blue-title-h6 blue-title-h6-blue mb-lg-1 mb-0 mt-2">Hygiene</h6>
            </div>
            <div className="col-12 pb-3">
              <div className="summary-cards-container patients-info-row">
                <div className="doctor-production patients-info-box donut-chart-col">
                  <div className="patients-info">
                    <h6>
                      Hygiene Production
                      <FeatherIcon icon="info" size="18" className="ml-1" />
                    </h6>
                    <div className="patient-info-detail">
                      <div className="goal-col">
                        <span className="number">$6,410</span>
                        <div className="daily-goal right-border">
                          <h6>Daily Goal</h6>
                          <span className="number">$6,750</span>
                        </div>
                      </div>
                      <div className="status-col">
                        <div className="prev-status">
                          <span className="icon green-txt">
                            {/* <i class="fa fa-caret-up"></i> */}
                            <span className="arrow-up"></span>
                          </span>
                          <span className="status-value green-txt">$1,000 (27%)</span>
                          <span className="status-txt">From Previous WTD</span>
                        </div>
                        <div className="next-status">
                          <span className="icon green-txt">
                            {/* <i class="fa fa-caret-up"></i> */}
                            <span className="arrow-up"></span>
                          </span>
                          <span className="status-value green-txt">$2,714 (27%)</span>
                          <span className="status-txt green-txt">Above Goal</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="patients-map">
                    <h6>Total Visit</h6>
                    <div className="patients-info-map  donut-center-value">
                      <div className="app-chart-container">
                        <p className="chart-data">
                          <span>379</span>
                        </p>
                        <div className="inner-container">
                          <Chart
                            id="new-5"
                            options={TotalVisitChart.options}
                            series={TotalVisitChart.series}
                            type="donut"
                            height={280}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="doctor-production patients-info-box">
                  <div className="patients-info-head">
                    <h6>
                      Reappointment Rate
                      <FeatherIcon icon="info" size="18" className="ml-1" />
                    </h6>
                    <h6>Reappointment</h6>
                  </div>
                  <div className="patients-info right-border">
                    <div className="patient-info-detail">
                      <div className="goal-col">
                        <span className="number">60%</span>
                        <div className="daily-goal  right-border">
                          <h6>Goal</h6>
                          <span className="number green-txt">85%</span>
                        </div>
                      </div>
                      <div className="status-col">
                        <div className="prev-status">
                          <span className="icon">
                            <span className="arrow-up"></span>
                          </span>
                          <span className="status-value green-txt">$1,000 (27%)</span>
                          <span className="status-txt">From Previous WTD</span>
                        </div>
                        <div className="next-status">
                          <span className="icon">
                            <span className="arrow-up"></span>
                          </span>
                          <span className="status-value green-txt">$1,000 (27%)</span>
                          <span className="status-txt green-txt">Above Goal</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="patients-map">
                    <h6 className="chart-head">Reappointment</h6>
                    {/* <h6>Total Visit</h6> */}
                    <div className="patients-info-map">
                      <div className="flex-fill width-100 pos-relative inner-col">
                        <ul className="donut-label list-unstyled">
                          <li className="orange-label blue-label">
                            Patients
                            <span>30</span>
                          </li>
                          <li className="lightblue-label">
                            Reappointed
                            <span>28</span>
                          </li>
                          <li className="limeblue-label">
                            Unscheduled
                            <span>10</span>
                          </li>
                        </ul>
                        <div className="app-chart-container">
                          <p className="chart-data">
                            Patients<span>30</span>
                          </p>
                          <div className="inner-container">
                            {/* <div id="donut-chart3" className="donut mt-0"></div> */}
                            <Chart
                              id="#donut-chart3"
                              className="donut mt-0"
                              options={ReappointmentChart.options}
                              series={ReappointmentChart.series}
                              type="donut"
                              height={280}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="doctor-production patients-info-box no-map">
                  <div className="patients-info">
                    <h6>
                      Production Per Visit
                      <FeatherIcon icon="info" size="18" className="ml-1" />
                    </h6>
                    <div className="patient-info-detail">
                      <div className="goal-col">
                        <span className="number">$1,264</span>
                        <div className="daily-goal  right-border">
                          <h6>Goal</h6>
                          <span className="number">$2,000</span>
                        </div>
                      </div>
                      <div className="status-col">
                        <div className="prev-status">
                          <span className="icon">
                            <span className="arrow-up"></span>
                          </span>
                          <span className="status-value green-txt">$1,000 (27%)</span>
                          <span className="status-txt">From Previous WTD</span>
                        </div>
                        <div className="next-status">
                          <span className="icon">
                            <span className="arrow-down"></span>
                          </span>
                          <span className="status-value red-txt">$850 (20%)</span>
                          <span className="status-txt red-txt">Below Goal</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End of Hygiene */}

      {/* Font Office */}
      <div id="chart-data" className="historical-doctor font-office-row card-row pb-0 circle-chart">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h6 className="blue-title-h6 blue-title-h6-blue mb-lg-1 mb-0 mt-2">Front Office</h6>
            </div>
            <div className="col-12 pb-3">
              <div className="summary-cards-container patients-info-row ">
                <div className="doctor-production patients-info-box reactivate-patients-box">
                  <div className="patients-info">
                    <h6>
                      Reactivated Patients
                      <FeatherIcon icon="info" size="18" className="ml-1" />
                    </h6>
                    <div className="patient-info-detail">
                      <div className="goal-col">
                        <span className="number">8</span>
                        {/* <h6>Reactivated Patients</h6> */}
                        <div className="daily-goal right-border">
                          <h6>
                            Reactivated Patients <br /> Daily Goal
                          </h6>
                          <span className="number">12</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="patients-info scheduled-production-info">
                    <h6>Scheduled Production</h6>
                    <div className="patient-info-detail">
                      <div className="goal-col">
                        <span className="number">$2,342</span>
                        <div className="status-col">
                          <div className="next-status">
                            <span className="icon">
                              <span className="arrow-down"></span>
                            </span>
                            <span className="status-value red-txt">4 (30%)</span>
                            <span className="status-txt red-txt">Below Goal</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="doctor-production patients-info-box not-scheduled-box">
                  <div className="patients-info-head">
                    <h6>
                      Patients Not Scheduled
                      <FeatherIcon icon="info" size="18" className="ml-1" />
                    </h6>
                    <h6>Not Scheduled</h6>
                  </div>
                  <div className="patients-info right-border">
                    <div className="patient-info-detail">
                      <div className="goal-col">
                        <span className="number">9</span>
                        <div className="daily-goal ">
                          <h6>Unscheduled Production</h6>
                          <span className="number green-txt">$2,255</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="patients-map">
                    <h6 className="chart-head">Not Scheduled</h6>
                    {/* <h6>Total Visit</h6> */}
                    <div className="patients-info-map">
                      <div className="flex-fill width-100 pos-relative inner-col">
                        <ul className="donut-label list-unstyled">
                          <li className="orange-label blue-label">
                            Doctor Unscheduled
                            <span>5</span>
                          </li>
                          <li className="lightblue-label ">
                            Hygiene Unscheduled
                            <span>2</span>
                          </li>
                          <li className="limeblue-label">
                            No-Show
                            <span>2</span>
                          </li>
                          <li className="limeblue-label gray-label">
                            Cancellations
                            <span>2</span>
                          </li>
                        </ul>
                        <div className="app-chart-container">
                          <p className="chart-data ">
                            <span className="black-color">766</span>
                          </p>
                          <div className="inner-container">
                            {/* <div id="donut-chart3" className="donut mt-0"></div> */}
                            <Chart
                              id="#donut-chart3"
                              className="donut mt-0"
                              options={PatientsNotScheduledChart.options}
                              series={PatientsNotScheduledChart.series}
                              type="donut"
                              height={280}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="doctor-production patients-info-box no-map unactive-patients">
                  <div className="patients-info-head">
                    <h6>
                      Total Unscheduled Active Patients
                      <FeatherIcon icon="info" size="18" className="ml-1" />
                    </h6>
                    <h6>Unscheduled Active Patients</h6>
                  </div>
                  <div className="patients-info">
                    <div className="patient-info-detail">
                      <div className="goal-col right-border">
                        <span className="number">766</span>
                        <div className="daily-goal ">
                          <h6>Value</h6>
                          <span className="number">$345,466</span>
                        </div>
                      </div>
                      <div className="patients-map">
                        <h6 className="chart-head">Unscheduled Active Patients</h6>
                        {/* <h6>Total Visit</h6> */}
                        <div className="patients-info-map  donut-center-value">
                          <div className="app-chart-container">
                            <p className="chart-data">
                              <span>439</span>
                            </p>
                            <div className="inner-container">
                              <Chart
                                id="new-5"
                                options={UnscheduledPatientChart.options}
                                series={UnscheduledPatientChart.series}
                                type="donut"
                                height={280}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End of Font Office */}

      {/* <!-- Footer Chart Row --> */}
      <div
        id="chart-data"
        className="historical-doctor pb-0 circle-chart footer-chart-section mb-5"
      >
        <div className="container-fluid">
          <div className="row footer-chart-row">
            <div className="paients-chart-col referral-chart">
              <div className="">
                <h6 className="blue-title-h6 blue-title-h6-blue mb-lg-1 mb-0 mt-2">
                  New Patients By Referral Source
                  <FeatherIcon icon="info" size="18" className="ml-1" />
                </h6>
              </div>
              <div className="pb-5 pt-5 pl-5 pr-5 chart-section position-relative">
                {showLoading && <AppPageLoader />}
                <div className="app-chart-container">
                  <h6>Daily Goal</h6>
                  <p className="chart-data">
                    <span>439</span>
                  </p>
                  <div className="inner-container">
                    <Chart
                      id="new-5"
                      options={ReferralSourceChart.options}
                      series={ReferralSourceChart.series}
                      type="donut"
                      height={280}
                      // width={360}
                    />
                  </div>
                </div>
                <ul className="donut-label list-unstyled">
                  <li className="green-label">
                    Referrals
                    {/* <span>5</span> */}
                  </li>
                  <li className="red-label">
                    Google
                    {/* <span>2</span> */}
                  </li>
                  <li className="blue-label">
                    Insurance
                    {/* <span>2</span> */}
                  </li>
                  <li className="gray-label">
                    Social Media
                    {/* <span>2</span> */}
                  </li>
                  <li className="limeblue-label">
                    Drive by
                    {/* <span>2</span> */}
                  </li>
                  <li className="dark-yellow-label">
                    Advertisement
                    {/* <span>2</span> */}
                  </li>
                </ul>
              </div>
            </div>
            <div className="paients-chart-col referral-chart">
              <div className="">
                <h6 className="blue-title-h6 blue-title-h6-blue mb-lg-1 mb-0 mt-2">
                  Production By Referral Source
                  <FeatherIcon icon="info" size="18" className="ml-1" />
                </h6>
              </div>
              <div className="pb-5 pt-5 pl-5 pr-5 chart-section position-relative">
                {showLoading && <AppPageLoader />}
                <div className="app-chart-container">
                  <h6>Daily Goal</h6>
                  <p className="chart-data">
                    <span>$835</span>
                  </p>
                  <div className="inner-container">
                    <Chart
                      id="new-5"
                      options={ProductionReferralSourceChart.options}
                      series={ProductionReferralSourceChart.series}
                      type="donut"
                      height={280}
                      // width={360}
                    />
                  </div>
                </div>
                <ul className="donut-label list-unstyled">
                  <li className="green-label">
                    Referrals
                    {/* <span>5</span> */}
                  </li>
                  <li className="red-label">
                    Google
                    {/* <span>2</span> */}
                  </li>
                  <li className="blue-label">
                    Insurance
                    {/* <span>2</span> */}
                  </li>
                  <li className="gray-label">
                    Social Media
                    {/* <span>2</span> */}
                  </li>
                  <li className="limeblue-label">
                    Drive by
                    {/* <span>2</span> */}
                  </li>
                  <li className="dark-yellow-label">
                    Advertisement
                    {/* <span>2</span> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end collection */}
    </div>
  );
}

export default Dashboard_historical;
