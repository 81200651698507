import React, { useEffect, useState } from "react";
import { HPVQASection } from "../HPVQA/HPVQA";
import { Card, CardBody } from "reactstrap";
import LabelSelect from "../../Screening/SectionCommonComponents/LabelSelect";
import CommonApi from "../../API/CommonApiCalls";
import { Button } from "react-bootstrap";
import AppPageLoader from "../PageLoader/AppPageLoader";
import Api from "../../API/screening";
import { localStorageUserData } from "../../config/appConstatnts";
import { toast } from "react-toastify";

const AddNewScreening = ({
  title,
  className,
  setShowCard,
  PatientId,
  LocationBy,
  handleOnCancel,
  handleOnSubmit,
}) => {
  const [appPageLoader, setAppPageLoader] = useState(false);
  const [locationUserList, setLocationUserList] = useState({
    locationList: [{ value: 0, label: "Select Location" }],
    usersList: [{ value: 0, label: "Select Provider" }],
  });
  const resetPatientInfoData = {
    screenBy: locationUserList && locationUserList.usersList[0],
    screenByError: "",
    searchLocationBy: locationUserList && locationUserList.locationList[0],
    QAResponse: [],
    patientId: PatientId ?? 0,
    locationBy: LocationBy ?? 0,
  };
  const [submitAddNewScreeningData, setSubmitAddNewScreeningData] = useState(resetPatientInfoData);
  const [apiError, setApiError] = useState("");

  //   ===========handle change ==============
  const handleChange = (event, name) => {
    setSubmitAddNewScreeningData({
      ...submitAddNewScreeningData,
      screenBy: event,
      screenByError: event.value === 0 ? "Please Select Provider" : "",
    });
  };

  const handleClick = (val, id) => {
    const newQAResp = [...submitAddNewScreeningData.QAResponse];
    newQAResp[id].a = val;
    newQAResp[id].error = "";
    setSubmitAddNewScreeningData({ ...submitAddNewScreeningData, QAResponse: newQAResp });
  };

  // =================== on submit ===============================
  const handleSubmit = () => {
    const newSubmitPatientInfoData = submitAddNewScreeningData;
    let isError = false;
    if (newSubmitPatientInfoData.screenBy.value === 0) {
      newSubmitPatientInfoData.screenByError = "Please Select Provider";
      isError = true;
    }
    if (
      newSubmitPatientInfoData.QAResponse.map(({ id, a }) => ({
        QuestionId: id,
        Answer: a,
      })).every((val) => val.Answer.length > 0) === false
    ) {
      let data = newSubmitPatientInfoData.QAResponse;
      // eslint-disable-next-line array-callback-return
      data.filter((val) => {
        if (val.type === 1) {
          if (val.a === "") val.error = "Please select an option";
          else val.error = "";
        }
        if (val.type === 0) {
          if (val.a.length === 0) val.error = "Please enter a value";
          else val.error = "";
        }
      });
      isError = true;
      newSubmitPatientInfoData.QAResponse = data;
    } else {
      let data = newSubmitPatientInfoData.QAResponse;
      data.filter((val) => {
        val.error = "";
        return val;
      });
      newSubmitPatientInfoData.QAResponse = data;
    }

    setSubmitAddNewScreeningData({ ...newSubmitPatientInfoData });
    if (!isError) {
      const payLoad = {
        PatientId: newSubmitPatientInfoData.patientId,
        DoctorId: newSubmitPatientInfoData.screenBy.value,
        LocationId: newSubmitPatientInfoData.locationBy,
        AccountId:
          localStorage.getItem("userData") &&
          JSON.parse(localStorage.getItem("userData")).AccountId,
        Screening: newSubmitPatientInfoData.QAResponse.map(({ id, a }) => ({
          QuestionId: id,
          Answer: a,
        })),
      };

      setAppPageLoader(true);
      Api.AddScreening(payLoad)
        .then((resp) => {
          setShowCard(false);
          toast.success(resp.data.Message);
          handleOnSubmit();
        })
        .catch((err) => {
          setApiError(err.response.data.Message);
          toast.error(err.response.data.Message);
        })
        .finally(() => setAppPageLoader(false));
    }
  };
  const handleCancel = () => {
    setShowCard(false);
  };

  // ============ getApi =====================

  const getQAApi = () => {
    setAppPageLoader(true);
    CommonApi.getQA()
      .then((resp) => {
        const qaList = resp.data.Result.map(({ Id, OrderBy, Question, Type }) => ({
          id: Id,
          q: Question,
          type: Type,
          OrderBy,
          a: "",
          error: "",
        }));
        submitAddNewScreeningData.QAResponse = qaList;
        setSubmitAddNewScreeningData(submitAddNewScreeningData);
        setAppPageLoader(false);
      })
      .catch((err) => {
        setAppPageLoader(false);
      })
      .finally(() => setAppPageLoader(false));
  };
  const getUserLocationApi = () => {
    setAppPageLoader(true);
    CommonApi.getLocationDoctorList()
      .then((resp) => {
        let locationList =
          resp.data.Result.Location.length <= 1
            ? [
                {
                  value: resp.data.Result.Location[0].Id,
                  label: resp.data.Result.Location[0].Name,
                },
              ]
            : [
                { value: 0, label: "Select Location" },
                ...resp.data.Result.Location.map(({ Id, Name }) => ({
                  value: Id,
                  label: Name,
                })),
              ];

        let usersList = [
          { value: 0, label: "Select Provider", locationId: 0 },
          ...resp.data.Result.User.map(({ Id, Name, LocationId }) => ({
            value: Id,
            label: Name,
            locationId: LocationId,
          })),
        ];
        setLocationUserList({
          locationList,
          usersList,
        });
        submitAddNewScreeningData.screenBy = usersList.find(
          (doctor) => doctor.locationId === locationList[0].value
        );
        setSubmitAddNewScreeningData(submitAddNewScreeningData);
        setAppPageLoader(false);
      })
      .catch((err) => {
        setAppPageLoader(false);
      })
      .finally(() => setAppPageLoader(false));
  };

  useEffect(() => {
    getQAApi();
    getUserLocationApi();
    // window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className={`border-0 mb-3 patients-info-card ${className}`}>
      {appPageLoader && <AppPageLoader className={"position-fixed"} />}
      <CardBody>
        <h5 className="text-danger">{apiError}</h5>
        <h2 className="mt-0 pt-0">{`${title ?? "Add New Screening"}`}</h2>
        <LabelSelect
          parentClassName={"mb-5 position-relative"}
          label={["Screen by ", <span key={1}>*</span>]}
          className={"style-select react-select dayfilterSelect mr-2"}
          value={submitAddNewScreeningData.screenBy}
          name={"screenBy"}
          onChange={(event) => handleChange(event, "screenBy")}
          options={locationUserList.usersList}
          error={submitAddNewScreeningData.screenByError}
        />
        <div className="hpv-questions-block">
          <HPVQASection handleClick={handleClick} QAList={submitAddNewScreeningData.QAResponse} />
          <div className="actions-btns d-flex mt-1">
            <Button color="primary" className="fw400 title2 save-btn" onClick={handleSubmit}>
              Save
            </Button>
            <Button color="secondary" className="cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default AddNewScreening;
