import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Api from "../../API/login";
import { ReactComponent as MailIcon } from "../../assets/images/envelope.svg";
import { ReactComponent as Icon1 } from "../../assets/images/padlock.svg";
import AppPageLoader from "../../CommonComponents/PageLoader/AppPageLoader";
import Input from "../../CommonComponents/Input/Input";
import { goToMainPage, gotoSignUp, goToStateDashboardPage } from "../../config/CommonFunctions";
import BlockButton from "../SectionCommonComponent/BlockButton";
import LRFLayout from "../SectionCommonComponent/LRFLayout";

const SignInPage = () => {
  const history = useHistory();
  const selectedEmail = history.location.state && history.location.state.email;
  const [textBoxType, setTextBoxType] = useState("password");
  const [eyeTog, seteyeTog] = useState("false");

  const handleToggleeyeTog = () => {
    textBoxType === "password" ? setTextBoxType("text") : setTextBoxType("password");
    seteyeTog(!eyeTog);
  };

  const [userModel, setUserModel] = useState({
    Username: selectedEmail ?? "",
    Password: "",
  });
  const [errors, setErrors] = useState({
    Username: "",
    Password: "",
  });
  const [sectionLoader, setSectionLoader] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");

  const handleChange = (event) => {
    event.preventDefault();
    let { name, value } = event.target;
    setUserModel({ ...userModel, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let flagError = false;

    if (userModel.Username.length === 0) {
      setErrors((errors) => ({
        ...errors,
        Username: "Username or Email is required.",
      }));
      flagError = true;
    }
    if (userModel.Password.length === 0) {
      setErrors((errors) => ({
        ...errors,
        Password: "Password is required.",
      }));
      flagError = true;
    }

    if (!flagError) {
      setErrors({ Username: "", Password: "" });
      const payLoad = {
        ...userModel,
        CompanyId: localStorage.getItem("CompanyId"),
      };
      setSectionLoader(true);
      Api.logIn(payLoad)
        .then((res) => {
          setSectionLoader(false);
          localStorage.setItem("token", res.data.Result.Token);
          localStorage.setItem("userData", JSON.stringify(res.data.Result));
          if (res.data.Result.IsAdmin) {
            goToStateDashboardPage();
          } else goToMainPage();
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            setApiErrorMessage(error.response.data.Message);
          } else {
            setApiErrorMessage("Something went wrong");
          }
          setSectionLoader(false);
        });
    }
  };

  return (
    <>
      {sectionLoader && <AppPageLoader />}
      <LRFLayout
        titleText={"Sign in"}
        sideBannerText={"sign up"}
        sideBannerRoute={gotoSignUp}
        sideBannerPositionCode={0}
        headerLine={"Welcome back. Enter your credentials to login."}
      >
        {apiErrorMessage && <div className="err-message">{apiErrorMessage}</div>}
        <form onSubmit={handleSubmit}>
          <div className="content">
            <Input
              name="Username"
              type={"text"}
              className={"form-control light-border"}
              id={"username"}
              maxLength="100"
              value={userModel.Username}
              onChange={handleChange}
              placeholder={"User Name / Email Address*"}
              error={errors.Username}
              iconBefore={<MailIcon />}
            />
            <Input
              name="Password"
              type={textBoxType}
              className={"form-control light-border pass-input"}
              id={"exampleInputPassword1"}
              value={userModel.Password}
              onChange={handleChange}
              placeholder={"Password*"}
              error={errors.Password}
              iconBefore={<Icon1 />}
              iconAfter={
                <i
                  className={`passTogEye icon-eye ${!eyeTog ? "passVisible" : ""}`}
                  onClick={handleToggleeyeTog}
                ></i>
              }
            />
            <div className="forget-link link-purple">
              <Link to={"/forgot-password"} className="h5 f-600">
                Forgot Password?
              </Link>
            </div>
          </div>
          <div className="account-btn-block">
            <div className="btn-block">
              <button className={"btn btn-outline-primary blue-btn-bg"} type="submit">
                Sign in
              </button>
            </div>
          </div>
          {/* <BlockButton text={"sign in"} onClick={handleSubmit} /> */}
        </form>
      </LRFLayout>
    </>
  );
};
export default SignInPage;
