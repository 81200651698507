import React from "react";

import icon2 from "../../assets/images/pageNotFound.svg";

export default function ErrorPage() {
  return (
    <>
      <div className="text-center">
        <img src={icon2} className="img-fluid mx-auto w-50" alt="" />
        <h2 className="text-center my-2">Page Not Found</h2>
      </div>
    </>
  );
}
