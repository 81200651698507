import React, { useEffect, useState } from "react";
import { ReactComponent as Icon1 } from "../../assets/images/Street.svg";
import { ReactComponent as Icon2 } from "../../assets/images/city.svg";
import { ReactComponent as Icon3 } from "../../assets/images/location-pin.svg";
import { ReactComponent as Icon4 } from "../../assets/images/zip-code.svg";
import { goToSignin } from "../../config/CommonFunctions";
import LRFLayout from "../SectionCommonComponent/LRFLayout";
import Input from "../../CommonComponents/Input/Input";
import { validZipCodeRegex } from "../../config/appRegex";

import useStateList from "../../CommonComponents/_helper-functions/StateList";
import BlockButton from "../SectionCommonComponent/BlockButton";
import BackButton from "../SectionCommonComponent/BackButton";
import SelectDropdown from "../SectionCommonComponent/SelectDropdown";

// export default function SignUpPage2(){
const SignUpPage2 = (props) => {
  const { next, previousPage, postData, setPostData } = props;

  const { stateList, stateListError } = useStateList("US");
  const [commonError, setCommonError] = useState("");

  const [practiceInfo, setPracticeInfo] = useState({
    ...postData.Address,
  });

  const [practiceInfoError, setPracticeInfoError] = useState(postData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPracticeInfo({ ...practiceInfo, [name]: value });
    let errorValue = "";
    switch (name) {
      case "LocationName":
        if (value.trim().length === 0) {
          errorValue = "Street Address is required.";
        }
        break;
      case "City":
        if (value.trim().length === 0) {
          errorValue = "City is required.";
        }
        break;
      case "State":
        if (value.trim().length === 0) {
          errorValue = "State is required.";
        }
        break;
      case "ZipCode":
        if (value.trim().length === 0) {
          errorValue = "Zip Code is required.";
        } else {
          errorValue = validZipCodeRegex.test(value) ? "" : "Enter valid Zip Code.";
        }
        break;
      default:
        break;
    }
    setPracticeInfoError((practiceInfoError) => ({
      ...practiceInfoError,
      [name]: errorValue,
    }));
  };

  const handleSubmit = () => {
    let flagError = false;
    if (practiceInfo.LocationName.length === 0) {
      setPracticeInfoError((practiceInfoError) => ({
        ...practiceInfoError,
        LocationName: "Street Address is required.",
      }));
      flagError = true;
    }
    if (practiceInfo.City.length === 0) {
      setPracticeInfoError((practiceInfoError) => ({
        ...practiceInfoError,
        City: "City is required.",
      }));
      flagError = true;
    }
    if (practiceInfo.State === 0 || practiceInfo.State === "0" || practiceInfo.State.length === 0) {
      setPracticeInfoError((practiceInfoError) => ({
        ...practiceInfoError,
        State: "State is required.",
      }));
      flagError = true;
    }
    if (practiceInfo.ZipCode.length === 0) {
      setPracticeInfoError((practiceInfoError) => ({
        ...practiceInfoError,
        ZipCode: "Zip Code is required.",
      }));
      flagError = true;
    } else if (!validZipCodeRegex.test(practiceInfo.ZipCode)) {
      setPracticeInfoError((practiceInfoError) => ({
        ...practiceInfoError,
        ZipCode: "Enter valid Zip Code.",
      }));
      flagError = true;
    }

    if (!flagError) {
      const newData = {
        ...postData,
        Address: {
          LocationName: practiceInfo.LocationName,
          StreetAddress: practiceInfo.LocationName,
          City: practiceInfo.City,
          State: practiceInfo.State,
          ZipCode: practiceInfo.ZipCode,
        },
      };
      setPostData(newData);
      next();
    }
  };

  useEffect(() => {
    stateList &&
      stateList.length > 0 &&
      setPracticeInfo({ ...practiceInfo, State: stateList[0].value });
    stateListError &&
      stateListError.length > 0 &&
      setCommonError("There is some error in getting stateList");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateList, stateListError]);

  return (
    <LRFLayout
      titleText={"Sign up"}
      sideBannerText={"sign in"}
      sideBannerRoute={goToSignin}
      sideBannerPositionCode={1}
      headerLine={"Enter your Practice information"}
    >
      {commonError.length > 0 && <div className="err-message">{commonError}</div>}
      <form>
        <div className="content">
          <Input
            name="LocationName"
            type="text"
            id="LocationName"
            className="form-control light-border"
            placeholder="Street Address*"
            onChange={handleChange}
            value={practiceInfo.LocationName}
            iconBefore={<Icon1 />}
            error={practiceInfoError.LocationName}
          />
          <Input
            name="City"
            type="text"
            id="City"
            className="form-control light-border"
            placeholder="City*"
            onChange={handleChange}
            value={practiceInfo.City}
            iconBefore={<Icon2 />}
            error={practiceInfoError.City}
          />
          <SelectDropdown
            icon={<Icon3 />}
            value={practiceInfo.State}
            handleChange={handleChange}
            options={stateList}
            error={practiceInfoError.State}
            parentClassName={"form-group has-value select-form-group"}
            className={"form-control light-border"}
            name={"State"}
          />
          <Input
            name="ZipCode"
            type="text"
            className="form-control light-border"
            id="zipcode"
            value={practiceInfo.ZipCode}
            onChange={handleChange}
            placeholder="Zip Code*"
            iconBefore={<Icon4 />}
            error={practiceInfoError.ZipCode}
          />
        </div>
        <BlockButton onClick={handleSubmit} text={"next"} />
        <BackButton onClick={() => previousPage()} />
      </form>
    </LRFLayout>
  );
};
export default SignUpPage2;
