import axios from "axios";
import { API_URL } from "../config/appConstatnts";

class Api {
  // API for adding new patient
  static addNewPatient = (payLoad) => {
    const HEADER = {
      headers: {
        access_token: localStorage.getItem("token"),
        "Content-Type": "application/json",
        Company_Id: localStorage.getItem("CompanyId"),
      },
    };
    return axios.post(API_URL + "/Patients/AddPatient", payLoad, HEADER);
  };

  //post api for adding or saving new screening
  static AddScreening = (payLoad) => {
    const HEADER = {
      headers: {
        access_token: localStorage.getItem("token"),
        "Content-Type": "application/json",
        Company_Id: localStorage.getItem("CompanyId"),
      },
    };
    return axios.post(API_URL + "/HPV/AddScreening", payLoad, HEADER);
  };

  //delete api for deleting the screening
  static DeleteScreening = (id) => {
    const HEADER = {
      headers: {
        access_token: localStorage.getItem("token"),
        "Content-Type": "application/json",
        Company_Id: localStorage.getItem("CompanyId"),
      },
    };
    return axios.delete(API_URL + "/HPV/DeleteScreening?Id=" + id , HEADER);
  };

  //update api for updating the last screening date
  static UpdateScreening = (id, date) => {
    const HEADER = {
      headers: {
        access_token: localStorage.getItem("token"),
        "Content-Type": "application/json",
        Company_Id: localStorage.getItem("CompanyId"),
      },
    };
    return axios.delete(API_URL + "/HPV/UpdateScreening?Id=" + id + "&date=" + date, HEADER);
  };

}
export default Api;
