import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Button,
  Row,
} from "reactstrap";
import Select2 from "react-select2-wrapper";

import { ReactComponent as Expand } from "../assets/images/expand.svg";
import Search from "../assets/images/search.svg";
import ApexCharts from "../CommonComponents/ApexCharts";

import { FullScreen, useFullScreenHandle } from "react-full-screen";
import AppNavbar from "../CommonComponents/Navbars/AppNavbar";
import Footer from "../CommonComponents/Footers/Footer";
import { useHistory } from "react-router-dom";
import { handleOnScreenBtnClick, redirectToAddpatient } from "../config/CommonFunctions";
import { CardSection } from "../CommonComponents/Cards/DashboardCards";
import PatientList from "../CommonComponents/PatientList";
import CommonApi from "../API/CommonApiCalls";
import Select from "react-select";
import AppPageLoader from "../CommonComponents/PageLoader/AppPageLoader";
import Api from "../API/reports";
import { RecordLincURL } from "../config/appConstatnts";

const Reports = () => {
  let history = useHistory();
  const columns = [
    {
      name: "Action",
      selector: (row) => row.action,
      cell: (row) => (
        <Button
          color="primary"
          style={{ borderRadius: "12px" }}
          outline
          onClick={(e) => handleOnScreenbtnClick(row, e)}
        >
          Screen
        </Button>
      ),
      sortable: false,
    },
    {
      name: "First Name",
      selector: (row) => row.firstName,
      sortable: true,
    },
    {
      name: "Last Name",
      selector: (row) => row.lastName,
      sortable: true,
    },
    {
      name: "DOB",
      selector: (row) => row.dob,
      sortable: true,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row.locationName,
      sortable: true,
    },
    {
      name: "Screened",
      selector: (row) => row.screened,
      // sortable: true,
    },
    {
      name: "Screened By",
      selector: (row) => row.screenedBy,
      sortable: true,
    },
    {
      name: "Last Screening",
      selector: (row) => row.lastScreenDate,
      sortable: true,
    },
    {
      name: "Vaccinated",
      selector: (row) => row.vaccinated,
      // sortable: true,
    },
  ];
  const initialPatientListPayload = {
    PageIndex: 1,
    PageSize: 10,
    SearchBy: "",
    LocationId: 0,
    SortBy: 1,
    SortDirection: "ASC",
    AppointmentDate: {
      from: null,
      to: null,
    },
    ScreenedDate: {
      from: null,
      to: null,
    },
    IsTodaysAppt: false,
    ChartType: 3,
  };

  const [patientListPayload, setPatientListPayload] = useState(initialPatientListPayload);
  const [dashboardCardPayLoad, setDashboardCardPayLoad] = useState({
    AccountId:
      localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")).AccountId,
    LocationId: 0,
    ChartType: 3,
    StartDate: null,
    EndDate: null,
  });
  const [customeDatePicker, setCustomeDatePicker] = useState({ StartDate: null, EndDate: null });
  const [showCustomeDatePicker, setShowCustomeDatePicker] = useState(false);
  const [pageInfo, setPageInfo] = useState(initialPatientListPayload);
  const [customeDatePickerError, setCustomeDatePickerError] = useState("");
  const [locationUserList, setLocationUserList] = useState({
    locationList: [{ value: 0, label: "Select Location" }],
    usersList: [{ value: 0, label: "Select Doctor", locationId: 0 }],
  });
  const [commonApiError, setCommonApiError] = useState("");
  const [appPageLoader, setAppPageLoader] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [downloadPatientFileError, setDownloadPatientFileError] = useState("");
  const [showExportBtn, setShowExportBtn] = useState(true);

  const handle = useFullScreenHandle();
  // ====================== handle change =================
  const changePageInfo = (pageData) => {
    setPageInfo(pageData);
  };

  const handleOnScreenbtnClick = (row, event) => {
    handleOnScreenBtnClick(row.patientId, history.location.pathname, pageInfo);
  };
  const handleChangeChartType = (chartTypeId) => {
    if (chartTypeId === "8") {
      setShowCustomeDatePicker(true);
      // setPatientListPayload({ ...patientListPayload, ChartType: chartTypeId });
    } else {
      setShowCustomeDatePicker(false);
      setDashboardCardPayLoad({
        ...dashboardCardPayLoad,
        ChartType: chartTypeId,
        StartDate: null,
        EndDate: null,
      });
      setPatientListPayload({ ...patientListPayload, ChartType: chartTypeId });
    }
  };
  const handleDateChange = (event) => {
    const { name, value } = event.target;
    setCustomeDatePicker({ ...customeDatePicker, [name]: value });
  };
  const handelApplyDateFilter = () => {
    if (customeDatePicker.StartDate && customeDatePicker.EndDate) {
      let isError = false;
      if (customeDatePicker.StartDate > customeDatePicker.EndDate) {
        isError = true;
        setCustomeDatePickerError("Please Select apropriate date");
      }
      if (isError === false) {
        setDashboardCardPayLoad({ ...dashboardCardPayLoad, ChartType: null, ...customeDatePicker });
        setPatientListPayload({
          ...patientListPayload,
          ChartType: null,
          ScreenedDate: {
            from: customeDatePicker.StartDate,
            to: customeDatePicker.EndDate,
          },
        });
        setCustomeDatePickerError("");
      }
    } else {
      setCustomeDatePickerError("Please Select apropriate date");
    }
  };
  const handleChangeDashboardCardLocation = (event, name) => {
    setDashboardCardPayLoad({ ...dashboardCardPayLoad, LocationId: event.value });
    setPatientListPayload({ ...patientListPayload, LocationId: event.value });
  };
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };
  const handleSubmitSearch = () => {
    const payLoad = { ...patientListPayload, SearchBy: searchInput };
    setPatientListPayload(payLoad);
  };
  const handleExportPatientData = () => {
    const payLoad = {
      LocationId: patientListPayload.LocationId,
      AppointmentDate: {
        FromDate: patientListPayload.AppointmentDate.from,
        ToDate: patientListPayload.AppointmentDate.to,
      },
      ScreenedDate: {
        FromDate: patientListPayload.ScreenedDate.from,
        ToDate: patientListPayload.ScreenedDate.to,
      },
      IsTodaysAppt: false,
      ChartType: patientListPayload.ChartType,
      PageIndex: 1,
      PageSize: totalRecords,
      SortBy: patientListPayload.SortBy,
      SortDirection: patientListPayload.SortDirection,
    };
    setAppPageLoader(true);
    Api.ExportPatientData(payLoad)
      .then((resp) => {
        if (resp.data.StatusCode === 200) {
          window.location.href = RecordLincURL + "IntegrationExcelFiles\\" + resp.data.Result;
          setDownloadPatientFileError("");
        }
      })
      .catch((err) => {
        setDownloadPatientFileError(err?.response?.data?.Message ?? "Something went wrong");
      })
      .finally(() => setAppPageLoader(false));
  };

  // ==========get api call ====================
  const getUserLocationApi = () => {
    setAppPageLoader(true);
    CommonApi.getLocationDoctorList()
      .then((resp) => {
        let locationList = [
          ...locationUserList.locationList,
          ...resp.data.Result.Location.map(({ Id, Name }) => ({
            value: Id,
            label: Name,
          })),
        ];

        let usersList = [
          ...locationUserList.usersList,
          ...resp.data.Result.User.map(({ Id, Name, LocationId }) => ({
            value: Id,
            label: Name,
            locationId: LocationId,
          })),
        ];
        setLocationUserList({
          locationList,
          usersList,
        });
      })
      .catch((err) => {
        setCommonApiError(err?.response?.data.Message);
      })
      .finally(() => setAppPageLoader(false));
  };

  useEffect(() => {
    getUserLocationApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AppNavbar />
      <div className="main-container">
        <Container fluid>
          {/* <h2>dental practice Reports</h2> */}
          <div className="screening-count-main">
            <Card>
              <CardTitle className="dashboard-head card-body">
                <div className="mb-0 d-flex flex-wrap justify-content-between" id="sub-header">
                  {locationUserList.locationList.length > 2 && (
                    <div className="screen-by mt-0">
                      {commonApiError && (
                        <div className="text-center text-danger ">{commonApiError}</div>
                      )}
                      <div className={`location-content`} style={{ zIndex: 1001 }}>
                        {appPageLoader && <AppPageLoader />}
                        <label className="">{"Location by "}</label>
                        <Select
                          className={"style-select react-select dayfilterSelect "}
                          style={{ width: "150px" }}
                          defaultValue={locationUserList.locationList[0]}
                          options={locationUserList.locationList}
                          isClearable={false}
                          classNamePrefix="select"
                          onChange={(event) =>
                            handleChangeDashboardCardLocation(event, "searchLocationBy")
                          }
                        />
                      </div>
                    </div>
                  )}
                  <div className="d-flex py-4 align-items-center flex-wrap">
                    <button
                      className={`${dashboardCardPayLoad.ChartType === 0 ? "btn active" : "btn"}`}
                      type="button"
                      onClick={() => handleChangeChartType(0)}
                    >
                      Today
                    </button>
                    <button
                      className={`${dashboardCardPayLoad.ChartType === 2 ? "btn active" : "btn"}`}
                      type="button"
                      onClick={() => handleChangeChartType(2)}
                    >
                      This Week
                    </button>
                    <button
                      className={`${dashboardCardPayLoad.ChartType === 3 ? "btn active" : "btn"}`}
                      type="button"
                      onClick={() => handleChangeChartType(3)}
                    >
                      This Month
                    </button>
                    <button
                      className={`${dashboardCardPayLoad.ChartType === 4 ? "btn active" : "btn"}`}
                      type="button"
                      onClick={() => handleChangeChartType(4)}
                    >
                      This Year
                    </button>
                    <div
                      className={`${
                        dashboardCardPayLoad.ChartType === 1 ||
                        dashboardCardPayLoad.ChartType === 5 ||
                        dashboardCardPayLoad.ChartType === 6 ||
                        dashboardCardPayLoad.ChartType === 7 ||
                        dashboardCardPayLoad.ChartType === null ||
                        dashboardCardPayLoad.ChartType === 8
                          ? "active"
                          : "btn"
                      } style-select dayfilterSelect`}
                    >
                      <Select2
                        onSelect={(e) => handleChangeChartType(e.target.value)}
                        defaultValue={dashboardCardPayLoad.ChartType}
                        data={[
                          { text: "Custom Date", id: 8 },
                          { text: "Yesterday", id: 1 },
                          { text: "Last Week", id: 5 },
                          { text: "Last Month", id: 6 },
                          { text: "Last Year", id: 7 },
                        ]}
                        options={{
                          placeholder: "Select",
                        }}
                      />
                    </div>
                  </div>
                </div>

                {showCustomeDatePicker && (
                  <>
                    <div className="d-flex my-2 py-2 justify-content-center align-items-end border-top flex-wrap date-filter">
                      <div className="filter-ctrl">
                        <label>From Date</label>
                        <input
                          type="date"
                          className="form-control date-select"
                          name="StartDate"
                          max={new Date().toISOString().slice(0, 10)}
                          onChange={handleDateChange}
                        />
                      </div>

                      <div className="filter-ctrl">
                        <label>To Date</label>
                        <input
                          type="date"
                          className="form-control date-select"
                          name="EndDate"
                          max={new Date().toISOString().slice(0, 10)}
                          onChange={handleDateChange}
                        />
                      </div>
                      <button
                        type="button"
                        className="btn btn-sm blue-btn-bg green-btn green-border "
                        onClick={handelApplyDateFilter}
                        disabled={
                          customeDatePicker.StartDate?.length === 0 ||
                          customeDatePicker.EndDate?.length === 0
                        }
                      >
                        Apply
                      </button>
                    </div>
                    <div className="text-center text-danger">
                      <h6>{customeDatePickerError}</h6>
                    </div>
                  </>
                )}
              </CardTitle>
              <CardBody>
                <Row>
                  <CardSection payLoad={dashboardCardPayLoad} pageType={"reports"} />
                  <Col xl="7">
                    <Card className="border-0 mb-3">
                      <FullScreen handle={handle}>
                        <CardBody>
                          <CardTitle className="text-center mb-5">
                            {/* HPV Screened & Vaccinated Report{" "} */}
                            <Expand onClick={handle.enter} className="cursor-pointer float-right" />
                          </CardTitle>
                          <ApexCharts payLoad={dashboardCardPayLoad} pageType={"reports"} />
                        </CardBody>
                      </FullScreen>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>

          <Card className="transparant reports-patient-head border-0 mb-3">
            <CardBody className="p-0">
              <div className="d-flex justify-content-between flex-wrap mt-5 select-patients">
                <h2 className="m-0">Patient List</h2>
                <div className="main-search">
                  <FormGroup>
                    <img src={Search} alt="serach" onClick={() => handleSubmitSearch()} />
                    <Input
                      type="text"
                      name="SearchBy"
                      id="SearchBy"
                      placeholder="Search Patients"
                      value={searchInput}
                      onChange={handleSearchInputChange}
                    />
                  </FormGroup>
                </div>
                <div className="d-flex flex-wrap">
                  <Button className="" color="primary" outline onClick={redirectToAddpatient}>
                    + Add Patient
                  </Button>
                  {showExportBtn && (
                    <Button
                      className=" ml-0 ml-md-2 px-5"
                      color="danger"
                      outline
                      onClick={handleExportPatientData}
                    >
                      Export
                    </Button>
                  )}
                </div>
              </div>
              {downloadPatientFileError.length > 0 && (
                <h6 className="text-center text-danger mt-3 mb-0"> {downloadPatientFileError}</h6>
              )}
            </CardBody>
          </Card>
          {appPageLoader && <AppPageLoader className={"position-fixed"} />}

          <PatientList
            columns={columns}
            showRecords={true}
            patientListPayloadFromParent={patientListPayload}
            setPatientListPayloadParent={setPatientListPayload}
            setPageInfo={changePageInfo}
            setTotalRecordsParent={setTotalRecords}
            setShowExportBtn={setShowExportBtn}
          />
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Reports;
