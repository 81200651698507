import React, { useState } from "react";
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";
import LoginNavbar from "../../CommonComponents/Navbars/LoginNavbar";
import Search from "../../assets/images/search.svg";
import Footer from "../../CommonComponents/Footers/Footer";
import { FormGroup, Input, Row } from "reactstrap";
import WhoYouAreCard from "../SectionCommonComponent/WhoYouAreCard";
import Api from "../../API/login";
import { goToSignin } from "../../config/CommonFunctions";
import AppPageLoader from "../../CommonComponents/PageLoader/AppPageLoader";
import NoRecordFound from "../../CommonComponents/NoRecordFound";

const SelectPerson = () => {
  const [usersList, setUsersList] = useState([]);
  const [searchData, setSearchData] = useState("");
  const [searchDataError, setSearchDataError] = useState("");
  const [sectionLoader, setSectionLoader] = useState(false);

  const getUsersList = (searchData) => {
    const payLoad = {
      Keywords: searchData,
      ProviderfilterType: 0,
    };
    setSectionLoader(true);
    Api.selectWhoYouAre(payLoad)
      .then((resp) => {
        setSectionLoader(false);
        setUsersList(resp.data);
      })
      .catch((err) => {
        setSectionLoader(false);
        setSearchDataError("Something went wrong");
      });
  };

  const handleChange = (event) => {
    setSearchData(event.target.value);
    if (event.target.value.trim().length > 0) setSearchDataError("");
    if (event.target.value.trim().length > 3) getUsersList(event.target.value);
  };

  const handleSubmit = () => {
    if (searchData.trim().length === 0) {
      setSearchDataError("Please Enter value to search");
      return;
    }
    setSearchDataError("");
    getUsersList(searchData);
  };

  const handleCardClick = (email) => {
    const payLoad = {
      email,
    };
    goToSignin(payLoad);
  };

  return (
    <div>
      {sectionLoader && <AppPageLoader />}
      <LoginNavbar />
      <div className="main-container select-person-block">
        <div className="custom-container">
          <div className="select-id">
            <h1>Select Who You Are</h1>
            <div className="main-search">
              <FormGroup>
                <img src={Search} className="btn p-0" alt="search" onClick={handleSubmit} />
                <Input
                  type="text"
                  name="text"
                  id="search"
                  value={searchData}
                  onChange={handleChange}
                  placeholder="Search for your profile here and select when found"
                />
                <div className="err-message">{searchDataError}</div>
              </FormGroup>
            </div>
            <div className="detials-section">
              <Row>
                {usersList.length > 0 &&
                  usersList.map((value) => (
                    <WhoYouAreCard
                      key={value.UserId}
                      email={value.Email}
                      UserImg={value.Image}
                      name={value.FirstName}
                      profession={value.AccountName}
                      handleCardClick={handleCardClick}
                    />
                  ))}

                {searchData.length > 0 && usersList.length === 0 && (
                  <NoRecordFound
                    className={"w-100"}
                    text={"There is no account having this user name."}
                  />
                )}
              </Row>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SelectPerson;
