import React, { useState } from "react";
import { ReactComponent as Icon1 } from "../../assets/images/user.svg";
import { ReactComponent as Icon2 } from "../../assets/images/envelope.svg";
import { ReactComponent as Icon3 } from "../../assets/images/phone-call.svg";
import { ReactComponent as Icon4 } from "../../assets/images/padlock.svg";
import { goToSignin } from "../../config/CommonFunctions";

import {
  validCharRegex,
  validEmailRegex,
  validPasswordRegex,
  validPhoneRegex,
} from "../../config/appRegex";

import Api from "../../API/signup";
import LRFLayout from "../SectionCommonComponent/LRFLayout";
import Input from "../../CommonComponents/Input/Input";
import AppPageLoader from "../../CommonComponents/PageLoader/AppPageLoader";
import BlockButton from "../SectionCommonComponent/BlockButton";

const SignUnPage1 = (props) => {
  const { next, postData, setPostData } = props;

  const [eyeTog2, seteyeTog2] = useState("false");
  const [eyeTog3, seteyeTog3] = useState("false");

  const defaulteUser = {
    FirstName: "",
    LastName: "",
    Email: "",
    Phone: "",
    Password: "",
    ConPassword: "",
  };

  const [userModel, setUserModel] = useState(postData);
  const [userModelErrors, setUserModelErrors] = useState(defaulteUser);
  const [passwordType, setPasswordType] = useState("password");
  const [cpasswordType, setCPasswordType] = useState("password");

  const [sectionLoader, setSectionLoader] = useState(false);

  const changePasswordType = () => {
    passwordType === "password" ? setPasswordType("text") : setPasswordType("password");
    seteyeTog2(!eyeTog2);
  };
  const changeConPasswordType = () => {
    cpasswordType === "password" ? setCPasswordType("text") : setCPasswordType("password");
    seteyeTog3(!eyeTog3);
  };

  const handleChange = (event) => {
    event.preventDefault();
    let { name, value } = event.target;
    setUserModel({ ...userModel, [name]: value });
    let errorValue = "";
    switch (name) {
      case "FirstName":
        if (value.length === 0) {
          errorValue = "First Name is required.";
        } else {
          errorValue = validCharRegex.test(value) ? "" : "Enter only characters.";
        }
        break;
      case "LastName":
        if (value.length === 0) {
          errorValue = "Last Name is required.";
        } else {
          errorValue = validCharRegex.test(value) ? "" : "Enter only characters.";
        }
        break;
      case "Email":
        if (value.length === 0) {
          errorValue = "Email is required.";
        } else {
          errorValue = validEmailRegex.test(value) ? "" : "Enter valid email.";
        }
        break;
      case "Phone":
        if (value.length === 0) {
          errorValue = "Phone number is required.";
        } else {
          errorValue = validPhoneRegex.test(value) ? "" : "Enter valid phone number.";
        }
        break;
      case "Password":
        if (value.length === 0) {
          errorValue = "Password is required.";
        } else {
          errorValue = validPasswordRegex.test(value) ? "" : "Enter valid Password.";
        }
        break;
      case "ConPassword":
        if (value.length === 0) {
          errorValue = "Password is required.";
        } else {
          errorValue = userModel.Password === value ? "" : "Password don't match.";
        }
        break;
      default:
        break;
    }
    setUserModelErrors((userModelErrors) => ({
      ...userModelErrors,
      [name]: errorValue,
    }));
  };

  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const handleSubmit = () => {
    let flagError = false;
    if (userModel.FirstName.length === 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        FirstName: "FirstName is required.",
      }));
      flagError = true;
    }
    if (userModel.LastName.length === 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        LastName: "Last Name is required.",
      }));
      flagError = true;
    }
    if (userModel.Email.length === 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        Email: "Email is required.",
      }));
      flagError = true;
    }
    if (userModel.Phone.length === 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        Phone: "Phone is required.",
      }));
      flagError = true;
    }
    if (userModel.Password.length === 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        Password: "Password is required.",
      }));
      flagError = true;
    }
    if (userModel.ConPassword.length === 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        ConPassword: "Password is required.",
      }));
      flagError = true;
    }

    if (!flagError) {
      setSectionLoader(true);

      Api.getCheckEmail(userModel.Email)
        .then((res) => {
          setSectionLoader(false);
          if (res.data.Result !== -1) {
            setUserModelErrors({ ...userModelErrors, Email: res.data.Message });
          } else {
            setPostData(userModel);
            next();
          }
        })
        .catch((error) => {
          setSectionLoader(false);
          setApiErrorMessage(JSON.parse(error.request.response).Message);
        });
    }
  };

  return (
    <>
      {sectionLoader && <AppPageLoader className={"position-fixed"} />}
      <LRFLayout
        titleText={"Sign up"}
        sideBannerText={"sign in"}
        sideBannerRoute={goToSignin}
        sideBannerPositionCode={1}
        headerLine={"Enter your personal information"}
      >
        {apiErrorMessage.length > 0 && <div className="err-message">{apiErrorMessage}</div>}
        <form>
          <div className="content">
            <Input
              name={"FirstName"}
              type={"text"}
              value={userModel.FirstName}
              onChange={handleChange}
              className={"form-control mb-0 light-border userid-filed"}
              id="firstName"
              placeholder="First Name*"
              iconBefore={<Icon1 />}
              error={userModelErrors.FirstName}
              maxLength="20"
            />
            <Input
              name={"LastName"}
              type={"text"}
              value={userModel.LastName}
              onChange={handleChange}
              className={"form-control light-border"}
              id="lastName"
              placeholder="Last Name*"
              iconBefore={<Icon1 />}
              error={userModelErrors.LastName}
              maxLength="20"
            />
            <Input
              name={"Email"}
              type={"text"}
              value={userModel.Email}
              onChange={handleChange}
              className={"form-control light-border"}
              id={"email"}
              placeholder={"Email Address*"}
              iconBefore={<Icon2 />}
              error={userModelErrors.Email}
              maxLength={"100"}
            />
            <Input
              name={"Phone"}
              type={"text"}
              value={userModel.Phone}
              onChange={handleChange}
              className={"form-control light-border"}
              id={"phoneNumber"}
              placeholder={"Phone Number*"}
              iconBefore={<Icon3 />}
              error={userModelErrors.Phone}
            />
            <Input
              name={"Password"}
              type={passwordType}
              value={userModel.Password}
              onChange={handleChange}
              className={"form-control light-border pass-input"}
              id={"password"}
              placeholder={"Password*"}
              error={userModelErrors.Password}
              iconBefore={<Icon4 />}
              iconAfter={
                <i
                  className={`passTogEye icon-eye ${!eyeTog2 ? "passVisible" : ""}`}
                  onClick={changePasswordType}
                ></i>
              }
              maxLength={"50"}
            />
            <Input
              name={"ConPassword"}
              type={cpasswordType}
              value={userModel.ConPassword}
              onChange={handleChange}
              className={"form-control light-border pass-input"}
              id={"conPassword"}
              placeholder={"Confirm Password*"}
              iconBefore={<Icon4 />}
              iconAfter={
                <i
                  className={`passTogEye icon-eye ${!eyeTog3 ? "passVisible" : ""}`}
                  onClick={changeConPasswordType}
                ></i>
              }
              error={userModelErrors.ConPassword}
              maxLength={"50"}
            />
          </div>
          <BlockButton onClick={handleSubmit} text={"next"} />
        </form>
      </LRFLayout>
    </>
  );
};
export default SignUnPage1;
