// Author https://github.com/yegor-sytnyk/movies-list
import React from "react";
import { ReactComponent as Check } from "../../assets/images/close-badge.svg";
import { ReactComponent as CheckGreen } from "../../assets/images/tick-badge.svg";
import { UncontrolledTooltip } from "reactstrap";

const Data = [
  {
    id: 1,
    firstName: "",
    lastName: "",
    dob: "",
    gender: "",
    patientId: "",
    screened: null,
    lastScreenDate: null,
    toggleSelected: false,
  },
];

export default Data;
