import React from "react";

const AppPageLoader = ({ className }) => {
  return (
    <div
      className={`loader-wrapper align-items-center justify-content-center ${className}`}
      id="appLoadingMain"
    >
      <div id="loading-bar-spinner" className="spinner">
        <div className="spinner-icon"></div>
      </div>
    </div>
  );
};
export default AppPageLoader;
