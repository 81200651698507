import React, { useState } from "react";
import SignUpPage1 from "../SignUpPage/SignUpPage1";
import SignUpPage2 from "../SignUpPage/SignUpPage2";
import SignUpPage3 from "../SignUpPage/SignUpPage3";
import ThankYouPage from "./ThankYouPage";
const SignUnPage = () => {
  const defaulteUser = {
    FirstName: "",
    LastName: "",
    Email: "",
    Phone: "",
    Password: "",
    ConPassword: "",
    Address: {
      LocationName: "",
      StreetAddress: "",
      City: "",
      State: "",
      ZipCode: "",
    },
    PracticeName: "",
    Speciality: "",
    OtherPraticeManagement: "",
  };

  const [page, setPage] = useState(1);

  const nextPage = () => setPage(page + 1);
  const previousPage = () => setPage(page - 1);

  const postDataChange = (data) => {
    setUserModel({ ...userModel, ...data });
  };

  const [userModel, setUserModel] = useState(defaulteUser);

  return (
    <>
      {page === 1 && (
        <SignUpPage1 next={nextPage} postData={userModel} setPostData={postDataChange} />
      )}

      {page === 2 && (
        <SignUpPage2
          next={nextPage}
          previousPage={previousPage}
          postData={userModel}
          setPostData={postDataChange}
        />
      )}
      {page === 3 && (
        <SignUpPage3
          previousPage={previousPage}
          postData={userModel}
          setPostData={setUserModel}
          next={nextPage}
        />
      )}
      {page === 4 && <ThankYouPage />}
    </>
  );
};
export default SignUnPage;
