import React from "react";

const NoRecordFound = ({ children, className, text }) => {
  return (
    <div
      className={`card text-center py-5 ${className}`}
      style={{ backgroundColor: "rgb(244, 139, 37, .2)" }}
    >
      <h3 className="m-0 p-0" style={{ textTransform: "none" }}>
        {text}
      </h3>
      {children}
    </div>
  );
};

export default NoRecordFound;
