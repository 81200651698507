import React from "react";
import Select from "react-select";

const LabelSelect = React.memo(
  ({ parentClassName, className, label, defaultValue, options, error, ...props }) => {
    return (
      <div className={`screen-by ${parentClassName}`} style={{ zIndex: 1001 }}>
        <label>{label}</label>
        <Select
          className={className}
          defaultValue={defaultValue}
          options={options}
          isClearable={false}
          classNamePrefix="select"
          {...props}
        />
        <div className="err-message">{error}</div>
      </div>
    );
  }
);

export default LabelSelect;
