import axios from "axios";
import { API_URL, HEADER } from "../config/appConstatnts";

let from = "TestUser";

class Api {
  // API for getting company Details
  static getCompanyDetail = (url) => {
    return axios.get(API_URL + "/company/get?domain=" + url);
  };

  // API for get getCompanyId
  static getCompanyId = (url) => {
    return axios.get(API_URL + "/Verident/CompanyId?strDomain=" + url);
  };

  // API for user Login
  static logIn = (payload) => {
    let UserModel = {
      Username: payload.Username,
      Password: payload.Password,
      CompanyId: payload.CompanyId,
    };
    return axios.post(API_URL + "/Verident/Login", UserModel);
  };

  // API for forgot password
  static forgotPassword = (payload) => {
    return axios.post(
      API_URL + "/Verident/ForgotPassowrd?Email=" + payload + "&From=" + from,
      "",
      HEADER
    );
  };

  // API for Reset password
  static resetPassword = (payload) => {
    let model = {
      Email: payload.Email,
      Password: payload.Password,
    };
    return axios.post(API_URL + "/Verident/ResetPassword", model, HEADER);
  };

  // Account activation API
  static accountActivation = (email) => {
    return axios.get(API_URL + "/Verident/AccountActivation?Email=" + email, "");
  };

  static authentication = (data, header) => {
    return axios.post(API_URL + "/SingleSignOn/Authentication", data, header);
  };

  // Select Who You Are
  static selectWhoYouAre = (data, header) => {
    return axios.post(API_URL + "/OneClickReferral/GetSpecificSearch", data, header);
  };
}
export default Api;
