import React, { useEffect, useState } from "react";
import AppNavbar from "../CommonComponents/Navbars/AppNavbar";
import Select from "react-select";
//import Search from '../assets/images/search.svg'
import { Button, Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import Select2 from "react-select2-wrapper";

import { ReactComponent as Expand } from "../assets/images/expand.svg";

import Footer from "../CommonComponents/Footers/Footer";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ApexCharts from "../CommonComponents/ApexCharts";
import { CardSection } from "../CommonComponents/Cards/DashboardCards";
import AppPageLoader from "../CommonComponents/PageLoader/AppPageLoader";
import Api from "../API/stateDashboard";
import DentalPracticeList from "../CommonComponents/DentalPracticeList/DentalPracticeList";
import { RecordLincURL } from "../config/appConstatnts";

// RDT exposes the following internal pagination properties
const StateDashboard = () => {
  const handle = useFullScreenHandle();

  const initialDentalPracticeListPayload = {
    AccountId: 0,
    StateId: 0,
    PageIndex: 1,
    PageSize: 10,
    SortBy: 1,
    SortDirection: "ASC",
    ChartType: 3,
  };
  const [AccountStateList, setAccountStateList] = useState({
    AccountList: [{ value: 0, label: "All" }],
    StateList: [{ value: 0, label: "All" }],
  });
  const [dentalPracticeListPayLoad, setDentalPracticeListPayLoad] = useState(
    initialDentalPracticeListPayload
  );
  const [dashboardCardPayLoad, setDashboardCardPayLoad] = useState({
    ChartType: 3,
    StartDate: null,
    EndDate: null,
    AccountListId: AccountStateList.AccountList[0],
    StateListId: AccountStateList.StateList[0],
  });
  const [customeDatePicker, setCustomeDatePicker] = useState({ StartDate: null, EndDate: null });
  const [showCustomeDatePicker, setShowCustomeDatePicker] = useState(false);
  const [customeDatePickerError, setCustomeDatePickerError] = useState("");
  const [commonApiError, setCommonApiError] = useState("");
  const [appPageLoader, setAppPageLoader] = useState(false);

  // ===============handle change event======================

  const handleChangeChartType = (chartTypeId) => {
    if (chartTypeId === "8") {
      setShowCustomeDatePicker(true);
      // setDentalPracticeListPayLoad({ ...dentalPracticeListPayLoad, ChartType: chartTypeId });
    } else {
      setShowCustomeDatePicker(false);
      setDashboardCardPayLoad({
        ...dashboardCardPayLoad,
        ChartType: chartTypeId,
        StartDate: null,
        EndDate: null,
      });
      setDentalPracticeListPayLoad({ ...dentalPracticeListPayLoad, ChartType: chartTypeId });
    }
  };
  const handleDateChange = (event) => {
    const { name, value } = event.target;
    setCustomeDatePicker({ ...customeDatePicker, [name]: value });
  };
  const handelApplyDateFilter = () => {
    if (customeDatePicker.StartDate && customeDatePicker.EndDate) {
      let isError = false;
      if (customeDatePicker.StartDate > customeDatePicker.EndDate) {
        isError = true;
        setCustomeDatePickerError("Please Select apropriate date");
      }
      if (isError === false) {
        setDashboardCardPayLoad({ ...dashboardCardPayLoad, ChartType: null, ...customeDatePicker });
        setDentalPracticeListPayLoad({
          ...dentalPracticeListPayLoad,
          ChartType: null,
          ...customeDatePicker,
        });
        setCustomeDatePickerError("");
      }
    } else {
      setCustomeDatePickerError("Please Select apropriate date");
    }
  };
  const handleChangeAccountState = (event, name) => {
    if (name === "StateListId") {
      setDashboardCardPayLoad({
        ...dashboardCardPayLoad,
        [name]: event,
        AccountListId: { label: "All", value: 0 },
      });
      setDentalPracticeListPayLoad({
        ...dentalPracticeListPayLoad,
        StateId: event.value,
        AccountId: 0,
      });
    } else {
      setDashboardCardPayLoad({ ...dashboardCardPayLoad, [name]: event });
      setDentalPracticeListPayLoad({
        ...dentalPracticeListPayLoad,
        AccountId: event.value,
      });
    }
  };

  const [downloadStateDashboardDataError, setDownloadStateDashboardDataError] = useState("");
  const handleExportDentalPracticeData = () => {
    const apiPayLoad = {
      AccountId: dentalPracticeListPayLoad.AccountId,
      StateId: dentalPracticeListPayLoad.StateId === 0 ? null : dentalPracticeListPayLoad.StateId,
      ChartType: dentalPracticeListPayLoad.ChartType,
      StartDate: dentalPracticeListPayLoad.StartDate ?? null,
      EndDate: dentalPracticeListPayLoad.EndDate ?? null,
      SortDirection: "ASC",
      PageIndex: 1,
    };
    setAppPageLoader(true);
    Api.exportDentalPracticeData(apiPayLoad)
      .then((resp) => {
        if (resp.data.StatusCode === 200) {
          window.location.href = RecordLincURL + "IntegrationExcelFiles\\" + resp.data.Result;
        }
        setDownloadStateDashboardDataError("");
      })
      .catch((err) => {
        setDownloadStateDashboardDataError(err?.response?.data.Message ?? "Something went wrong!");
      })
      .finally(() => setAppPageLoader(false));
  };

  // ==========get api call ====================

  const getAccountStateList = () => {
    setAppPageLoader(true);
    Api.getStateAccountList()
      .then((resp) => {
        if (resp.data.Result) {
          const accountStateList = resp.data.Result;
          setAccountStateList({
            AccountList: [
              ...AccountStateList.AccountList,
              ...accountStateList.Accounts.map((AccountList) => ({
                value: AccountList.Id,
                label: AccountList.Name,
                stateId: AccountList.StateCode,
              })),
            ],
            StateList: [
              ...AccountStateList.StateList,
              ...accountStateList.States.map((StateList) => ({
                value: StateList.StateCode,
                label: StateList.StateName,
              })),
            ],
          });
        }
      })
      .catch((err) => {
        setCommonApiError(err?.response?.data.Message ?? "Something went wrong");
      })
      .finally(() => setAppPageLoader(false));
  };

  useEffect(() => {
    getAccountStateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AppNavbar pageType={"stateDashboard"} />
      {appPageLoader && <AppPageLoader className={"position-fixed"} />}
      <div className="main-container">
        <Container fluid>
          <div className="screening-count-main">
            <Card>
              <CardTitle className="dashboard-head">
                <div className="mb-0 d-flex justify-content-between" id="sub-header">
                  <div className="d-flex flex-column">
                    {commonApiError && (
                      <div className="text-center text-danger">{commonApiError}</div>
                    )}
                    <div className="d-flex justify-content-between">
                      <div
                        className={`d-flex flex-column justify-content-start`}
                        style={{ zIndex: 1001, rowGap: "1px" }}
                      >
                        <label className="my-auto">{"State "}</label>
                        <Select
                          className={"style-select react-select dayfilterSelect mr-2"}
                          style={{ width: "150px" }}
                          defaultValue={AccountStateList.StateList[0]}
                          value={dashboardCardPayLoad.StateListId}
                          options={AccountStateList.StateList}
                          isClearable={false}
                          classNamePrefix="select"
                          onChange={(event) => handleChangeAccountState(event, "StateListId")}
                        />
                      </div>
                      <div
                        className={`d-flex flex-column justify-content-start`}
                        style={{ zIndex: 1001, rowGap: "1px" }}
                      >
                        <label className="my-auto">{"Account"}</label>
                        <Select
                          className={"style-select react-select dayfilterSelect mr-2"}
                          style={{ width: "150px" }}
                          defaultValue={AccountStateList.AccountList[0]}
                          value={dashboardCardPayLoad.AccountListId}
                          isDisabled={dashboardCardPayLoad.StateListId.value === 0}
                          options={[
                            { value: 0, label: "All", stateId: 0 },
                            ...AccountStateList.AccountList.filter(
                              (v) => v.stateId === dashboardCardPayLoad.StateListId.value
                            ),
                          ]}
                          isClearable={false}
                          classNamePrefix="select"
                          onChange={(event) => handleChangeAccountState(event, "AccountListId")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex py-4 align-items-center flex-wrap">
                    <button
                      className={`${dashboardCardPayLoad.ChartType === 0 ? "btn active" : "btn"}`}
                      type="button"
                      onClick={() => handleChangeChartType(0)}
                    >
                      Today
                    </button>
                    <button
                      className={`${dashboardCardPayLoad.ChartType === 2 ? "btn active" : "btn"}`}
                      type="button"
                      onClick={() => handleChangeChartType(2)}
                    >
                      This Week
                    </button>
                    <button
                      className={`${dashboardCardPayLoad.ChartType === 3 ? "btn active" : "btn"}`}
                      type="button"
                      onClick={() => handleChangeChartType(3)}
                    >
                      This Month
                    </button>
                    <button
                      className={`${dashboardCardPayLoad.ChartType === 4 ? "btn active" : "btn"}`}
                      type="button"
                      onClick={() => handleChangeChartType(4)}
                    >
                      This Year
                    </button>
                    <div
                      className={`${
                        dashboardCardPayLoad.ChartType === 1 ||
                        dashboardCardPayLoad.ChartType === 5 ||
                        dashboardCardPayLoad.ChartType === 6 ||
                        dashboardCardPayLoad.ChartType === 7 ||
                        dashboardCardPayLoad.ChartType === null ||
                        dashboardCardPayLoad.ChartType === 8
                          ? "active"
                          : "btn"
                      } style-select dayfilterSelect`}
                    >
                      <Select2
                        onSelect={(e) => handleChangeChartType(e.target.value)}
                        defaultValue={dashboardCardPayLoad.ChartType}
                        data={[
                          { text: "Custom Date", id: 8 },
                          { text: "Yesterday", id: 1 },
                          { text: "Last Week", id: 5 },
                          { text: "Last Month", id: 6 },
                          { text: "Last Year", id: 7 },
                        ]}
                        options={{
                          placeholder: "Select",
                        }}
                      />
                    </div>
                  </div>
                </div>

                {showCustomeDatePicker && (
                  <>
                    <div className="d-flex my-2 py-2 justify-content-center align-items-end border-top flex-wrap date-filter">
                      <div className="filter-ctrl">
                        <label>From Date</label>
                        <input
                          type="date"
                          className="form-control date-select"
                          name="StartDate"
                          max={new Date().toISOString().slice(0, 10)}
                          onChange={handleDateChange}
                        />
                      </div>

                      <div className="filter-ctrl">
                        <label>To Date</label>
                        <input
                          type="date"
                          className="form-control date-select"
                          name="EndDate"
                          max={new Date().toISOString().slice(0, 10)}
                          onChange={handleDateChange}
                        />
                      </div>
                      <button
                        type="button"
                        className="btn btn-sm blue-btn-bg green-btn green-border "
                        onClick={handelApplyDateFilter}
                        disabled={
                          customeDatePicker.StartDate?.length === 0 ||
                          customeDatePicker.EndDate?.length === 0
                        }
                      >
                        Apply
                      </button>
                    </div>
                    <div className="text-center text-danger">
                      <h6>{customeDatePickerError}</h6>
                    </div>
                  </>
                )}
              </CardTitle>
              <CardBody>
                <Row>
                  <CardSection payLoad={dashboardCardPayLoad} pageType={"stateDashboard"} />
                  <Col lg="7">
                    <Card className="border-0 mb-3">
                      <FullScreen handle={handle}>
                        <CardBody>
                          <CardTitle className="text-center mb-5">
                            HPV Screened & Vaccinated Report{" "}
                            <Expand onClick={handle.enter} className="cursor-pointer float-right" />
                          </CardTitle>
                          <ApexCharts payLoad={dashboardCardPayLoad} pageType={"stateDashboard"} />
                        </CardBody>
                      </FullScreen>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
          <h2>Dental Practices</h2>
          <Card className="transparant border-0 mb-3">
            <CardBody className="p-0">
              <div className="d-flex justify-content-end flex-wrap select-patients">
                <div className="main-search">
                  <Button className="btn-danger ml-2 px-5" onClick={handleExportDentalPracticeData}>
                    Export
                  </Button>
                </div>
              </div>
              {downloadStateDashboardDataError && downloadStateDashboardDataError.length > 0 && (
                <h6 className="text-center text-danger mt-3 mb-0">
                  {downloadStateDashboardDataError}
                </h6>
              )}
            </CardBody>
          </Card>

          <DentalPracticeList
            setDentalPracticeListPayLoad={setDentalPracticeListPayLoad}
            dentalPracticePayLoad={dentalPracticeListPayLoad}
            isLoading={appPageLoader}
            paginationDefaultPage={1}
            paginationPerPage={10}
          />
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default StateDashboard;
