import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export default function EditScreening(props) {

const [screeningDate, setScreeningDate] = useState(null);

useEffect(() => { 
    setScreeningDate(props.EditScreeningData ? props.EditScreeningData.formatLastScreenDate : new Date().toISOString().slice(0, 10));
}, [props.show]);

const handleDateChange = (event) => {
    const { name, value } = event.target;
    setScreeningDate(value);
  };

return(
    <Modal
    {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model integration-modal locationInfo-model"
        id="resyncAppt"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6 text-center">
            Change Screening Date
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
            <form>
                <div className="d-flex my-2 py-2 justify-content-center align-items-end flex-wrap date-filter">
                      <div className="filter-ctrl">
                        <h6 className="text-center">Screening Date</h6>
                        <input
                          type="date"
                          className="pr-3 date-select"
                          name="ScreeningDate"
                          value={screeningDate}
                          max={new Date().toISOString().slice(0, 10)}
                          onChange={handleDateChange}
                        />
                      </div>
                </div>
            </form>
            <div className="btn-box d-flex border-top">
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={() => props.onHide(false, null, false)}>
                Cancel
              </button>
              <button className="btn app-btn large-btn" type="button" onClick={() => props.handleChangeScreening(screeningDate)}>                
                Update
              </button>
            </div>
        </Modal.Body>
    </Modal>
);
};