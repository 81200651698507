import React, { useEffect, useState } from "react";
import AppPageLoader from "../CommonComponents/PageLoader/AppPageLoader";
import queryString from "query-string";
import Api from "../API/login";
import { goToSignin } from "../config/CommonFunctions";

const AccountSetup = () => {
  const [rMessage, setRMessage] = useState("");
  const [titleMessage, setTitleMessage] = useState("Account Activation");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const qString = queryString.parse(window.location.search);
    if (qString != null && qString.Email !== undefined) {
      setLoader(true);
      Api.accountActivation(qString.Email)
        .then((res) => {
          setLoader(false);
          setTitleMessage("Account activated!");
          setRMessage(res.data.Message);
          setTimeout(() => {
            goToSignin();
          }, 5000);
        })
        .catch((error) => {
          setLoader(false);
          setRMessage(error?.response?.data?.Message);
          setTimeout(() => {
            goToSignin();
          }, 5000);
        });
    } else {
      goToSignin();
    }
  }, []);

  return (
    <section className="common-section vh-100">
      <div className="container-fluid h-100">
        <div className="main-block align-items-center justify-content-center h-100 d-flex">
          <div className="login sub-block">
            <div className="row justify-content-center">
              <div className="col-lg-7 pr-lg-0">
                <div className="d-block">
                  <div className="inner-block text-center">
                    <div className="block-1">
                      <h1>{titleMessage} </h1>
                      <div>
                        <b> {rMessage}</b>
                      </div>
                    </div>
                    <div>
                      <span>Please</span>
                      <span className="">
                        <button onClick={() => goToSignin()} className="text-purple f-600 mx-1">
                          Click
                        </button>
                      </span>
                      <span className="">
                        if you are not redirected automatically within a few seconds
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && <AppPageLoader className={"position-fixed"} />}
    </section>
  );
};
export default AccountSetup;
