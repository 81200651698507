import React from "react";
import { FormGroup, CustomInput } from "reactstrap";

const HPVQA = ({ question, selectedValue, handleClick, id, type, error, ...props }) => {
  const YesNo = ({ id, selectedValue }) => (
    <FormGroup style={{ zIndex: 1000 }}>
      <CustomInput
        type="radio"
        id={`ques-${id}-yes`}
        name={`question-${id}`}
        className="ques-checks"
        label="Yes"
        onChange={() => handleClick("Yes", id)}
        defaultChecked={selectedValue.toLowerCase() === "yes" ? true : false}
        {...props}
      />
      <CustomInput
        type="radio"
        id={`ques-${id}-no`}
        name={`question-${id}`}
        className="ques-checks"
        label="No"
        onChange={() => handleClick("No", id)}
        defaultChecked={selectedValue.toLowerCase() === "no" ? true : false}
        {...props}
      />
      <div className="err-message">{error}</div>
    </FormGroup>
  );

  const TextInput = ({ id, selectedValue }) => (
    <FormGroup>
      <textarea
        type="text"
        id={`ques-${id}-yes`}
        name={`question-${id}`}
        className="ques-checks"
        defaultValue={selectedValue}
        cols={300}
        rows={5}
      />
      <div className="err-message">{error}</div>
    </FormGroup>
  );

  return (
    <div className="question-box position-relative" style={{ zIndex: 1000 }}>
      <p>{question}</p>
      <div className="question-buttons">
        {type === 1 ? (
          <YesNo id={id} selectedValue={selectedValue} />
        ) : (
          <TextInput id={id} selectedValue={selectedValue} />
        )}
      </div>
    </div>
  );
};

const HPVQASection = ({ QAList, handleClick, ...props }) => {
  return (
    <>
      {QAList &&
        QAList.length > 0 &&
        QAList.map((value, id) => (
          <HPVQA
            key={id}
            question={value.q}
            selectedValue={value.a}
            handleClick={handleClick}
            id={id}
            type={value.type}
            error={value.error}
            {...props}
          />
        ))}
    </>
  );
};

export { HPVQA, HPVQASection };
