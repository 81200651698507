import React from "react";

const BackButton = ({ onClick }) => {
  return (
    <div className="account-btn-block text-center pt-2">
      <button onClick={onClick} className="m-0 p-0 bg-white border-0 back-button">
        <i className="fa fa-chevron-left" aria-hidden="true"></i>
        Back
      </button>
    </div>
  );
};

export default BackButton;
