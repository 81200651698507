import React, { useState } from "react";
import { Navbar } from "react-bootstrap";
import Logo from "../../assets/images/Logo-1.png";
import { Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import ChangePass from "../../models/ChangePass";
import { goToSignin } from "../../config/CommonFunctions";

const LoginNavbar = () => {
  const [ChangePassShow, setChangePass] = useState(false);
  return (
    <div id="header">
      <Navbar bg="dark" variant="dark" expand="lg">
        <div className="container-fluid">
          <Navbar.Brand className="logo">
            <NavLink to="/">
              {" "}
              <img src={Logo} alt="Logo" className="img-fluid" />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <div className="login-btn">
              <Button onClick={goToSignin}>Login / Signup</Button>
            </div>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <ChangePass show={ChangePassShow} onHide={() => setChangePass(false)} />
    </div>
  );
};
export default LoginNavbar;
