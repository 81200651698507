import axios from "axios";
import { API_URL } from "../config/appConstatnts";

class Api {
  // Api for stateAccount list.
  static getStateAccountList = () => {
    const HEADER = {
      headers: {
        access_token: localStorage.getItem("token"),
        "Content-Type": "application/json",
        Company_Id: localStorage.getItem("CompanyId"),
      },
    };
    return axios.get(API_URL + "/HPV/GetStateAccount", HEADER);
  };
  // Api for getDentalPractice.
  static getDentalPracticeList = (payLoad) => {
    const HEADER = {
      headers: {
        access_token: localStorage.getItem("token"),
        "Content-Type": "application/json",
        Company_Id: localStorage.getItem("CompanyId"),
      },
    };
    return axios.post(API_URL + "/HPV/GetDentalPractices", payLoad, HEADER);
  };
  // Api for ExportDentalPractice.
  static exportDentalPracticeData = (payLoad) => {
    const HEADER = {
      headers: {
        access_token: localStorage.getItem("token"),
        "Content-Type": "application/json",
        Company_Id: localStorage.getItem("CompanyId"),
      },
    };
    return axios.post(API_URL + "/HPV/ExportDentalPracticeData", payLoad, HEADER);
  };
}

export default Api;
