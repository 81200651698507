// Declare all application const.

// export const API_URL = "https://4c1f453ebf09.ngrok.io/api";
// export const API_URL = "https://eb323ea6f091.ngrok.io/rlapi/api";
// export const RecordLincURL = "https://eb323ea6f091.ngrok.io/";
// export const API_URL = "https://recordlinc.com/rlapi/api";
// export const RecordLincURL = "http://localhost:3000/";
// ===================localurl===========
// export const RecordLincURL = "http://localhost:2298/";
// export const API_URL = "http://localhost:56697/api";
// ===================localurl===========

// export const RecordLincURL = "https://qa.recordlinc.com/";
// export const RecordLincURL = "https://recordlinc.com/";
//export const RecordLincURL = "https://qa.recordlinc.com/";

//===================original url========================
export const RecordLincURL = "https://recordlinc.com/";
export const API_URL = "https://recordlinc.com/rlapi/api";
//===================original url========================

// export const API_URL = "https://qa.recordlinc.com/rlapi/api";
// export const API_URL = "http://localhost:56697/";
// export const API_URL = "https://4c1f453ebf09.ngrok.io/api";

// =====================dynamic theming constants============
export const ProductName =
  localStorage.getItem("CompanyDetails") === undefined ||
  localStorage.getItem("CompanyDetails") == null
    ? ""
    : JSON.parse(localStorage.getItem("CompanyDetails"));

// export const localStorageUserData =
//   localStorage.getItem("userData") == null ? [] : JSON.parse(localStorage.getItem("userData"));

// export const localStoragetoken =
//   localStorage.getItem("token") == null ? null : localStorage.getItem("token");

// export const localStorageCompanyDetail =
//   localStorage.getItem("CompanyDetails") == null
//     ? null
//     : JSON.parse(localStorage.getItem("CompanyDetails"));

export const domain = window.location.hostname;

export const publicUrl = process.env.PUBLIC_URL; //live

export const url_link = window.location.origin; //live

export const IntigrationAuthKey = "https://integrate.drdds.com/authKey/";

// export const url_link = publicUrl; //test

// ===========================================================

export const GHL_API_URL = "https://rest.gohighlevel.com/v1";

// Set comman Header value for API calls
export const HEADER = {
  headers: {
    access_token: localStorage.getItem("token"),
    "Content-Type": "application/json",
    Company_Id: localStorage.getItem("CompanyId"),
  },
};

export const HEADERMultipart = {
  headers: {
    access_token: localStorage.getItem("token"),
    "Content-Type": "multipart/form-data",
    Company_Id: localStorage.getItem("CompanyId"),
  },
};
