import axios from "axios";
import { API_URL, HEADER } from "../config/appConstatnts";

class Api {
  // Api for check an email.
  static getCheckEmail = (email) => {
    return axios.get(API_URL + "/Verident/CheckEmailExists?Email=" + email, HEADER);
  };

  // Api for get state of US
  static getState = (CountryID) => {
    return axios.get(API_URL + "/State/Get/" + CountryID);
  };

  // integrationList
  static getIntegrationList = () => {
    return axios.get(API_URL + "/Integrationlist", HEADER);
  };

  // Get Specialities
  static getSpecialities = () => {
    return axios.get(API_URL + "/Verident/GetSpecialities?IsSignUP=True", HEADER);
  };

  // getPatientsList
  static getPatientList = (payLoad) => {
    const HEADER = {
      headers: {
        CompanyId: localStorage.getItem("CompanyId"),
        "Content-Type": "application/json",
        access_token: localStorage.getItem("token"),
      },
    };
    return axios.post(API_URL + "/HPV/GetPatientsList", payLoad, HEADER);
  };

  // get doctors and locations list
  static getLocationDoctorList = () => {
    const HEADER = {
      headers: {
        CompanyId: localStorage.getItem("CompanyId"),
        "Content-Type": "application/json",
        access_token: localStorage.getItem("token"),
      },
    };
    return axios.get(API_URL + "/HPV/GetDoctorsWithLocation", HEADER);
  };

  // for getting all Q&A list
  static getQA = () => {
    const HEADER = {
      headers: {
        CompanyId: localStorage.getItem("CompanyId"),
        "Content-Type": "application/json",
        access_token: localStorage.getItem("token"),
      },
    };
    return axios.get(API_URL + "/HPV/GetQuestion", HEADER);
  };
}

export default Api;
