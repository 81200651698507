import React from "react";
import DataTable from "react-data-table-component";
import BootyPagination from "../Pagination/BootyPagination";
import screeningData from "../../app-pages/data/screeningData";
import NoRecordFound from "../NoRecordFound";

const ScreeningHistory = ({
  columns,
  data,
  totalRecords,
  paginationDefaultPage,
  paginationPerPage,
  paginationTotalRows,
  handleOnSort,
  isLoading,
  ...props
}) => {
  return (
    <>
      {!isLoading && data.length > 0 && (
        <div className="float-right">
          <h5 className="mr-5 mt-2 mb-1 p-0">Total Records: {totalRecords > 0 && totalRecords}</h5>
        </div>
      )}
      <DataTable
        noDataComponent={
          isLoading ? (
            <h3>Loading...</h3>
          ) : (
            <NoRecordFound
              className={"w-100"}
              text={"There are no screening records for selected patient."}
            />
          )
        }
        columns={columns}
        data={isLoading ? screeningData : data}
        onSort={handleOnSort}
        pagination
        paginationServer
        paginationDefaultPage={paginationDefaultPage ?? 1}
        paginationPerPage={paginationPerPage ?? 5}
        paginationTotalRows={paginationTotalRows ?? 24}
        paginationComponent={BootyPagination}
        {...props}
      />
    </>
  );
};

export default ScreeningHistory;
