import history from "../history";

export const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

// Redirect to forgot password
export const gotoForgotPassword = () => {
  history.push("/forgot-password");
};
// Redirect to sign up
export const gotoSignUp = () => {
  history.push("/signup");
};

// Redirect to dashboard
export const gotoDashboard = () => {
  history.push("/dashboard");
};

// redirect to signup
export const goToSignin = (data) => {
  history.push("/login", { email: data?.email ?? "" } ?? "");
};

export const goToMainPage = (payLoadData) => {
  history.push("/screen", { patientData: payLoadData } ?? "");
};
export const goToStateDashboardPage = () => {
  history.push("/state/dashboard");
};

export const redirectToAddpatient = () => {
  history.push({
    pathname: "/screen/addpatient",
    state: { previousPath: history.location.pathname },
  });
};

export const logout = () => {
  history.push("/login");
  localStorage.removeItem("token");
  localStorage.removeItem("userData");
};

export const goToHome = () => {
  history.push("/");
};

// capitalizeWord
export const capitalizedWord = (CompanyName) =>
  CompanyName.charAt(0).toUpperCase() + CompanyName.slice(1);

// give difference of two date
export const getDifferenceInDays = (startDate, endDate) => {
  const diffInMs = Math.abs(startDate - endDate);
  return diffInMs / (1000 * 60 * 60 * 24);
};

// for start date and end date for months
export const defaultFromDate = () => {
  let today = new Date();
  return (
    today.getFullYear() +
    "-" +
    ("0" + today.getMonth()).slice(-2) +
    "-" +
    ("0" + today.getDate()).slice(-2)
  );
};

export const defaultToDate = () => {
  let today = new Date();
  return (
    today.getFullYear() +
    "-" +
    ("0" + (today.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + today.getDate()).slice(-2)
  );
};

export const handleOnScreenBtnClick = (patientId, previousHistory, patientListPayLoad) => {
  history.push({
    pathname: "/reports/patinetinfo",
    state: {
      previousPath: previousHistory,
      patientId: patientId,
      patientListPayLoad,
    },
  });
};

export const getFormatedDate = (selectedDate) => {
  const newDate = +selectedDate?.getDate();
  const newMonth = +selectedDate?.getMonth() + 1;
  return newMonth + "/" + newDate + "/" + selectedDate?.getFullYear();
};
