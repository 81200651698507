import React, { useLayoutEffect } from "react";
import "./App.css";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";

import Home from "./LRF/selectWhoYouAre/SelectWhoYouAre";
import MainPage from "./Screening";
// import MyAccount from "./Myaccount";
// developement==========================
import NewSignInPage from "./LRF/SignInPage/SignInPage";
import NewSignUpPage from "./LRF/SignUpPage/SignUpPage";
import NewChangePassPage from "./LRF/ChangePassPage";
import NewForgotPasswordPage from "./LRF/ForgotPasswordPage";
import NewResetPasswordPage from "./LRF/ResetPasswordPage";
import DentalPracticeDashboard from "./Reports";
import AccountSetup from "./LRF/AccountSetup";
import StateDashboard from "./StateDashboard";
import Addpatients from "./Screening/AddPatients";
import PatientsInfo from "./PatientsInfo";
import Patients from "./Patients";
// developement==========================
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "react-datepicker/dist/react-datepicker.css";

import history from "./history";
import { domain, ProductName } from "./config/appConstatnts";
import Api from "./API/login";
import { ToastContainer } from "react-toastify";
import AppPageLoader from "./CommonComponents/PageLoader/AppPageLoader";
import ErrorPage from "./CommonComponents/ErrorPage";

const App = () => {
  const localStorageCompanyDetail = JSON.parse(localStorage.getItem("CompanyDetails"));
  const localStoragetoken = localStorage.getItem("token");
  const [appLoaderState, setappLoaderState] = React.useState(true);

  // const appThemeColor = (url, companyId) => {
  //   document.getElementById("favicon").href = `${url}/${companyId}/images/Favicon.png`;
  //   document.getElementById("custom-style").href = `${url}/${companyId}/css/style.css`;
  //   document.getElementById("flag-style").href = `${url}/${companyId}/css/flag.css`;
  //   document.getElementById(
  //     "custom-responsive-style"
  //   ).href = `${url}/${companyId}/css/responsive.css`;
  // };

  if (localStorageCompanyDetail === null) {
    Api.getCompanyDetail(domain)
      .then((res) => {
        localStorage.setItem("CompanyId", res.data.Result.CompanyId);
        localStorage.setItem("CompanyDetails", JSON.stringify(res.data.Result));
        document.title = res.data.Result.CompanyName;
        // appThemeColor(url_link, res.data.Result.CompanyId);
        document.querySelector("#custom-style").onload = () => {
          setappLoaderState(false);
        };
      })
      .catch((error) => {
        setappLoaderState(false);
      })
      .finally(() => setappLoaderState(false));
  }
  useLayoutEffect(() => {
    if (localStorage.getItem("userData") === null) {
      window.localStorage.clear();
    }
    if (
      localStoragetoken == null ||
      localStorageCompanyDetail == null ||
      localStorageCompanyDetail.CompanyId == null
    ) {
      Api.getCompanyDetail(domain)
        .then((res) => {
          localStorage.setItem("CompanyId", res.data.Result.CompanyId);
          localStorage.setItem("CompanyDetails", JSON.stringify(res.data.Result));
          document.title = res.data.Result.CompanyName;
          // appThemeColor(url_link, res.data.Result.CompanyId);
          document.querySelector("#custom-style").onload = () => {
            setappLoaderState(false);
          };
        })
        .catch((error) => {
          setappLoaderState(false);
        })
        .finally(() => setappLoaderState(false));
    } else {
      // let companyId = localStorage.getItem("CompanyId");
      document.title = ProductName.CompanyName;
      // appThemeColor(publicUrl, companyId);
      // document.querySelector("#custom-style").onload = () => {
      //   setappLoaderState(false);
      // };
      setappLoaderState(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PrivateRoute = ({ component: Component, ...rest }) => {
    const restPara = { ...rest };
    if (JSON.parse(localStorage.getItem("userData"))?.IsAdmin === false)
      return (
        <Route
          {...rest}
          render={(props) =>
            localStorage.getItem("token") != null &&
            localStorage.getItem("token").length > 0 &&
            restPara.path !== "/state/dashboard" ? (
              <Component {...props} />
            ) : (
              <Redirect to={"/"} />
            )
          }
        />
      );
    else {
      return (
        <Route
          {...rest}
          render={(props) =>
            localStorage.getItem("token") != null &&
            localStorage.getItem("token").length > 0 &&
            restPara.path !== "/screen" &&
            restPara.path !== "/reports" &&
            restPara.path !== "/patients" &&
            restPara.path !== "/screen/addpatient" &&
            restPara.path !== "/reports/patinetinfo" ? (
              <Component {...props} />
            ) : (
              <Redirect to={"/"} />
            )
          }
        />
      );
    }
  };

  const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("token") == null || localStorage.getItem("token").length === 0 ? (
          <Component {...props} />
        ) : JSON.parse(localStorage.getItem("userData")).IsAdmin ? (
          <Redirect to="/state/dashboard" />
        ) : (
          <Redirect to="/screen" />
        )
      }
    />
  );

  return (
    <div className="App">
      {appLoaderState && <AppPageLoader />}
      <Router history={history}>
        <Switch>
          {/* ===================Developement start============== */}
          <PublicRoute path="/activation" component={AccountSetup} exact />
          <PublicRoute path="/login" component={NewSignInPage} exact />
          <PublicRoute path="/signup" component={NewSignUpPage} exact />
          <PublicRoute path="/change-password" component={NewChangePassPage} exact />
          <PublicRoute path="/resetpassword" component={NewResetPasswordPage} exact />
          <PublicRoute path="/forgot-password" component={NewForgotPasswordPage} exact />

          <PublicRoute path="/" component={Home} exact />
          {/* Screening page */}
          <PrivateRoute path="/screen" component={MainPage} exact />
          <PrivateRoute path="/screen/addpatient" component={Addpatients} exact />
          {/* Patients page */}
          <PrivateRoute path="/patients" component={Patients} exact />
          {/* Dental-practice dashboard */}
          <PrivateRoute path="/reports" component={DentalPracticeDashboard} exact />
          <PrivateRoute path="/reports/patinetinfo" component={PatientsInfo} exact />
          {/* State dashboard*/}
          <PrivateRoute path="/state/dashboard" component={StateDashboard} exact />
          {/* <Redirect to={"/"} /> */}
          <Route component={ErrorPage} />
          {/* ===================Developement end============== */}
        </Switch>
        {/* Router End */}
      </Router>
      <ToastContainer />
    </div>
  );
};

export default App;
