import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import logo from "./Images/profile-logo.svg";
// import { ReactComponent as dummyImage } from "../../assets/images/Profile_No_Image_Male.png";

const WhoYouAreCard = ({ UserImg, name, profession, handleCardClick, email }) => {
  return (
    <Col xl="4" lg="6">
      <Card className="user-profile-block">
        <CardBody onClick={() => handleCardClick(email)} className="cursor-pointer">
          <div className="user-img">
            <img
              style={{
                backgroundImage: `url(${logo})`,
                backgroundSize: "auto",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
              src={`${
                UserImg.split("/")[0] === "https:"
                  ? `${UserImg}`
                  : "https://refer.oneclickreferral.com" + UserImg
              }`}
              width={128}
              height={128}
              alt={name}
              className="img-fluid user-image"
            />
          </div>
          <div className="user-detail">
            <h3>{name}</h3>
            <p>{profession}</p>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default WhoYouAreCard;
